/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: beacon_list_box.jsx
 *
 *  Description: Beacon List Box contains list of beacons
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 26-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import React from "react";
import Styled from "styled-components";

export default function BeaconListBox({
  open,
  handleNegativeAction,
  negativeText = "CANCEL",
  children
}) {
  return (
    <Dialog open={open} onClose={handleNegativeAction}>
      <DialogContent>{children}</DialogContent>
      <StyledDialogActions>
        <Button onClick={handleNegativeAction} color="primary">
          {negativeText}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

const StyledDialogTitle = Styled(DialogTitle)`
   && {
       padding-left:0px
   }
  `;

const StyledDialogActions = Styled(DialogActions)`
   && {
       padding:16px
   }
  `;
