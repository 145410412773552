/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import {
  AppBar,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect } from "react";
import firebase from "firebase";
import IndoorCanvas from "./IndoorCanvas";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/*
    Declearing the functional component 
    */

function SubLocationDialogBox({ subid, locid, ...props }) {
  const [open, setOpen] = React.useState(false);
  const [canvasHeight, setCanvasHeight] = React.useState(0);
  const [canvasWidth, setCanvasWidth] = React.useState(0);
  const [userData, setUserData] = React.useState();
  const [sublocId, setSublocId] = React.useState(props.subId);

  const db = firebase
    .database()
    .ref()
    .child(`realtime_locations/${props.locId}/${props.subId}/`);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    //setUserData([]);

    firebase
      .database()
      .ref()
      .child(`realtime_locations/${props.locId}/${props.subId}/`)
      .off("value", snapshot => {});
    setOpen(false);
  }

  const getUserData = async () => {
    db.on("value", snapshot => {
      const state = snapshot.val();

      setUserData(state);
    });
  };

  useEffect(() => {
    const img = new Image();
    img.src = props.image;

    img.onload = function() {
      setCanvasWidth(img.width);
      setCanvasHeight(img.height);
    };
  }, [props.image]);

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sublocId]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        style={{ color: "white" }}
        onClick={handleClickOpen}
      >
        track
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="Close"
              style={{ color: "white" }}
            >
              <CloseIcon />
            </IconButton>

            {/* <Button
                color="inherit"
                onClick={handleSave}
                style={{ marginLeft: "90%", color: "white" }}
              >
                save
              </Button> */}
          </Toolbar>
        </AppBar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh"
          }}
        >
          <div
            style={{
              height: 80,
              display: "flex",
              flexDirection: "row",
              flexGrow: 0,
              paddingLeft: "2%"
            }}
          >
            <Grid container xs={12}>
              <Grid item xs={10}>
                <Typography
                  style={{
                    fontSize: "22px",
                    fontWeight: "bolder",
                    margin: "2% 0% 0% 1% ",
                    fontFamily: "helvetica neue, sans-serif"
                  }}
                >
                  {props.subName}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Divider
            style={{ border: "0.5px solid black", margin: "0% 5% 2% 2%" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              paddingLeft: "2%"
            }}
          >
            <Grid container>
              <Grid item xs>
                {canvasHeight && canvasWidth ? (
                  <IndoorCanvas
                    image={props.image}
                    CanvasHeight={canvasHeight}
                    CanvasWidth={canvasWidth}
                    userData={userData}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

/*
    Validating React Component Props
    */
SubLocationDialogBox.propTypes = {};

export default SubLocationDialogBox;
