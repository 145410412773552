/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_appbar.jsx
 *
 *  Description: Appbar component.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */ import {
  AppBar,
  MenuItem,
  Toolbar,
  Menu
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Styled from "styled-components";
import { Row } from "../../styles/styled";
import { getCurrentUser, logout } from "../../controllers/firebase";

// functional component
/*
 **************************************************************************************
 * @brief  (AppBarView)  renders the appbar
 * @param{type :object } (props)
 *
 * @returns Appbar component (react element)
 **************************************************************************************
 */
function AppBarView({ drawer, setDrawer, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userName, setUserName] = useState("Snowm");
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /*
   **************************************************************************************
   * @brief  (GetCurrenUser)  sets the text inside the userProfile image if image not provided
   * @param{type :Firebase.user } (user)
   *
   * @returns undefined
   **************************************************************************************
   */

  getCurrentUser(user => {
    if (user) {
      setUserName(user.displayName);
    } else {
      setUserName("Snowm");
    }
  });

  const open = Boolean(anchorEl);
  const { theme, routes, history } = props;
  return (
    <Appbar position="static" theme={theme} color="#000">
      <ToolbarStyled>
        <Row align="center" justify="flex-start">
          <LogoImage
            onClick={() => {}}
            src="https://static.wixstatic.com/media/dfaf54_a93f1233ad1b44e28dd07aa82c479593~mv2.png/v1/fill/w_280,h_109,al_c,lg_1,q_80/Final-Logo-01.webp"
          />
        </Row>

        <div>
          <StyledMenu
            aria-owns={open ? "menu-appbar" : undefined}
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            theme={theme}
          >
            <IconText
              style={{
                margin: "auto",
                lineHeight: 1
              }}
            >
              {userName ? userName[0] : "S"}
            </IconText>
          </StyledMenu>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={open}
            onClose={handleClose}
          >
            {routes.map(({ route, name, icon, menu }) => {
              if (menu) {
                return (
                  <MenuItem
                    onClick={() => {
                      history.push(route);
                      handleClose();
                    }}
                  >
                    {name}
                  </MenuItem>
                );
              }
              return null;
            })}
            <MenuItem onClick={logout}>Log Out</MenuItem>
          </Menu>
        </div>
      </ToolbarStyled>
    </Appbar>
  );
}

// proptype validation
AppBarView.defaultProps = {
  toggle: () => {}
};

AppBarView.propTypes = {
  toggle: PropTypes.func
};

export default AppBarView;

const ToolbarStyled = Styled(Toolbar)`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
`;

const StyledMenu = Styled.div`
&&{
  cursor:pointer;
  display:flex;
  height:20px;
  width:20px;
  padding:10px;
  border-radius:50%;
  background:${props => props.theme.palette.primary.main};
  color:white;
}
`;

const IconText = Styled.div`
  font-weight:bold;
  font-size:18px;
`;

const LogoImage = Styled.img`
object-fit:cover;
cursor:pointer;
height:64px;
width:auto;
`;

const Hamburger = Styled.div`
  visibility:collapse;
  margin:8px;
  height:0px;
  
`;

const Appbar = Styled(AppBar)`
    &&{
      height:64px;
      width:100%;
      padding:0px;
      margin:0px;
    }
`;
