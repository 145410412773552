export const ValidateEmail = email => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
};

export const ValidatePhone = phone => {
  return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phone);
};

export const ValidateLocationName = name => {
  if (
    name.length === 0 ||
    name.length > 16 ||
    name.length < 2 ||
    !name.trim()
  ) {
    return false;
  }
  return true;
};

export const ValidateUserName = name => {
  if (name.length === 0 || name.length > 32 || !name.trim()) {
    return false;
  }
  return true;
};
export const ValidateBlank = value => {
  if (value.length === 0 || !value.trim()) {
    return false;
  }
  return true;
};
export const ValidateDropDown = value => {
  if (value.length === 0) {
    return false;
  }
  return true;
};
export const ValidateTitleCampaign = title => {
  if (title.length === 0 || title.length > 32 || !title.trim()) {
    return false;
  }
  return true;
};
export const ValidateDescriptionCampaign = description => {
  if (
    description.length === 0 ||
    description.length > 128 ||
    !description.trim()
  ) {
    return false;
  }
  return true;
};
export const isFileImage = file => {
  return file && file["type"].split("/")[0] === "image";
};
export const isFileImagePng = file => {
  return file && file["type"] === "image/png";
};
export const isFileVideo = file => {
  return file && file["type"].split("/")[0] === "video";
};
export const isFileAudio = file => {
  return file && file["type"].split("/")[0] === "audio";
};

export const matchYoutubeUrl = url => {
  var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (url.match(p)) {
    //return url.match(p)[1];
    return true;
  }
  return false;
};
