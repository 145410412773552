/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-indent */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";

import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Button,
  Dialog
} from "@material-ui/core";

import addimage from "../../../assets/images/addsublocationimage.png";
import "./Sublocation.css";

import {
  getCurrentUser,
  getCurrentCompanyKey,
  getSubLocationData,
  getBeacons
} from "../../../controllers/firebase";
import SublocationDetailDialog from "./subdialogbox";
import SelectLocation from "./SelectLocation";
import AddSubLocationDialogBox from "./AddSubLocationDialogBox";
import "./Sublocation.css";
import Tracking from "../indoor/IndoorNew";
import DeleteDialogBox from "./DeleteDialogBox";

/*
Declearing the functional component 
*/
function Sublocations() {
  const [locationHolder, setLocationHolder] = useState();

  // variable to store company key fetched from firebase
  const [companyKey, setCompanyKey] = useState();

  const [add, setAdd] = useState(false);
  const [subLoc, setSubLoc] = useState();
  const [beacons, setBeacons] = useState();
  const [selectedSubLocation, setSelectedSubLocation] = useState(null);

  async function initialize() {
    let key = await getCurrentCompanyKey();
    setCompanyKey(key);
  }

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (locationHolder) {
      //todo manage company key in state
      getBeaconData(companyKey);
      getSubLocations(companyKey);
    }
  }, [locationHolder]);

  const getSubLocations = async ck => {
    const sublocation = await getSubLocationData(ck);
    setSubLoc(sublocation);
  };
  const getBeaconData = async ck => {
    const beacons = await getBeacons(ck);
    setBeacons(beacons);
  };

  function handleAddOpen() {
    setAdd(true);
  }

  function handleAddClose() {
    setAdd(false);
  }

  const updateSubLocations = () => {
    getSubLocations(companyKey);
    getBeaconData(companyKey);
  };

  const update = () => {
    getSubLocations(companyKey);
  };

  // Add necessary comments in the return
  return (
    <div id="parentdiv">
      <div id="firstchilddiv">
        <SelectLocation setLocationHolder={setLocationHolder} />
      </div>
      <div id="secondchilddiv">
        <Grid container spacing={1}>
          {locationHolder && (
            <Grid item xs={12} id="titlegrid">
              {locationHolder.navLocationId && (
                <Typography variant="h3">
                  {locationHolder && locationHolder.locationName + " "}
                  sublocations
                </Typography>
              )}
            </Grid>
          )}
          {locationHolder &&
            subLoc &&
            subLoc.map((subLocation, index) => {
              if (locationHolder.navLocationId === subLocation.navLocationId) {
                return (
                  <Grid item id="campaigngrid">
                    <Card>
                      <div
                        style={{
                          position: "relative"
                        }}
                      >
                        <CardMedia
                          style={{
                            height: "5vh",
                            paddingTop: "56.25%"
                          }}
                          image={subLocation.imageUrl}
                        />
                        <Card
                          style={{
                            position: "absolute",
                            top: "4px",
                            right: "5px"
                          }}
                        >
                          <DeleteDialogBox
                            beacons={beacons}
                            sublocation={subLocation}
                            update={update}
                          />
                        </Card>
                      </div>

                      <CardContent id="cardcontent">
                        <div id="titledescriptiondiv">
                          <Typography id="campaigntitle">
                            {subLocation.subLocationName}
                          </Typography>
                          <Typography>
                            Created by : {""}
                            {subLocation.displayName}
                          </Typography>
                          <Typography>
                            {new Date(subLocation.timestamp)
                              .toString()
                              .slice(0, 25)}
                          </Typography>
                        </div>
                      </CardContent>

                      {locationHolder.selectedType === "indoorNavigation" && (
                        <CardActions
                          disableSpacing
                          style={{
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <Tracking
                            image={subLocation.imageUrl}
                            locId={locationHolder.navLocationId}
                            subId={subLocation.navSublocationId}
                            subName={subLocation.subLocationName}
                          />
                        </CardActions>
                      )}

                      <CardActions
                        disableSpacing
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ color: "white" }}
                          onClick={() => setSelectedSubLocation(subLocation)}
                        >
                          Click to View
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              } else {
                return <div></div>;
              }
            })}

          {locationHolder && (
            <Grid item id="addcampaigngrid">
              <div onClick={handleAddOpen}>
                <img src={addimage} id="addimage" alt="noImage" />
              </div>
            </Grid>
          )}
          {locationHolder && (
            <Dialog
              open={add}
              onClose={handleAddClose}
              aria-labelledby="form-dialog-title"
              maxWidth="xl"
              disableBackdropClick="true"
              disableEscapeKeyDown="true"
            >
              <AddSubLocationDialogBox
                closeAddDialog={handleAddClose}
                update={updateSubLocations}
                navLocationId={locationHolder.navLocationId}
                locationId={locationHolder.id}
              />
            </Dialog>
          )}
          {selectedSubLocation && locationHolder ? (
            <SublocationDetailDialog
              open={selectedSubLocation !== null}
              handleClose={() => {
                setSelectedSubLocation(null);
              }}
              image={selectedSubLocation.imageUrl}
              locId={locationHolder.id}
              navLocId={locationHolder.navLocationId}
              subId={selectedSubLocation.navSublocationId}
              subName={selectedSubLocation.subLocationName}
              updateSubLocation={updateSubLocations}
              updateBeacon={getBeaconData}
            />
          ) : (
            <div></div>
          )}
        </Grid>
      </div>
    </div>
  );
}

/*
Validating React Component Props
*/
Sublocations.propTypes = {};

export default Sublocations;
