/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useContext, useEffect } from "react";

import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Paper,
  Grid,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";

import { LocationContext } from "../../../contexts/LocationsContext";

const dropDownStyle = {
  minWidth: "28%",
  marginLeft: "2.5%",

  marginBottom: "3%"
};

/*
Declearing the functional component 
*/
function SelectLocation(props) {
  const [locationHolder, setLocationHolder] = useState();
  const [locations, setLocations] = useState();

  const { locationss } = useContext(LocationContext);

  useEffect(() => {
    const locations =
      locationss &&
      locationss.filter(
        location =>
          location.selectedType === "sublocationBased" ||
          location.selectedType === "indoorNavigation"
      );
    setLocations(locations);
  }, [locationss]);

  const handleLocationChange = e => {
    setLocationHolder(e.target.value);
    const selectedLocation =
      locationss &&
      locationss.filter(location => location.id === e.target.value);
    props.setLocationHolder({ ...selectedLocation[0] });
  };

  // Add necessary comments in the return
  return (
    <Grid xs={5} id="gridparent">
      <Paper elevation="5" id="paperstyle">
        <Typography id="locationtypography">
          <span id="locationspan_1">Choose Location</span>
          <span id="locationspan_2">
            {" "}
            Select a location to see sublocations
          </span>
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs sm={8}>
            <FormControl style={dropDownStyle}>
              <InputLabel
                htmlFor="location-simple"
                shrink={locationHolder ? true : false}
              >
                Choose a Location
              </InputLabel>
              <Select
                value={locationHolder}
                onChange={handleLocationChange}
                inputProps={{
                  name: "location",
                  id: "location-simple"
                }}
              >
                {locations &&
                  locations.map((location, index) => {
                    return (
                      <MenuItem value={location.id} key={index}>
                        {location.locationName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

/*
Validating React Component Props
*/
SelectLocation.propTypes = {
  getLocationHolder: PropTypes.func.isRequired
};

export default SelectLocation;
