/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useContext } from "react";

import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Paper,
  Grid,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";

import { LocationContext } from "../../../contexts/LocationsContext";
import { sublocationContext } from "../../../contexts/SublocationsContext";

const dropDownStyle = {
  minWidth: "28%",
  marginLeft: "2.5%",

  marginBottom: "3%"
};

/*
Declearing the functional component 
*/
function SelectLocation(props) {
  const [locationHolder, setLocationHolder] = useState();
  const [sublocationHolder, setSublocationHolder] = useState("");
  const [locationSelected, setlocationSelected] = useState(null);

  const { locationss } = useContext(LocationContext);
  const { sublocations, updateSubLocations } = useContext(sublocationContext);

  const handleSublocationChange = e => {
    setSublocationHolder(e.target.value);
    const selectedSubLocation =
      sublocations &&
      sublocations.filter(
        sublocation => sublocation.navSublocationId === e.target.value
      );
    props.getSubLocationHolder({ ...selectedSubLocation[0] });
  };

  function getLocationList(loc) {
    //check changed from != to !==
    let t = loc.filter(l => l.selectedType !== "indoorNavigation");
    return t.map((l, index) => (
      <MenuItem value={l.id} key={index}>
        {l.locationName}
      </MenuItem>
    ));
  }

  const handleLocationChange = e => {
    setLocationHolder(e.target.value);
    setSublocationHolder("");
    const selectedLocation =
      locationss &&
      locationss.filter(location => location.id === e.target.value);
    props.getLocationHolder({ ...selectedLocation[0] });
    props.getSubLocationHolder({});
    updateSubLocations();
    setlocationSelected({ ...selectedLocation[0] });
  };

  // Add necessary comments in the return
  return (
    <Grid xs={5} id="gridparent">
      <Paper elevation="5" id="paperstyle">
        <Typography id="locationtypography">
          <span id="locationspan_1">Choose Location</span>
          <span id="locationspan_2"> Select a location to see campaigns</span>
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs sm={8}>
            <FormControl style={dropDownStyle}>
              <InputLabel
                htmlFor="location-simple"
                shrink={locationHolder ? true : false}
              >
                Choose a Location
              </InputLabel>
              <Select
                value={locationHolder}
                onChange={handleLocationChange}
                inputProps={{
                  name: "location",
                  id: "location-simple"
                }}
              >
                {locationss && getLocationList(locationss)
                // locationss.map((location, index) => {
                //   return (
                //     <MenuItem value={location.id} key={index}>
                //       {location.locationName}
                //     </MenuItem>
                //   );
                // })
                }
              </Select>

              {locationSelected &&
                (locationSelected.selectedType === "locationBased" ? (
                  <React.Fragment></React.Fragment>
                ) : locationSelected.selectedType === "sublocationBased" ||
                  locationSelected.selectedType === "indoorNavigation" ? (
                  <Select
                    value={sublocationHolder}
                    onChange={handleSublocationChange}
                    inputProps={{
                      name: "sublocation",
                      id: "sublocation-simple"
                    }}
                  >
                    {sublocations &&
                      sublocations.map((sublocation, index) => {
                        return (
                          locationSelected.navLocationId ===
                            sublocation.navLocationId && (
                            <MenuItem
                              value={sublocation.navSublocationId}
                              key={index}
                            >
                              {sublocation.subLocationName}
                            </MenuItem>
                          )
                        );
                      })}
                  </Select>
                ) : (
                  <></>
                ))}
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

/*
Validating React Component Props
*/
SelectLocation.propTypes = {
  getLocationHolder: PropTypes.func.isRequired
};

export default SelectLocation;
