import React, { useState, useEffect } from "react";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Typography,
  CircularProgress
} from "@material-ui/core";
import PropTypes from "prop-types";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import video from "../../../assets/images/videovisual.png";
import article from "../../../assets/images/articlevisual.png";
import audio from "../../../assets/images/audiovisual.png";
import url from "../../../assets/images/linkvisual.png";
import Youtube from "../../../assets/images/Youtube.png";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import mobile from "../../../assets/images/mobile.png";
import proximityGraphic from "../../../assets/images/proximityGraphic.png";
import {
  getCurrentUser, // get current user from firebase
  getCurrentCompanyKey, // get current company key of signed-in user
  getAssignedBeacons, // get beacons assigned to particular location with firebase
  uploadTaskPromise, // upload to storage and get the url
  updateCampaign
} from "../../../controllers/firebase";
import {
  ValidateTitleCampaign,
  ValidateDescriptionCampaign,
  ValidateDropDown,
  isFileImage,
  isFileAudio,
  isFileVideo,
  ValidateBlank
} from "../../../helpers/validators";
import "./Campaign.css";

const sectionStyle = {
  width: "500px",
  height: "500px",
  backgroundImage: `url(${mobile})`,
  backgroundRepeat: "no-repeat"
};
const myStyle = {
  padding: "160px 25px 10px 153px ",
  width: "172px",
  height: "150px"
};
const dropDownStyle = {
  minWidth: "100px"
};
const myStyle1 = {
  width: "400px",
  height: "400px",
  marginLeft: "30px"
};

const defaultCampaign = {
  title: "",
  age: { above: null, below: null },
  gender: "",
  uuid: "",
  type: "",
  image: "",
  content: "",
  description: "",
  priority: "",
  proximity: "",
  when: "",
  days: "",
  active: false
};
const counter = [];
const disabledWeekList = {
  Monday: {
    day: "Monday",
    active: false,
    from: "00:00",
    to: "23:59"
  },
  Tuesday: {
    day: "Tuesday",
    active: false,
    from: "00:00",
    to: "23:59"
  },
  Wednesday: {
    day: "Wednesday",
    active: false,
    from: "00:00",
    to: "23:59"
  },
  Thursday: {
    day: "Thursday",
    active: false,
    from: "00:00",
    to: "23:59"
  },
  Friday: {
    day: "Friday",
    active: false,
    from: "00:00",
    to: "23:59"
  },
  Saturday: {
    day: "Saturday",
    active: false,
    from: "00:00",
    to: "23:59"
  },
  Sunday: {
    day: "Sunday",
    active: false,
    from: "00:00",
    to: "23:59"
  }
};
let checkUploaded = true;

function AddCampaignDialogBox({
  campaign = defaultCampaign,
  location,
  updateCampaigns,
  closeDialog,
  ...props
}) {
  const [weekList, setWeekList] = useState(
    JSON.parse(JSON.stringify(disabledWeekList))
  );
  const [info, setInfo] = useState(campaign);
  const [active, setActive] = useState(false);
  const [openCancel, setOpenCancel] = React.useState(false);
  // variables to store beacon values fetched from firebase
  const [beacons, setBeacons] = useState();
  // variable to store company key fetched from firebase
  const [companyKey, setCompanyKey] = useState();
  // variable to store the userid fetched from firebase
  const [uid, setUid] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [errorsForm, setErrorsForm] = useState({
    beaconSelect: {
      status: false
    },
    title: {
      status: false
    },
    description: {
      status: false
    },
    uploadTypeSelect: {
      status: false
    },
    data: {
      status: false
    },
    image: {
      status: false
    }
  });

  useEffect(() => {
    // method to intialize all the variables which are fecthed from firebase
    getCurrentUser(u => {
      if (u) {
        setUid(u.uid);
        getCurrentCompanyKey().then(e => {
          setCompanyKey(e);
          getBeacon();
        });
      }
    });
    setActive(true);
    if (campaign && campaign.type === "Image") {
      setImageUrl(campaign.content);
    } else {
      setImageUrl(null);
    }
    // if (campaign) {
    //   if (campaign.dayList && campaign.dayList.length !== 0) {
    //     setActive(true);
    //   }
    // }
    //eslint-disble-next-line
  }, [campaign]);

  useEffect(() => {
    const tempWeekList = JSON.parse(JSON.stringify(disabledWeekList));

    if (info.dayList && info.dayList.length !== 0) {
      info.dayList.forEach(d => {
        tempWeekList[d.day] = { ...d, active: true };
      });
    }
    setWeekList(tempWeekList);
  }, [info.dayList]);

  const getBeacon = () => {
    if (location) {
      getAssignedBeacons(location.id).then(array => {
        setBeacons([...array]);
      });
    }
  };

  // to enable and disable the time range
  const handleDayClick = d => {
    if (active) {
      weekList[d.day].active = !weekList[d.day].active;
      const newDayList = [];
      Object.values(weekList).forEach(day => {
        if (day.active) {
          newDayList.push({
            day: day.day,
            from: day.from,
            to: day.to
          });
        }
      });
      setInfo({ ...info, dayList: [...newDayList] });
      setWeekList({ ...weekList });
    }
  };

  // to set the info values
  const handleChange = e => {
    switch (e.target.name) {
      case "above":
        setInfo({ ...info, age: { ...info.age, above: e.target.value } });
        break;
      case "below":
        setInfo({ ...info, age: { ...info.age, below: e.target.value } });
        break;
      default:
        if (e.target.name === "uuid") {
          if (ValidateDropDown(e.target.value)) {
            setInfo({ ...info, [e.target.name]: { value: e.target.value } });
            setErrorsForm({
              ...errorsForm,
              beaconSelect: {
                status: false,
                error: "enter valid name input"
              }
            });
          } else {
            setErrorsForm({
              ...errorsForm,
              beaconSelect: {
                status: true,
                error: "enter valid name input"
              }
            });
          }
        } else if (e.target.name === "type") {
          if (ValidateDropDown(e.target.value)) {
            checkUploaded = false;
            setImageUrl(null);
            setInfo({ ...info, [e.target.name]: { value: e.target.value } });
            setErrorsForm({
              ...errorsForm,
              uploadTypeSelect: {
                status: false,
                error: "enter valid name input"
              }
            });
          } else {
            setErrorsForm({
              ...errorsForm,
              uploadTypeSelect: {
                status: true,
                error: "enter valid name input"
              }
            });
          }
        } else if (e.target.name === "content") {
          if (ValidateBlank(e.target.value)) {
            setInfo({ ...info, [e.target.name]: { value: e.target.value } });
            checkUploaded = true;
            setErrorsForm({
              ...errorsForm,
              data: {
                status: false,
                error: "enter valid name input"
              }
            });
          } else {
            setErrorsForm({
              ...errorsForm,
              data: {
                status: true,
                error: "enter valid name input"
              }
            });
          }
        } else if (e.target.name === "title") {
          if (ValidateTitleCampaign(e.target.value)) {
            setInfo({ ...info, [e.target.name]: { value: e.target.value } });
            setErrorsForm({
              ...errorsForm,
              title: {
                status: false,
                error: "enter valid name input"
              }
            });
          } else {
            setErrorsForm({
              ...errorsForm,
              title: {
                status: true,
                error: "enter valid name input"
              }
            });
          }
        } else if (e.target.name === "description") {
          if (ValidateDescriptionCampaign(e.target.value)) {
            setInfo({ ...info, [e.target.name]: { value: e.target.value } });
            setErrorsForm({
              ...errorsForm,
              description: {
                status: false,
                error: "enter valid name input"
              }
            });
          } else {
            setErrorsForm({
              ...errorsForm,
              description: {
                status: true,
                error: "enter valid name input"
              }
            });
          }
        }
        setInfo({ ...info, [e.target.name]: e.target.value });
        break;
    }
  };

  // const handleImageChange = async e => {
  //   if (ValidateDropDown(e.target.value)) {
  //     checkUploaded = true;
  //     const image = e.target.files[0];
  //     setImageLoading(true);
  //     setInfo({
  //       ...info,
  //       [e.target.name]: { value: image }
  //     });
  //     const storageUrl = await uploadTaskPromise(info.type, image);
  //     setImageUrl(storageUrl);
  //     setInfo({ ...info, content: storageUrl });
  //     setImageLoading(false);
  //     setErrorsForm({
  //       ...errorsForm,
  //       image: {
  //         status: false,
  //         error: "enter valid name input"
  //       }
  //     });
  //   } else {
  //     setErrorsForm({
  //       ...errorsForm,
  //       image: {
  //         status: true,
  //         error: "enter valid name input"
  //       }
  //     });
  //   }
  // };
  const handleImageChange = async e => {
    checkUploaded = false;
    setImageUrl(null);
    if (isFileImage(e.target.files[0])) {
      if (ValidateDropDown(e.target.value)) {
        if (e.target.files[0].size < 10485760) {
          checkUploaded = true;

          setLoading(true);
          const storageUrl = await uploadTaskPromise(
            info.type,
            e.target.files[0]
          );

          setImageUrl(storageUrl);

          setInfo({ ...info, content: storageUrl });
          setLoading(false);
          setErrorsForm({
            ...errorsForm,
            image: {
              status: false,
              error: "enter valid name input"
            }
          });
        } else {
          alert("Please upload a video of size smaller than 10Mb");
        }
      } else {
        setErrorsForm({
          ...errorsForm,
          image: {
            status: true,
            error: "enter valid name input"
          }
        });
      }
    } else {
      alert("Please upload an image type");
    }
  };
  ///////////////////////////////////////////////////////////////

  const handleVideoChange = async e => {
    checkUploaded = false;
    setImageUrl(null);
    if (isFileVideo(e.target.files[0])) {
      if (ValidateDropDown(e.target.value)) {
        if (e.target.files[0].size < 10485760) {
          checkUploaded = true;

          setLoading(true);
          const storageUrl = await uploadTaskPromise(
            info.type,
            e.target.files[0]
          );

          setImageUrl(storageUrl);

          setInfo({ ...info, content: storageUrl });
          setLoading(false);
          setErrorsForm({
            ...errorsForm,
            image: {
              status: false,
              error: "enter valid name input"
            }
          });
        } else {
          alert("Please upload a video of size smaller than 10Mb");
        }
      } else {
        setErrorsForm({
          ...errorsForm,
          image: {
            status: true,
            error: "enter valid name input"
          }
        });
      }
    } else {
      alert("Please upload an video type");
    }
  };

  const handleAudioChange = async e => {
    checkUploaded = false;
    setImageUrl(null);
    if (isFileAudio(e.target.files[0])) {
      if (ValidateDropDown(e.target.value)) {
        if (e.target.files[0].size < 10485760) {
          checkUploaded = true;

          setLoading(true);
          const storageUrl = await uploadTaskPromise(
            info.type,
            e.target.files[0]
          );

          setImageUrl(storageUrl);

          setInfo({ ...info, content: storageUrl });
          setLoading(false);
          setErrorsForm({
            ...errorsForm,
            image: {
              status: false,
              error: "enter valid name input"
            }
          });
        } else {
          alert("Please upload a video of size smaller than 10Mb");
        }
      } else {
        setErrorsForm({
          ...errorsForm,
          image: {
            status: true,
            error: "enter valid name input"
          }
        });
      }
    } else {
      alert("Please upload an audio type");
    }
  };

  //////////////////////////////////////////////////////////////

  const handleClockFromChange = (e, d) => {
    info.dayList.forEach(day => {
      if (day.day === d.day) {
        day.from = e.target.value;
      }
    });
    setInfo({ ...info, dayList: [...info.dayList] });
  };

  const handleClockToChange = (e, d) => {
    info.dayList.forEach(day => {
      if (day.day === d.day) {
        day.to = e.target.value;
      }
    });
    setInfo({ ...info, dayList: [...info.dayList] });
  };

  // this function is connected to the add button

  const handleClickAdd = async () => {
    if (
      info.title &&
      info.description &&
      info.uuid &&
      info.type &&
      info.content &&
      checkUploaded &&
      !errorsForm.beaconSelect.status &&
      !errorsForm.title.status &&
      !errorsForm.description.status &&
      !errorsForm.uploadTypeSelect.status &&
      !errorsForm.data.status &&
      !errorsForm.image.status
    ) {
      setLoading(true);
      const dayList = [];
      Object.values(weekList).forEach(d => {
        if (d.active) {
          dayList.push({
            day: d.day,
            from: d.from,
            to: d.from
          });
        }
      });
      if (info.type === "Youtube") {
        let index = info.content.indexOf("=");
        info.content = info.content.slice(index + 1);
      }
      info.uid = uid;
      await updateCampaign(info);
      updateCampaigns(info);
      checkUploaded = true;
      setLoading(false);
      closeDialog();
    } else {
      alert(
        "Please fill all the input feilds:beacon,title,upload type,upload input,description"
      );
    }
  };

  // const handleClose = () => {
  //   checkUploaded = true;
  //   closeDialog();
  // };

  /////////////////////////////////////////////////////////////////////////////////////
  const handleClose = () => {
    setOpenCancel(true);
  };

  const denyCancellation = () => {
    setOpenCancel(false);
  };
  const confirmCancellation = () => {
    //setWeekList({ ...prevInfo.dayList });
    setOpenCancel(false);

    checkUploaded = true;

    closeDialog();
  };
  //////////////////////////////////////////////////////////////////////////////////////

  function getCounterList() {
    if (counter.length === 0) {
      for (let i = 1; i <= 100; i++) {
        counter.push(i);
      }
    }
    return counter.map((c, index) => (
      <MenuItem value={c} key={index}>
        {c}
      </MenuItem>
    ));
  }
  return (
    <div>
      <DialogTitle id="form-dialog-title">Edit Campaign</DialogTitle>
      <DialogContent>
        <Dialog
          open={imageLoading || loading}
          onClose={imageLoading || loading}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <DialogContentText>
              Please Wait <CircularProgress color="blue" size="16px" />
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <>
          <Grid container style={{ minWidth: "1200px" }}>
            <Grid item xs={12} sm={6}>
              <Grid item>
                <FormControl style={dropDownStyle}>
                  <InputLabel
                    htmlFor="beacon-simple"
                    shrink={info.uuid === "" ? false : true}
                  >
                    Beacon
                  </InputLabel>
                  <Select
                    error={errorsForm.beaconSelect.status}
                    value={info.uuid}
                    onChange={handleChange}
                    inputProps={{
                      name: "uuid",
                      id: "beacon-simple"
                    }}
                    // MenuProps={MenuProps}
                  >
                    {beacons &&
                      beacons.map((beacon, index) => (
                        <MenuItem value={beacon.uuid} key={index}>
                          {beacon.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  id="outlined-name"
                  label="Title "
                  error={errorsForm.title.status}
                  helperText={
                    errorsForm.title.status &&
                    "invalid input,please enter a valid input with 32 characters,no space,"
                  }
                  value={info.title}
                  name="title"
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <FormControl style={{ minWidth: "120px" }}>
                  <InputLabel
                    htmlFor="type-simple"
                    shrink={info.type === "" ? false : true}
                  >
                    Upload Type
                  </InputLabel>
                  <Select
                    error={errorsForm.uploadTypeSelect.status}
                    value={info.type}
                    onChange={handleChange}
                    inputProps={{
                      name: "type",
                      id: "type-simple"
                    }}
                  >
                    <MenuItem value="Image">Image</MenuItem>
                    {!props.subLoc.navSublocationId && (
                      <MenuItem value="Article">Article</MenuItem>
                    )}
                    {!props.subLoc.navSublocationId && (
                      <MenuItem value="Video">Video</MenuItem>
                    )}
                    {!props.subLoc.navSublocationId && (
                      <MenuItem value="Audio">Audio</MenuItem>
                    )}
                    {!props.subLoc.navSublocationId && (
                      <MenuItem value="Youtube">Youtube</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                {info.type === "Image" ? (
                  <>
                    {!imageLoading ? (
                      <>
                        <input
                          accept="image/*"
                          type="file"
                          name="image"
                          onChange={handleImageChange}
                          error={errorsForm.image.status}
                          style={{ display: "none" }}
                          id="raised-button-file"
                          multiple
                          color="#29abe2"
                        />
                        <label htmlFor="raised-button-file">
                          <Button variant="raised" component="span">
                            Upload Image (Max size:10MB)
                            <CloudUploadIcon style={{ color: "#29abe2" }} />
                          </Button>
                        </label>
                        {checkUploaded && (
                          <React.Fragment>
                            <span style={{ marginLeft: "1%" }}>
                              The file is uploaded{" "}
                            </span>
                            <CheckCircleOutlineIcon
                              style={{ color: "29abe2" }}
                            />
                          </React.Fragment>
                        )}
                      </>
                    ) : (
                      <CircularProgress />
                    )}
                  </>
                ) : info.type === "Article" ? (
                  <TextField
                    id="outlined-name"
                    value={info.content}
                    error={errorsForm.data.status}
                    helperText={
                      errorsForm.data.status &&
                      "please enter valid input,don't leave the field empty"
                    }
                    placeholder="Paste the URL here ..."
                    rows={5}
                    multiline
                    name="content"
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                ) : info.type === "Video" ? (
                  <>
                    {!imageLoading ? (
                      <>
                        <input
                          accept="video/*"
                          type="file"
                          name="video"
                          onChange={handleVideoChange}
                          error={errorsForm.image.status}
                          style={{ display: "none" }}
                          id="raised-button-file"
                          multiple
                          color="#29abe2"
                        />
                        <label htmlFor="raised-button-file">
                          <Button variant="raised" component="span">
                            Upload a video (Max size:10MB)
                            <CloudUploadIcon style={{ color: "#29abe2" }} />
                          </Button>
                        </label>
                        {checkUploaded && (
                          <React.Fragment>
                            <span style={{ marginLeft: "1%" }}>
                              The file is uploaded{" "}
                            </span>
                            <CheckCircleOutlineIcon
                              style={{ color: "29abe2" }}
                            />
                          </React.Fragment>
                        )}
                      </>
                    ) : (
                      <CircularProgress />
                    )}
                  </>
                ) : info.type === "Audio" ? (
                  <>
                    <input
                      accept="audio/*"
                      type="file"
                      name="Audio"
                      onChange={handleAudioChange}
                      error={errorsForm.image.status}
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      color="#29abe2"
                    />
                    <label htmlFor="raised-button-file">
                      <Button variant="raised" component="span">
                        Upload an Audio (Max size:10MB)
                        <CloudUploadIcon style={{ color: "#29abe2" }} />
                      </Button>
                    </label>
                    {checkUploaded && (
                      <React.Fragment>
                        <span style={{ marginLeft: "1%" }}>
                          The file is uploaded{" "}
                        </span>
                        <CheckCircleOutlineIcon style={{ color: "29abe2" }} />
                      </React.Fragment>
                    )}
                  </>
                ) : info.type === "Youtube" ? (
                  <TextField
                    id="outlined-name"
                    value={info.content}
                    error={errorsForm.data.status}
                    helperText={
                      errorsForm.data.status &&
                      "please enter valid input,don't leave the field empty"
                    }
                    placeholder="Paste the URL here ..."
                    name="content"
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </Grid>
              <Grid item>
                <TextField
                  id="outlined-name"
                  label="description"
                  name="description"
                  error={errorsForm.description.status}
                  helperText={
                    errorsForm.description.status &&
                    "invalid input,please enter a valid input with 128 characters,no space,"
                  }
                  value={info.description}
                  onChange={handleChange}
                  fullWidth
                  placeholder="Enter the details"
                  multiline
                  rows={8}
                  rowsMax={10}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid container item>
                <Grid sm={3} item>
                  <FormControl id="prioritystyle">
                    <InputLabel
                      htmlFor="prioirty-simple"
                      shrink={info.priority === "" ? false : true}
                    >
                      Priority
                    </InputLabel>
                    <Select
                      value={info.priority}
                      onChange={handleChange}
                      inputProps={{
                        name: "priority",
                        id: "priority-simple"
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value={1}>Low</MenuItem>
                      <MenuItem value={2}>Medium</MenuItem>
                      <MenuItem value={3}>High</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid sm={2} item>
                  <Typography> Above</Typography>
                  <FormControl style={{ marginLeft: "10px" }}>
                    <InputLabel
                      htmlFor="above-simple"
                      shrink={info.age.above === "" ? false : true}
                    >
                      Age
                    </InputLabel>
                    <Select
                      value={info.age.above}
                      onChange={handleChange}
                      inputProps={{
                        name: "above",
                        id: "above-simple"
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      {getCounterList()}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid sm={2} item>
                  <Typography>Below</Typography>
                  <FormControl style={{ marginLeft: "10px" }}>
                    <InputLabel
                      htmlFor="below-simple"
                      shrink={info.age.below === "" ? false : true}
                    >
                      Age
                    </InputLabel>
                    <Select
                      value={info.age.below}
                      onChange={handleChange}
                      inputProps={{
                        name: "below",
                        id: "below-simple"
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      {getCounterList()}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={3}>
                  <FormControl style={dropDownStyle}>
                    <InputLabel
                      htmlFor="gender-simple"
                      shrink={info.gender === "" ? false : true}
                    >
                      Gender
                    </InputLabel>
                    <Select
                      value={info.gender}
                      onChange={handleChange}
                      inputProps={{
                        name: "gender",
                        id: "gender-simple"
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl
                    style={{ marginLeft: "10px", minWidth: "100px" }}
                  >
                    <InputLabel
                      htmlFor="proximity-simple"
                      shrink={info.proximity === "" ? false : true}
                    >
                      Proximity
                    </InputLabel>
                    <Select
                      value={info.proximity}
                      onChange={handleChange}
                      inputProps={{
                        name: "proximity",
                        id: "proximity-simple"
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="Immediate">Immediate</MenuItem>
                      <MenuItem value="Near">Near</MenuItem>
                      <MenuItem value="Far">Far</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {props.subLoc.navSublocationId && (
                  <Grid item sm={3}>
                    <FormControl
                      style={{ marginLeft: "10px", minWidth: "100px" }}
                    >
                      <InputLabel
                        htmlFor="when-simple"
                        shrink={info.when === "" ? false : true}
                      >
                        When
                      </InputLabel>
                      <Select
                        value={info.when}
                        onChange={handleChange}
                        inputProps={{
                          name: "when",
                          id: "when-simple"
                        }}
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="Immediate">On Enter</MenuItem>
                        <MenuItem value="Near">On Leave</MenuItem>
                        <MenuItem value="Far">On Stay</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Grid container>
                <Grid item sm={10}>
                  <Typography id="timetabletypography">Timetable</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell id="start">Start</TableCell>
                        <TableCell id="end">End</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.values(weekList).map((day, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              <Button
                                variant="contained"
                                color={!day.active ? "default" : "primary"}
                                onClick={() => handleDayClick(day)}
                                id="daybutton"
                              >
                                {day.day}
                              </Button>
                            </TableCell>
                            <TableCell align="right">
                              <form noValidate>
                                <TextField
                                  id="time"
                                  type="time"
                                  onChange={e => handleClockFromChange(e, day)}
                                  value={day.active ? day.from : null}
                                  disabled={!day.active}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  inputProps={{
                                    step: 300
                                  }}
                                />
                              </form>
                            </TableCell>
                            <TableCell align="right">
                              <form noValidate>
                                <TextField
                                  id="time"
                                  type="time"
                                  value={day.active ? day.to : null}
                                  onChange={e => handleClockToChange(e, day)}
                                  disabled={!day.active}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  inputProps={{
                                    step: 300
                                  }}
                                />
                              </form>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div style={sectionStyle}>
                {info.type && info.type === "Video" ? (
                  <img src={video} style={myStyle} alt="" />
                ) : info.type === "Url" ? (
                  <img src={url} style={myStyle} alt="" />
                ) : info.type === "Article" ? (
                  <img src={article} style={myStyle} alt="" />
                ) : info.type === "Image" ? (
                  <img src={imageUrl} style={myStyle} alt="" />
                ) : info.type === "Youtube" ? (
                  <img src={Youtube} style={myStyle} alt="" />
                ) : info.type === "Audio" ? (
                  <img src={audio} style={myStyle} alt="" />
                ) : (
                  <></>
                )}
              </div>
              <img alt=" " src={proximityGraphic} style={myStyle1} />
            </Grid>
          </Grid>
        </>
        {/* //////////////////////////////////////////////////////////////////////////////////////// */}

        <Dialog
          open={openCancel}
          onClose={openCancel}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Cancel Campaign</DialogTitle>
          <DialogContent>
            <DialogContentText>
              By clicking on cancel all of your data will not be saved?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={denyCancellation} color="primary">
              no
            </Button>
            <Button
              onClick={confirmCancellation}
              variant="contained"
              color="primary"
              style={{ color: "white" }}
            >
              yes
            </Button>
          </DialogActions>
        </Dialog>

        {/* //////////////////////////////////////////////////////////////////////////////////////////// */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickAdd}
          style={{ color: "white" }}
        >
          {loading ? <CircularProgress color="white" /> : "confirm"}
        </Button>
      </DialogActions>
    </div>
  );
}

AddCampaignDialogBox.propTypes = {
  campaign: PropTypes.object.isRequired,

  location: PropTypes.object.isRequired,
  updateCampaigns: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired
};

export default AddCampaignDialogBox;
