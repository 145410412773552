/* eslint-disable import/prefer-default-export */
import firebase from "firebase";
/*
 ****************************************************************************
 * @brief  (fucntion getAdministrator) -  this function the details of the administrator
 * who is currently signed in
 *
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component takes the userid of the signed in user
 *
 * @return  what does the function return
 *-this functional component  returns  array of admin details
 ****************************************************************************
 */

export async function getAdministrator(uid) {
  try {
    const admin = [];

    const snapshot = await firebase
      .firestore()
      .collection("administrators")
      .where("uid", "==", uid)
      .get();

    snapshot.forEach(doc => {
      admin.push({
        name: doc.data().name,
        email: doc.data().email
      });
    });

    return admin;
  } catch (e) {
    throw Error(e);
  }
}
