/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-indent */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";

import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Dialog
} from "@material-ui/core";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";

import video from "../../../assets/images/videovisual.png";
import article from "../../../assets/images/articlevisual.png";
import audio from "../../../assets/images/audiovisual.png";
import url from "../../../assets/images/linkvisual.png";
import Youtube from "../../../assets/images/Youtube.png";

import AddCampaignDialogBox from "./AddCampaignDialogBox";
import DeleteCampaignDialogBox from "./DeleteCampaignDialogBox";
import EditCampaignDialogBox from "./EditCampaignDialogBox";
import addimage from "../../../assets/images/addsublocationimage.png";

import {
  getCurrentUser,
  getCurrentCompanyKey,
  getCampaigns
} from "../../../controllers/firebase";
import SelectLocation from "./SelectLocation";
import "./Campaign.css";

/*
Declearing the functional component 
*/
let counter;
function Campaigns() {
  const [locationHolder, setLocationHolder] = useState("");
  const [sublocationHolder, setSublocationHolder] = useState("");
  // variable to store company key fetched from firebase
  const [companyKey, setCompanyKey] = useState();
  // variable to store the userid fetched from firebase
  const [uid, setUid] = useState();
  const [open, setOpen] = useState(false);
  const [add, setAdd] = useState(false);
  const [campaigns, setCampaigns] = useState();
  const [selectedCampaign, setCampaign] = useState();
  const [selectedLocation, setLocation] = useState();

  useEffect(() => {
    getCurrentUser(u => {
      if (u) {
        setUid(u.uid);

        getCurrentCompanyKey().then(e => {
          setCompanyKey(e);
          getCampaignData(e);
        });
      }
    });
  }, [locationHolder, sublocationHolder]);

  function handleAddOpen() {
    setAdd(true);
  }

  function handleAddClose() {
    setAdd(false);
  }

  function handleClickOpen(campaign, location) {
    setLocation(location);
    setCampaign(campaign);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    if (!open) {
      setLocation(null);
      setCampaign(null);
    }
  }, [open]);

  const getCampaignData = cKey => {
    if (locationHolder && locationHolder.selectedType === "locationBased") {
      getCampaigns(cKey).then(array => {
        setCampaigns(array);
      });
    } else {
      getCampaigns(cKey).then(array => {
        const subLocCampaigns = array.filter(
          campaign =>
            //check again from != to !==
            campaign.navSublocationId !== sublocationHolder &&
            sublocationHolder.navSublocationId
        );

        setCampaigns(subLocCampaigns);
      });
    }
  };

  const getLocationHolder = lh => {
    setLocationHolder({ ...lh });
  };
  const getSubLocationHolder = slh => {
    setSublocationHolder({ ...slh });
  };

  const update = () => {
    getCampaignData(companyKey);
  };
  const countCampaigns = () => {
    counter = 0;
    campaigns &&
      campaigns.forEach(camp => {
        if (
          locationHolder.id === camp.locationId &&
          camp.navSublocationId == null
        ) {
          counter++;
        } else if (
          locationHolder.id === camp.locationId &&
          camp.navSublocationId === sublocationHolder.navSublocationId
        ) {
          counter++;
        }
      });
    return counter;
  };
  const updateCampaigns = info => {
    if (info) {
      let newCampaigns = [];
      if (campaigns) {
        newCampaigns = campaigns.map(camp => {
          if (camp.key === info.key) {
            camp = { ...camp, ...info };
          }
          return camp;
        });
      } else {
        newCampaigns = [info];
      }

      setCampaigns([...newCampaigns]);
    } else {
      getCampaignData(companyKey);
    }
  };

  // Add necessary comments in the return
  return (
    <div id="parentdiv">
      <div id="firstchilddiv">
        <SelectLocation
          getLocationHolder={getLocationHolder}
          getSubLocationHolder={getSubLocationHolder}
        />
      </div>
      <div id="secondchilddiv">
        <Grid container spacing={1}>
          <Grid item xs={12} id="titlegrid">
            {locationHolder.id !== "" && (
              <Typography variant="h3">
                {locationHolder && locationHolder.locationName} Campaigns
              </Typography>
            )}
          </Grid>
          {locationHolder.id &&
            campaigns &&
            campaigns.map(
              (campaign, index) =>
                locationHolder.id === campaign.locationId &&
                (campaign.navSublocationId == null ||
                  campaign.navSublocationId ===
                    sublocationHolder.navSublocationId) && (
                  <Grid item id="campaigngrid" key={index}>
                    <Card
                      style={
                        campaign.uuid === ""
                          ? {
                              boxShadow:
                                "0 4px 8px 0 red, 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                            }
                          : {
                              boxShadow:
                                "0 4px 8px 0 green, 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                            }
                      }
                    >
                      <div
                        style={{
                          position: "relative"
                        }}
                      >
                        {campaign.type === "Image" ? (
                          <CardMedia
                            style={{
                              height: "0",
                              paddingTop: "56.25%"
                            }}
                            image={campaign.content}
                          />
                        ) : campaign.type === "Video" ? (
                          <CardMedia
                            style={{
                              height: "0",
                              paddingTop: "56.25%"
                            }}
                            image={video}
                          />
                        ) : campaign.type === "Youtube" ? (
                          <CardMedia
                            style={{
                              height: "0",
                              paddingTop: "56.25%"
                            }}
                            image={Youtube}
                          />
                        ) : campaign.type === "Article" ? (
                          <CardMedia
                            style={{
                              height: "0",
                              paddingTop: "56.25%"
                            }}
                            image={article}
                          />
                        ) : campaign.type === "Url" ? (
                          <CardMedia
                            style={{
                              height: "0",
                              paddingTop: "56.25%"
                            }}
                            image={url}
                          />
                        ) : campaign.type === "Audio" ? (
                          <CardMedia
                            style={{
                              height: "0",
                              paddingTop: "56.25%"
                            }}
                            image={audio}
                          />
                        ) : (
                          <></>
                        )}
                      </div>

                      <CardContent id="cardcontent">
                        <div id="titledescriptiondiv">
                          <Typography id="campaigntitle">
                            {campaign.title}
                          </Typography>
                          <Typography>
                            Created by:
                            {campaign.displayName}
                          </Typography>
                          <Typography>
                            {new Date(campaign.timestamp)
                              .toString()
                              .slice(0, 25)}
                          </Typography>
                          <Typography id="campaigndescription">
                            {campaign.description &&
                            campaign.description.length > 75
                              ? campaign.description.slice(0, 75) + "...."
                              : campaign.description}
                          </Typography>
                        </div>
                      </CardContent>
                      <CardActions
                        disableSpacing
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <EditTwoToneIcon
                          style={{ color: "#A3A3A3" }}
                          onClick={() =>
                            handleClickOpen(campaign, locationHolder)
                          }
                        />

                        <DeleteCampaignDialogBox
                          campaign={campaign}
                          update={update}
                        />
                      </CardActions>
                    </Card>
                  </Grid>
                )
            )}

          {/* //////////////////////addinggggggggggggggggggggggggggggggg//////////////////// */}
          <Grid item id="addcampaigngrid">
            {countCampaigns() < 128 ? (
              locationHolder.navLocationId === null ? (
                <div onClick={handleAddOpen}>
                  <img src={addimage} id="addimage" alt="noImage" />
                </div>
              ) : (
                sublocationHolder.navSublocationId && (
                  <div onClick={handleAddOpen}>
                    <img src={addimage} id="addimage" alt="noImage" />
                  </div>
                )
              )
            ) : (
              <Typography color="secondary" variant="h5">
                {" "}
                you reached the maxmium number of campaigns which is 128
                campaigns.
              </Typography>
            )}
          </Grid>

          {/* ////////////////////////////////////////////////////////////////////// */}

          <Dialog
            open={add}
            onClose={handleAddClose}
            aria-labelledby="form-dialog-title"
            maxWidth="xl"
            disableBackdropClick="true"
            disableEscapeKeyDown="true"
          >
            <AddCampaignDialogBox
              loc={locationHolder}
              updateCampaigns={updateCampaigns}
              closeAddDialog={handleAddClose}
              campaigns={campaigns}
              subLoc={sublocationHolder}
            />
          </Dialog>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="xl"
            disableBackdropClick="true"
            disableEscapeKeyDown="true"
          >
            <EditCampaignDialogBox
              location={selectedLocation}
              campaign={selectedCampaign}
              updateCampaigns={updateCampaigns}
              closeDialog={handleClose}
              style={{ color: "#A3A3A3" }}
              subLoc={sublocationHolder}
            />
          </Dialog>
        </Grid>
      </div>
    </div>
  );
}

/*
Validating React Component Props
*/
Campaigns.propTypes = {};

export default Campaigns;
