/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Typography,
  CircularProgress
} from "@material-ui/core";
import axios from "axios";
import PropTypes from "prop-types";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import video from "../../../assets/images/videovisual.png";
import article from "../../../assets/images/articlevisual.png";
import audio from "../../../assets/images/audiovisual.png";
import url from "../../../assets/images/linkvisual.png";
import Youtube from "../../../assets/images/Youtube.png";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import mobile from "../../../assets/images/mobile.png";
import proximityGraphic from "../../../assets/images/proximityGraphic.png";
import {
  getCurrentUser, // get current user from firebase
  getCurrentCompanyKey, // get current company key of signed-in user
  getAssignedBeacons, // get beacons assigned to particular location with firebase
  setCampaigns, // to add the campaign data to firebase
  uploadTaskPromise // upload to storage and get the url
} from "../../../controllers/firebase";
import {
  ValidateTitleCampaign,
  ValidateDescriptionCampaign,
  ValidateDropDown,
  isFileImage,
  isFileAudio,
  isFileVideo,
  ValidateBlank,
  matchYoutubeUrl
} from "../../../helpers/validators";
import "./Campaign.css";

const sectionStyle = {
  width: "500px",
  height: "500px",
  backgroundImage: `url(${mobile})`,
  backgroundRepeat: "no-repeat"
};
const myStyle = {
  padding: "160px 25px 10px 153px ",
  width: "172px",
  height: "150px"
};
const dropDownStyle = {
  minWidth: "100px"
};
const myStyle_1 = {
  width: "400px",
  height: "400px",
  marginLeft: "30px"
};
const counter = [];
let checkUploaded = false;
let redundancy = false;

function AddCampaignDialogBox(props) {
  const [fileName, setFileName] = useState();
  const [openCancel, setOpenCancel] = React.useState(false);
  const [navSublocationId, setSublocationId] = useState(
    props.subLoc.navSublocationId
  );
  // to store the info
  const [info, setInfo] = useState({
    title: "",
    above: "",
    below: "",
    gender: "",
    uuid: "",
    type: "",
    image: "",
    data: "",
    description: "",
    priority: "",
    proximity: "",
    when: "",
    days: ""
  });

  const [baseInfo, setBaseInfo] = useState({
    title: "",
    above: "",
    below: "",
    gender: "",
    uuid: "",
    type: "",
    image: "",
    data: "",
    description: "",
    priority: "",
    proximity: "",
    when: "",
    days: ""
  });
  // variables to store beacon values fetched from firebase
  const [beacons, setBeacons] = useState();
  // variable to store company key fetched from firebase
  const [companyKey, setCompanyKey] = useState();
  // variable to store the userid fetched from firebase
  const [uid, setUid] = useState();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [timeZone, setTimeZone] = useState("");
  const [displayName, setDisplayName] = useState();

  // to store weekdays
  const [weekList, setWeekList] = useState([
    {
      day: "Monday",
      active: false,
      from: "00:00",
      to: "23:59"
    },
    {
      day: "Tuesday",
      active: false,
      from: "00:00",
      to: "23:59"
    },
    {
      day: "Wednesday",
      active: false,
      from: "00:00",
      to: "23:59"
    },
    {
      day: "Thursday",
      active: false,
      from: "00:00",
      to: "23:59"
    },
    {
      day: "Friday",
      active: false,
      from: "00:00",
      to: "23:59"
    },
    {
      day: "Saturday",
      active: false,
      from: "00:00",
      to: "23:59"
    },
    {
      day: "Sunday",
      active: false,
      from: "00:00",
      to: "23:59"
    }
  ]);

  const [baseWeekList, setBaseWeekList] = useState([
    {
      day: "Monday",
      active: false,
      from: "00:00",
      to: "23:59"
    },
    {
      day: "Tuesday",
      active: false,
      from: "00:00",
      to: "23:59"
    },
    {
      day: "Wednesday",
      active: false,
      from: "00:00",
      to: "23:59"
    },
    {
      day: "Thursday",
      active: false,
      from: "00:00",
      to: "23:59"
    },
    {
      day: "Friday",
      active: false,
      from: "00:00",
      to: "23:59"
    },
    {
      day: "Saturday",
      active: false,
      from: "00:00",
      to: "23:59"
    },
    {
      day: "Sunday",
      active: false,
      from: "00:00",
      to: "23:59"
    }
  ]);
  const [errorsForm, setErrorsForm] = useState({
    beaconSelect: {
      status: false
    },
    title: {
      status: false
    },
    description: {
      status: false
    },
    uploadTypeSelect: {
      status: false
    },
    data: {
      status: false
    },
    image: {
      status: false
    }
  });
  const [errorsFormBase, setErrorsFormBase] = useState({
    beaconSelect: {
      status: false
    },
    title: {
      status: false
    },
    description: {
      status: false
    },
    uploadTypeSelect: {
      status: false
    },
    data: {
      status: false
    },
    image: {
      status: false
    }
  });
  const timestamp = new Date().getTime();

  useEffect(() => {
    // method to intialize all the variables which are fecthed from firebase
    init();
  }, []);
  const init = async () => {
    getCurrentUser(u => {
      if (u) {
        setUid(u.uid);
        setDisplayName(u.displayName);
        getCurrentCompanyKey().then(e => {
          setCompanyKey(e);
        });
      }
    });
    getTimeZone();
  };
  useEffect(() => {
    getBeacons(props.loc.id);
  }, [props.loc.id]);

  const getBeacons = async locId => {
    if (props.loc.selectedType === "locationBased") {
      const array = await getAssignedBeacons(locId);
      setBeacons([...array]);
    } else {
      const array = await getAssignedBeacons(locId);

      const subLocBeacons = array.filter(
        //check changed from == to ===
        beacon => beacon.navSublocationId === props.subLoc.navSublocationId
      );
      setBeacons([...subLocBeacons]);
    }
  };

  const getTimeZone = async () => {
    const timestamp = Math.floor(new Date().getTime() / 1000);

    await axios
      .get(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${props.loc.lat},${props.loc.lng}&timestamp=${timestamp}&key=AIzaSyDfblTRdOWex-cI7y6Rl4O5uaR8C0zmfWc`
      )
      .then(res => {
        setTimeZone(res.data.timeZoneId);
      });
  };

  // to enable and disable the time range
  const handleDayClick = d => {
    weekList.map(day => day === d && (day.active = !day.active));
    setInfo({ ...info, days: { value: weekList } });
    setWeekList([...weekList]);
  };

  // to set the info values
  const handleChange = e => {
    if (e.target.name === "uuid") {
      if (ValidateDropDown(e.target.value)) {
        setInfo({ ...info, [e.target.name]: { value: e.target.value } });
        setErrorsForm({
          ...errorsForm,
          beaconSelect: {
            status: false,
            error: "enter valid name input"
          }
        });
      } else {
        setErrorsForm({
          ...errorsForm,
          beaconSelect: {
            status: true,
            error: "enter valid name input"
          }
        });
      }
    } else if (e.target.name === "type") {
      if (ValidateDropDown(e.target.value)) {
        checkUploaded = false;

        setImageUrl(null);

        setInfo({ ...info, [e.target.name]: { value: e.target.value } });

        //setImageUrl(null);
        setErrorsForm({
          ...errorsForm,
          uploadTypeSelect: {
            status: false,
            error: "enter valid name input"
          }
        });
      } else {
        setErrorsForm({
          ...errorsForm,
          uploadTypeSelect: {
            status: true,
            error: "enter valid name input"
          }
        });
      }
    } else if (e.target.name === "data") {
      if (ValidateBlank(e.target.value)) {
        if (info.type.value === "Youtube") {
          if (matchYoutubeUrl(e.target.value)) {
            setInfo({ ...info, [e.target.name]: { value: e.target.value } });
            checkUploaded = true;
            setErrorsForm({
              ...errorsForm,
              data: {
                status: false,
                error: "enter valid name input"
              }
            });
          } else {
            setErrorsForm({
              ...errorsForm,
              data: {
                status: true,
                error: "enter valid name input"
              }
            });
          }
        } else {
          setInfo({ ...info, [e.target.name]: { value: e.target.value } });
          checkUploaded = true;
          setErrorsForm({
            ...errorsForm,
            data: {
              status: false,
              error: "enter valid name input"
            }
          });
        }
      } else {
        setErrorsForm({
          ...errorsForm,
          data: {
            status: true,
            error: "enter valid name input"
          }
        });
      }
    } else if (e.target.name === "title") {
      if (ValidateTitleCampaign(e.target.value)) {
        setInfo({ ...info, [e.target.name]: { value: e.target.value } });
        setErrorsForm({
          ...errorsForm,
          title: {
            status: false,
            error: "enter valid name input"
          }
        });
      } else {
        setErrorsForm({
          ...errorsForm,
          title: {
            status: true,
            error: "enter valid name input"
          }
        });
      }
    } else if (e.target.name === "description") {
      if (ValidateDescriptionCampaign(e.target.value)) {
        setInfo({ ...info, [e.target.name]: { value: e.target.value } });
        setErrorsForm({
          ...errorsForm,
          description: {
            status: false,
            error: "enter valid name input"
          }
        });
      } else {
        setErrorsForm({
          ...errorsForm,
          description: {
            status: true,
            error: "enter valid name input"
          }
        });
      }
    }

    setInfo({ ...info, [e.target.name]: { value: e.target.value } });
  };
  const handleImageChange = async e => {
    checkUploaded = false;

    setImageUrl(null);
    if (isFileImage(e.target.files[0])) {
      if (ValidateDropDown(e.target.value)) {
        if (e.target.files[0].size < 10485760) {
          // setInfo({
          //   ...info,
          //   [e.target.name]: { value: e.target.files[0] }
          // });
          setFileName(e.target.files[0].name);

          setLoading(true);
          const storageUrl = await uploadTaskPromise(
            info.type.value,
            e.target.files[0]
          );

          setImageUrl(storageUrl);

          setInfo({ ...info, data: { value: storageUrl } });
          setLoading(false);
          checkUploaded = true;
          setErrorsForm({
            ...errorsForm,
            image: {
              status: false,
              error: "enter valid name input"
            }
          });
        } else {
          alert("Please upload a video of size smaller than 10Mb");
        }
      } else {
        setErrorsForm({
          ...errorsForm,
          image: {
            status: true,
            error: "enter valid name input"
          }
        });
      }
    } else {
      alert("Please upload an image type");
    }
  };
  ///////////////////////////////////////////////////////////////

  const handleVideoChange = async e => {
    checkUploaded = false;
    setImageUrl(null);
    if (isFileVideo(e.target.files[0])) {
      if (ValidateDropDown(e.target.value)) {
        if (e.target.files[0].size < 10485760) {
          setFileName(e.target.files[0].name);
          setLoading(true);
          const storageUrl = await uploadTaskPromise(
            info.type.value,
            e.target.files[0]
          );

          setImageUrl(storageUrl);

          setInfo({ ...info, data: { value: storageUrl } });
          setLoading(false);
          checkUploaded = true;
          setErrorsForm({
            ...errorsForm,
            image: {
              status: false,
              error: "enter valid name input"
            }
          });
        } else {
          alert("Please upload a video of size smaller than 10Mb");
        }
      } else {
        setErrorsForm({
          ...errorsForm,
          image: {
            status: true,
            error: "enter valid name input"
          }
        });
      }
    } else {
      alert("Please upload an video type");
    }
  };

  const handleAudioChange = async e => {
    checkUploaded = false;
    setImageUrl(null);
    if (isFileAudio(e.target.files[0])) {
      if (ValidateDropDown(e.target.value)) {
        if (e.target.files[0].size < 10485760) {
          setFileName(e.target.files[0].name);
          setLoading(true);
          const storageUrl = await uploadTaskPromise(
            info.type.value,
            e.target.files[0]
          );

          setImageUrl(storageUrl);

          setInfo({ ...info, data: { value: storageUrl } });
          setLoading(false);
          checkUploaded = true;
          setErrorsForm({
            ...errorsForm,
            image: {
              status: false,
              error: "enter valid name input"
            }
          });
        } else {
          alert("Please upload a video of size smaller than 10Mb");
        }
      } else {
        setErrorsForm({
          ...errorsForm,
          image: {
            status: true,
            error: "enter valid name input"
          }
        });
      }
    } else {
      alert("Please upload an audio type");
    }
  };

  //////////////////////////////////////////////////////////////

  function getCounterList() {
    if (counter.length === 0) {
      for (let i = 1; i <= 100; i++) {
        counter.push(i);
      }
    }
    return counter.map((c, index) => (
      <MenuItem value={c} key={index}>
        {c}
      </MenuItem>
    ));
  }

  const handleClockFromChange = (e, d) => {
    weekList.map(day => day === d && (day.from = e.target.value));
  };
  const handleClockToChange = (e, d) => {
    weekList.map(day => day === d && (day.to = e.target.value));
  };

  // this function is connected to the add button
  const handleClickAdd = async () => {
    let countDays = 0;

    props.campaigns &&
      info.title.value &&
      props.campaigns.forEach(camp => {
        if (
          camp.title.trim().toLowerCase() ===
            info.title.value.trim().toLowerCase() &&
          camp.uuid === info.uuid.value &&
          camp.type === info.type.value
        ) {
          redundancy = true;
        } else {
          redundancy = false;
        }
      });
    weekList.forEach(day => {
      if (day.active === false) {
        countDays++;
      }
      if (day.from === "" && day.active === true) {
        day.from = "00:00";
      }
      if (day.to === "" && day.active === true) {
        day.to = "23:59";
      }
    });

    if (
      info.title &&
      info.description &&
      info.uuid &&
      info.type &&
      info.data &&
      checkUploaded &&
      !errorsForm.beaconSelect.status &&
      !errorsForm.title.status &&
      !errorsForm.description.status &&
      !errorsForm.uploadTypeSelect.status &&
      !errorsForm.data.status &&
      !errorsForm.image.status &&
      !(countDays === 7)
    ) {
      if (redundancy) {
        alert(
          "There is a campaign already assigned to this beacon,Please add a new camapign"
        );
      } else {
        setLoading(true);
        const dayList = [];
        if (info.days && info.days.value) {
          info.days.value.forEach(d => {
            if (d.active) {
              dayList.push({
                day: d.day,
                from: d.from,
                to: d.to
              });
            }
          });
        }
        if (info.type.value === "Youtube") {
          let index = info.data.value.indexOf("=");
          info.data.value = info.data.value.slice(index + 1);
        }

        await setCampaigns(
          info.title.value,
          info.above.value,
          info.below.value,
          info.uuid.value,
          info.gender.value,
          info.type.value,
          info.data.value,
          info.description.value,
          info.priority.value,
          info.proximity.value,
          info.when.value,
          dayList,
          props.loc.id,
          uid,
          companyKey,
          timeZone,
          timestamp,
          navSublocationId,
          displayName
        );

        setLoading(false);
        checkUploaded = false;

        setInfo(baseInfo);
        setWeekList([...baseWeekList]);
        setImageUrl(null);
        //setOpen(false);
        setErrorsForm(errorsFormBase);
        props.updateCampaigns();
        props.closeAddDialog();
      }
    } else {
      alert(
        "Please fill all the input feilds:beacon,title,upload type,upload input,description"
      );
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const handleClose = () => {
    setOpenCancel(true);
  };

  const denyCancellation = () => {
    setOpenCancel(false);
  };
  const confirmCancellation = () => {
    setOpenCancel(false);
    redundancy = false;
    props.closeAddDialog();
  };

  ///////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      <DialogTitle id="form-dialog-title">Add Campaign</DialogTitle>
      <DialogContent>
        <Dialog
          open={loading}
          onClose={loading}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <DialogContentText>
              Please Wait <CircularProgress color="blue" size="16px" />
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <>
          <Grid container style={{ minWidth: "1200px" }}>
            <Grid item xs={12} sm={6}>
              <Grid item>
                <FormControl style={dropDownStyle}>
                  <InputLabel
                    htmlFor="beacon-simple"
                    shrink={info.uuid === "" ? false : true}
                  >
                    Beacon
                  </InputLabel>
                  <Select
                    error={errorsForm.beaconSelect.status}
                    value={info.uuid.value}
                    onChange={handleChange}
                    required
                    inputProps={{
                      name: "uuid",
                      id: "beacon-simple"
                    }}
                  >
                    {beacons &&
                      beacons.map((beacon, index) => (
                        <MenuItem value={beacon.uuid} key={index}>
                          {beacon.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  id="outlined-name"
                  label="Title "
                  name="title"
                  error={errorsForm.title.status}
                  helperText={
                    errorsForm.title.status &&
                    "invalid input,please enter a valid input with 32 characters,no space,"
                  }
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item>
                {" "}
                <FormControl style={{ minWidth: "120px" }}>
                  <InputLabel
                    htmlFor="type-simple"
                    shrink={info.type === "" ? false : true}
                  >
                    Upload Type
                  </InputLabel>
                  <Select
                    value={info.type.value}
                    error={errorsForm.uploadTypeSelect.status}
                    onChange={handleChange}
                    inputProps={{
                      name: "type",
                      id: "type-simple"
                    }}
                  >
                    <MenuItem value="Image">Image</MenuItem>
                    {!props.subLoc.navSublocationId && (
                      <MenuItem value="Article">Article</MenuItem>
                    )}
                    {!props.subLoc.navSublocationId && (
                      <MenuItem value="Video">Video</MenuItem>
                    )}
                    {!props.subLoc.navSublocationId && (
                      <MenuItem value="Audio">Audio</MenuItem>
                    )}
                    {!props.subLoc.navSublocationId && (
                      <MenuItem value="Youtube">Youtube</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                {info.type.value === "Image" ? (
                  <>
                    <input
                      accept="image/*"
                      type="file"
                      name="image"
                      onChange={handleImageChange}
                      error={errorsForm.image.status}
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      color="#29abe2"
                    />
                    <label htmlFor="raised-button-file">
                      <Button variant="raised" component="span">
                        Upload Image (Max size:10MB)
                        <CloudUploadIcon style={{ color: "#29abe2" }} />
                      </Button>
                    </label>
                    {checkUploaded && (
                      <React.Fragment>
                        <span style={{ marginLeft: "1%" }}>
                          {fileName} is uploaded{" "}
                        </span>
                        <CheckCircleOutlineIcon style={{ color: "29abe2" }} />
                      </React.Fragment>
                    )}
                  </>
                ) : info.type.value === "Article" ? (
                  <TextField
                    id="outlined-name"
                    placeholder="Please post your article here"
                    name="data"
                    error={errorsForm.data.status}
                    helperText={
                      errorsForm.data.status &&
                      "please enter valid input,don't leave the field empty"
                    }
                    rows={4}
                    multiline
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                ) : info.type.value === "Video" ? (
                  <>
                    <input
                      accept="video/*"
                      type="file"
                      name="video"
                      onChange={handleVideoChange}
                      error={errorsForm.image.status}
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      color="#29abe2"
                    />
                    <label htmlFor="raised-button-file">
                      <Button variant="raised" component="span">
                        Upload a Video (Maxsize:10MB)
                        <CloudUploadIcon style={{ color: "#29abe2" }} />
                      </Button>
                    </label>
                    {checkUploaded && (
                      <React.Fragment>
                        <span style={{ marginLeft: "1%" }}>
                          {fileName} is uploaded{" "}
                        </span>
                        <CheckCircleOutlineIcon style={{ color: "29abe2" }} />
                      </React.Fragment>
                    )}
                  </>
                ) : info.type.value === "Audio" ? (
                  <>
                    <input
                      accept="audio/*"
                      type="file"
                      name="Audio"
                      onChange={handleAudioChange}
                      error={errorsForm.image.status}
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      color="#29abe2"
                    />
                    <label htmlFor="raised-button-file">
                      <Button variant="raised" component="span">
                        Upload an Audio (Max size:10MB)
                        <CloudUploadIcon style={{ color: "#29abe2" }} />
                      </Button>
                    </label>
                    {checkUploaded && (
                      <React.Fragment>
                        <span style={{ marginLeft: "1%" }}>
                          [{fileName} is uploaded{" "}
                        </span>
                        <CheckCircleOutlineIcon style={{ color: "29abe2" }} />
                      </React.Fragment>
                    )}
                  </>
                ) : info.type.value === "Youtube" ? (
                  <TextField
                    id="outlined-name"
                    placeholder="Please paste the url of the video "
                    error={errorsForm.data.status}
                    helperText={
                      errorsForm.data.status &&
                      "please enter valid youtube link,don't leave the field empty"
                    }
                    name="data"
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </Grid>
              <Grid item>
                <TextField
                  id="outlined-name"
                  label="description"
                  name="description"
                  onChange={handleChange}
                  error={errorsForm.description.status}
                  helperText={
                    errorsForm.description.status &&
                    "invalid input,please enter a valid input with 128 characters,no space,"
                  }
                  fullWidth
                  placeholder="Enter the details"
                  multiline
                  rows={8}
                  rowsMax={10}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid container item>
                <Grid sm={3} item>
                  <FormControl id="prioritystyle">
                    <InputLabel
                      htmlFor="prioirty-simple"
                      shrink={info.priority === "" ? false : true}
                    >
                      Priority
                    </InputLabel>
                    <Select
                      value={info.priority.value}
                      onChange={handleChange}
                      inputProps={{
                        name: "priority",
                        id: "priority-simple"
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value={1}>Low</MenuItem>
                      <MenuItem value={2}>Medium</MenuItem>
                      <MenuItem value={3}>High</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid sm={2} item>
                  <Typography> Above</Typography>
                  <FormControl style={{ marginLeft: "10px" }}>
                    <InputLabel
                      htmlFor="above-simple"
                      shrink={info.above === "" ? false : true}
                    >
                      Age
                    </InputLabel>
                    <Select
                      value={info.above.value}
                      onChange={handleChange}
                      inputProps={{
                        name: "above",
                        id: "above-simple"
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      {getCounterList()}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid sm={2} item>
                  <Typography>Below</Typography>
                  <FormControl style={{ marginLeft: "10px" }}>
                    <InputLabel
                      htmlFor="below-simple"
                      shrink={info.below === "" ? false : true}
                    >
                      Age
                    </InputLabel>
                    <Select
                      value={info.below.value}
                      onChange={handleChange}
                      inputProps={{
                        name: "below",
                        id: "below-simple"
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      {getCounterList()}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={3}>
                  <FormControl style={dropDownStyle}>
                    <InputLabel
                      htmlFor="gender-simple"
                      shrink={info.gender === "" ? false : true}
                    >
                      Gender
                    </InputLabel>
                    <Select
                      value={info.gender.value}
                      onChange={handleChange}
                      inputProps={{
                        name: "gender",
                        id: "gender-simple"
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl
                    style={{ marginLeft: "10px", minWidth: "100px" }}
                  >
                    <InputLabel
                      htmlFor="when-simple"
                      shrink={info.proximity === "" ? false : true}
                    >
                      Proximity
                    </InputLabel>
                    <Select
                      value={info.proximity.value}
                      onChange={handleChange}
                      inputProps={{
                        name: "proximity",
                        id: "proximity-simple"
                      }}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="Immediate">Immediate</MenuItem>
                      <MenuItem value="Near">Near</MenuItem>
                      <MenuItem value="Far">Far</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {props.subLoc.navSublocationId && (
                  <Grid item sm={3}>
                    <FormControl
                      style={{ marginLeft: "10px", minWidth: "100px" }}
                    >
                      <InputLabel
                        htmlFor="when-simple"
                        shrink={info.when === "" ? false : true}
                      >
                        When
                      </InputLabel>
                      <Select
                        value={info.when.value}
                        onChange={handleChange}
                        inputProps={{
                          name: "when",
                          id: "when-simple"
                        }}
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="Immediate">On Enter</MenuItem>
                        <MenuItem value="Near">On Leave</MenuItem>
                        <MenuItem value="Far">On Stay</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Grid container>
                <Grid item sm={9}>
                  <Typography id="timetabletypography">Timetable</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell id="start">Start</TableCell>
                        <TableCell id="end">End</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {weekList.map((day, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              <Button
                                variant="contained"
                                color={!day.active ? "default" : "primary"}
                                onClick={() => handleDayClick(day)}
                                id="daybutton"
                              >
                                {day.day}
                              </Button>
                            </TableCell>
                            <TableCell align="right">
                              <form noValidate>
                                <TextField
                                  id="time"
                                  type="time"
                                  onChange={e => handleClockFromChange(e, day)}
                                  disabled={!weekList[index].active}
                                  defaultValue="00:00"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  inputProps={{
                                    step: 300 // 5 min
                                  }}
                                />
                              </form>
                            </TableCell>
                            <TableCell align="right">
                              <form noValidate>
                                <TextField
                                  id="time"
                                  type="time"
                                  onChange={e => handleClockToChange(e, day)}
                                  disabled={!weekList[index].active}
                                  defaultValue="23:59"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  inputProps={{
                                    step: 300 // 5 min
                                  }}
                                />
                              </form>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div style={sectionStyle}>
                {info.type.value && info.type.value === "Video" ? (
                  <img src={video} style={myStyle} alt="" />
                ) : info.type.value === "Url" ? (
                  <img src={url} style={myStyle} alt="" />
                ) : info.type.value === "Article" ? (
                  <img src={article} style={myStyle} alt="" />
                ) : info.type.value === "Image" ? (
                  <img src={imageUrl} style={myStyle} alt="" />
                ) : info.type.value === "Youtube" ? (
                  <img src={Youtube} style={myStyle} alt="" />
                ) : info.type.value === "Audio" ? (
                  <img src={audio} style={myStyle} alt="" />
                ) : (
                  <></>
                )}
                {/* <img src={video} style={myStyle} /> */}
              </div>

              <img src={proximityGraphic} style={myStyle_1} />
            </Grid>
          </Grid>
        </>
        {/* //////////////////////////////////////////////////////////////////////////////////////// */}

        <Dialog
          open={openCancel}
          onClose={openCancel}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Cancel Campaign</DialogTitle>
          <DialogContent>
            <DialogContentText>
              By clicking on cancel all your data will not be saved?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={denyCancellation} color="primary">
              no
            </Button>
            <Button
              onClick={confirmCancellation}
              variant="contained"
              color="primary"
              style={{ color: "white" }}
            >
              yes
            </Button>
          </DialogActions>
        </Dialog>

        {/* //////////////////////////////////////////////////////////////////////////////////////////// */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleClickAdd}
          style={{ color: "white" }}
        >
          {loading ? <CircularProgress color="white" /> : "Confirm"}
        </Button>
      </DialogActions>
    </div>
  );
}

AddCampaignDialogBox.propTypes = {
  loc: PropTypes.object.isRequired,
  updateCampaigns: PropTypes.func.isRequired,
  campaigns: PropTypes.array.isRequired
};

export default AddCampaignDialogBox;
