/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect, useContext } from "react";

import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from "@material-ui/core";
import {
  uploadTaskPromise,
  getCurrentCompanyKey,
  setSubLocationData,
  getCurrentUser
} from "../../../controllers/firebase";
import {
  ValidateLocationName,
  isFileImagePng
} from "../../../helpers/validators";
import { navigineAddSubLocation } from "../../../controllers/navigine_sublocation";
import { sublocationContext } from "../../../contexts/SublocationsContext";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

//import addsublocationimage from "../../assets/images/addsublocationimage.png";

/*
Declearing the functional component 
*/
let checkSubName = false;
let checkImage = true;
let checkUploaded = false;
let loading = false;
let timestamp;

function AddSubLocationDialogBox(props) {
  const [subLocName, setSubLocName] = useState("");
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [companyKey, setCompanyKey] = useState();
  const [particularSublocations, setParticularSublocations] = useState();
  const { sublocations, updateSubLocations } = useContext(sublocationContext);
  const [displayName, setDisplayName] = useState();
  const [fileName, setFileName] = useState("");
  const [fileNav, setFile] = useState();

  useEffect(() => {
    getCurrentUser(u => {
      setDisplayName(u.displayName);
      getCurrentCompanyKey().then(e => {
        setCompanyKey(e);
      });
    });
    timestamp = new Date().getTime();
    const array =
      sublocations &&
      sublocations.filter(sub => sub.locationId === props.locationId);
    setParticularSublocations(array);
  }, [sublocations, props.locationId]);

  const handleChange = e => {
    setSubLocName(e.target.value);
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = async function() {
      await setFile(reader.result);
    };
  };

  const handleAdd = async () => {
    loading = true;
    checkSubName = false;

    sublocations &&
      sublocations.forEach(sub => {
        if (
          sub.locationId === props.locationId &&
          sub.subLocationName.trim().toLowerCase() ===
            subLocName.trim().toLowerCase()
        ) {
          checkSubName = true;
        }
      });

    const navSubId = await navigineAddSubLocation(
      props.navLocationId,
      subLocName,
      fileNav,
      height,
      width
    );
    if (
      ValidateLocationName(subLocName) &&
      !checkSubName &&
      !checkImage &&
      navSubId
    ) {
      await setSubLocationData(
        subLocName,
        props.navLocationId,
        imageUrl,
        navSubId,
        companyKey,
        props.locationId,
        timestamp,
        displayName
      );
      props.update();

      props.closeAddDialog();
      checkImage = false;
      checkUploaded = false;
      loading = false;
    } else {
      loading = false;
      alert(
        "please upload the following :" +
          "\n" +
          "- valid picture (png type,5 MB size)" +
          "\n" +
          "-sublocation name should have only 16 letters,should be unique"
      );
    }
  };
  const handleImageChange = async e => {
    checkUploaded = false;
    if (e.target.files[0]) {
      if (
        e.target.files[0].size < 5485760 &&
        isFileImagePng(e.target.files[0])
      ) {
        loading = true;
        // const i = e.target.files[0];
        const img = new Image();
        setImage(e.target.files[0]);
        setFileName(e.target.files[0].name);
        // const type = "Image";
        const storageUrl = await uploadTaskPromise("Image", e.target.files[0]);
        img.src = storageUrl;
        img.onload = function() {
          setHeight(img.height);
          setWidth(img.width);
        };
        setImageUrl(storageUrl);
        checkImage = false;
        checkUploaded = true;
        loading = false;
        // let reader = new FileReader();
        // reader.readAsDataURL(image);
        // reader.onload = function() {
        //   console.log(reader.result);
        //   setFile(reader.result);
        //   console.log("file", fileNav);
        // };
      } else {
        checkImage = true;
        checkUploaded = false;
        alert("Invalid input : please upload a PNG type picture (size:5mb)  ");
      }
    }
  };
  const closeDialog = () => {
    checkUploaded = false;
    props.closeAddDialog();
  };
  // Add necessary comments in the return
  return (
    <div>
      <DialogTitle id="form-dialog-title">
        {" "}
        <Typography
          style={{
            fontSize: "20px",
            fontWeight: "bolder",

            fontFamily: "helvetica neue, sans-serif"
          }}
        >
          Add Sub-Location
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography
            style={{
              fontSize: "20px",
              fontWeight: "bolder",
              color: "#A3A3A3",
              fontFamily: "helvetica neue, sans-serif"
            }}
          >
            Add the sub-location information
          </Typography>
        </DialogContentText>

        <>
          <input
            accept="image/png"
            type="file"
            name="image"
            onChange={handleImageChange}
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            color="#29abe2"
          />
          <label htmlFor="raised-button-file">
            <Button variant="raised" component="span">
              Upload Image (Max size:5MB)
              <CloudUploadIcon style={{ color: "#29abe2" }} />
            </Button>
          </label>
          {checkUploaded && (
            <React.Fragment>
              <span style={{ marginLeft: "1%" }}> {fileName} is uploaded </span>
              <CheckCircleOutlineIcon style={{ color: "29abe2" }} />
            </React.Fragment>
          )}
        </>

        <TextField
          autoFocus
          margin="dense"
          label="Sub-location"
          type="name"
          name="newLoc"
          fullWidth
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleAdd}
          variant="contained"
          // onClick={handleClose}
          color="primary"
          style={{ color: "white" }}
        >
          Add
        </Button>
      </DialogActions>
      <Dialog
        open={loading}
        onClose={loading}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            Please Wait <CircularProgress color="blue" size="16px" />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

/*
Validating React Component Props
*/
AddSubLocationDialogBox.propTypes = {};

export default AddSubLocationDialogBox;
