/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: Venue.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Abhishek Kudikala (abhishek@snowm.io)
 *
 *  Date created: 21-June-2019
 *
 *
 *********************************************************************************
 */

import axios from "axios";

const userHash = "2F2C-F7C7-C494-A1AA";
const endpoint = "https://node-api.navigine.com/";

/*
 ****************************************************************************
 * @brief  (snowmAddVenue)      this function adds a Venue in a Sublocation
 *
 * @see link of the function if applicable
 *
 * @param{type: string, array} (Subid, name,id,description,phone, kx , ky) - takes sublocation Id and coordinate points as inputs
 *
 * @return {type: array}- returns response array which has details of venue such as id, etc.
 *
 ****************************************************************************
 */

export async function snowmVersion(locid) {
  const res = await axios.post(
    `${endpoint}versions/save?userHash=${userHash}&locationId=${locid}`
  );
  console.log(res);
}
