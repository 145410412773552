/* eslint-disable import/imports-first */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect, useContext } from "react";
import "./Location.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

import {
  Button,
  Paper,
  Typography,
  TextField,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";

import {
  getCurrentUser,
  getCurrentCompanyKey,
  getLocationData
} from "../../../controllers/firebase";

import {
  ValidateLocationName,
  ValidateDropDown
} from "../../../helpers/validators";
import { LocationContext } from "../../../contexts/LocationsContext";
let check = false;
let addressCheck = false;
let maxLocationBased = 0;
let maxSubLocation = 0;
let maxIndoorNavigation = 0;
let maxLocations = false;
let timestamp;

/*
Declearing the functional component 
*/
function AddLocation(props) {
  // state that holds the address
  const [address, setAddress] = useState();
  // state that holds the address suggestions
  const [suggestions, setsuggestions] = useState([]);
  // state that holds lng and lat
  const [coordinates, setCoordinates] = useState();
  // variable to store location values fetched from the firebase
  const [locations, setLocations] = useState(null);
  // variable to store location name
  const [locName, setLocName] = useState("");
  // variable to store company key fetched from firebase
  const [companyKey, setCompanyKey] = useState();
  // variable to store the userid fetched from firebase
  const [uid, setUid] = useState();
  const [type, setType] = useState("");
  const { subType, addLocation, locationss } = useContext(LocationContext);
  // error validations
  const [errors, setErrors] = useState({
    address: null,
    invalidAddress: null
  });

  const [errorsForm, setErrorsForm] = useState({
    locName: {
      status: false,
      message: "16 chars , dont leave it empty"
    }
  });

  // variable to show loading state
  const [loading, setLoading] = useState(false);
  const handleSuggestions = sug => {
    setsuggestions(sug);
  };
  const handleChangeLocationName = e => {
    if (ValidateLocationName(e.target.value)) {
      setLocName(e.target.value);
      setErrorsForm({
        ...errors,
        locName: { status: false, message: "16 chars , dont leave it empty" }
      });
    } else {
      setErrorsForm({
        ...errors,
        locName: { status: true, message: "2-16 chars , dont leave it empty" }
      });
    }
    setLocName(e.target.value);
  };
  const handleChange = add => {
    setAddress(add);
    geocodeByAddress(add)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        setCoordinates(latLng);
      })
      .catch(error => {
        console.error("Error", error);
      });
  };

  // method to intialize all the variables which are fecthed from firebase
  useEffect(() => {
    if (
      subType.locationBased === true &&
      subType.sublocationBased === false &&
      subType.indoorNavigation === false
    ) {
      setType("locationBased");
    }
    if (
      subType.locationBased === false &&
      subType.sublocationBased === true &&
      subType.indoorNavigation === false
    ) {
      setType("sublocationBased");
    }
    if (
      subType.locationBased === false &&
      subType.sublocationBased === false &&
      subType.indoorNavigation === true
    ) {
      setType("indoorNavigation");
    }
    getCurrentUser(u => {
      if (u) setUid(u.uid);
      getCurrentCompanyKey().then(e => {
        setCompanyKey(e);
        getLocData(e);
      });
    });
  }, [
    props.updateLocation,
    subType.indoorNavigation,
    subType.locationBased,
    subType.sublocationBased
  ]);
  // getting location data from firebase
  const getLocData = cKey => {
    getLocationData(cKey).then(array => {
      setLocations(array);
    });
  };

  const handleType = e => {
    setType(e.target.value);
  };

  const handleAddClick = async () => {
    check = false;
    maxLocationBased = 0;
    maxSubLocation = 0;
    maxIndoorNavigation = 0;
    timestamp = Math.floor(new Date().getTime() / 1000);
    maxLocations = false;
    setLoading(true);

    locationss &&
      locationss.forEach(location => {
        if (
          location.locationName.trim().toLowerCase() ===
          locName.trim().toLowerCase()
        ) {
          check = true;
        }
      });
    suggestions.forEach(sug => {
      if (
        sug.description.trim().toLowerCase() === address.trim().toLowerCase()
      ) {
        addressCheck = true;
      }
    });

    locationss &&
      locationss.forEach(location => {
        if (location.selectedType === "locationBased") {
          maxLocationBased = maxLocationBased + 1;
        } else if (location.selectedType === "sublocationBased") {
          maxSubLocation = maxSubLocation + 1;
        } else if (location.selectedType === "indoorNavigation") {
          maxIndoorNavigation = maxIndoorNavigation + 1;
        }
      });

    if (maxLocationBased === 16 && type === "locationBased") {
      maxLocations = true;
    } else if (maxSubLocation === 16 && type === "sublocationBased") {
      maxLocations = true;
    } else if (maxIndoorNavigation === 16 && type === "indoorNavigation") {
      maxLocations = true;
    }

    if (
      !check &&
      addressCheck &&
      locName &&
      address &&
      !errorsForm.locName.status &&
      ValidateDropDown(type)
    ) {
      if (maxLocations) {
        alert("you reached the maxmium number of locations of this service");
      } else {
        await addLocation(
          locName,
          address,
          coordinates.lat,
          coordinates.lng,
          type,
          timestamp
        );
        setLocName("");
        setAddress("");
        setType("");
        addressCheck = false;
      }
    } else {
      alert(
        "- Please fill all the input fields" +
          "\n" +
          "- Location name should be unique and has a max of 16 chars and min of 2 chars" +
          "\n" +
          "- Address should be selected from the suggested list"
      );
    }

    setLoading(false);
  };

  // Add necessary comments in the return
  return (
    <Paper elevation="5" style={{ margin: 15 }}>
      <Typography id="location">
        <span id="addlocation">Add Location</span>
        <span id="addlocationdescription">
          {" "}
          Enter location name and address
        </span>
      </Typography>
      <div style={{ margin: 10 }}>
        <TextField
          id="outlined-name"
          label="Location Name "
          value={locName}
          error={errorsForm.locName.status}
          helperText={errorsForm.locName.status && errorsForm.locName.message}
          name="name"
          style={{ flex: 1, width: "100%" }}
          variant="outlined"
          onChange={handleChangeLocationName}
        />
      </div>

      <div style={{ padding: 10 }}>
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onClick={handleChange}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, load }) => (
            <React.Fragment>
              <TextField
                id="outlined-name"
                label="Address"
                //error={errors.address}
                value={address}
                //helperText={errors.address}
                name="name"
                style={{ width: "100%" }}
                variant="outlined"
                {...getInputProps({
                  placeholder: "Please select from the suggested list...",
                  className: "location-search-input"
                })}
              />
              <div className="autocomplete-dropdown-container">
                {load && <div>Loading...</div>}

                {suggestions.map((suggestion, index) => {
                  handleSuggestions(suggestions);

                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? {
                        backgroundColor: "#fafafa",
                        cursor: "pointer"
                      }
                    : {
                        backgroundColor: "#ffffff",
                        cursor: "pointer"
                      };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                      key={index}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}
        </PlacesAutocomplete>
      </div>

      {(subType.locationBased === true &&
        subType.sublocationBased === false &&
        subType.indoorNavigation === false) ||
      (subType.locationBased === false &&
        subType.sublocationBased === true &&
        subType.indoorNavigation === false) ||
      (subType.locationBased === false &&
        subType.sublocationBased === false &&
        subType.indoorNavigation === true) ? (
        <React.Fragment></React.Fragment>
      ) : (
        <FormGroup row id="subscriptioncheckboxes">
          <Typography id="subscriptiontypetext">
            Please select your subscription type :
          </Typography>
          {subType.locationBased === true && (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!(subType && subType.locationBased)}
                  checked={type === "locationBased"}
                  onChange={handleType}
                  value="locationBased"
                  color="primary"
                />
              }
              label={
                <Typography class="checkboxtype">Location based</Typography>
              }
            />
          )}

          {subType.sublocationBased === true && (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!(subType && subType.sublocationBased)}
                  checked={type === "sublocationBased"}
                  onChange={handleType}
                  value="sublocationBased"
                  color="primary"
                />
              }
              label={<Typography class="checkboxtype">Sub Location</Typography>}
            />
          )}

          {subType.indoorNavigation === true && (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!(subType && subType.indoorNavigation)}
                  checked={type === "indoorNavigation"}
                  onChange={handleType}
                  value="indoorNavigation"
                  color="primary"
                />
              }
              label={
                <Typography class="checkboxtype">Indoor Navigation</Typography>
              }
            />
          )}
        </FormGroup>
      )}

      <Button
        variant="contained"
        color="primary"
        id="addlocationbutton"
        onClick={handleAddClick}
      >
        {loading ? <CircularProgress color="white" /> : "Add location"}
      </Button>
    </Paper>
  );
}

/*
Validating React Component Props
*/
AddLocation.propTypes = {};

export default AddLocation;
