import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Styled from "styled-components";
import { Route, Redirect } from "react-router-dom";
import { auth } from "firebase";
import Loader from "../Components/Generics/loader";

// alternative = (string) route to be redirected to if the authentication go as expected
// shouldFail = (bool) to represent if failed authentication is expected

function RenderProps({ authenticated, shouldFail, altPath, Component, props }) {
  const [width, setWidth] = useState(1280);
  useEffect(() => {
    // window.addEventListener('resize', () => {
    //   setWidth(window.innerWidth);
    // });
    setWidth(window.innerWidth);
  }, []);

  if (authenticated === null) {
    return <Loader />;
  }
  if ((authenticated && !shouldFail) || (!authenticated && shouldFail)) {
    if (authenticated) {
      return (
        <AppContainer width={width}>
          <Component {...props} />
        </AppContainer>
      );
    }
    return <Component {...props} />;
  }
  return <Redirect to={altPath} />;
}

export default function AuthenticatedRoute({
  shouldFail,
  altPath,
  component: Component,
  ...rest
}) {
  const [authenticated, setAuthenticated] = useState(null);
  auth().onAuthStateChanged(user => {
    if (user) {
      setAuthenticated(true);
    } else setAuthenticated(false);
  });
  return (
    <Route
      {...rest}
      render={props => (
        <RenderProps
          authenticated={authenticated}
          shouldFail={shouldFail}
          altPath={altPath}
          Component={Component}
          props={props}
        />
      )}
    />
  );
}
AuthenticatedRoute.propTypes = {
  shouldFail: PropTypes.bool.isRequired,
  altPath: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired
};

export const PrivateRoute = props => (
  <AuthenticatedRoute altPath="/login" shouldFail={false} {...props} />
);
export const LoginRoute = props => (
  <AuthenticatedRoute altPath="/home" shouldFail {...props} />
);

const AppContainer = Styled.div`
  transform:${props =>
    /Mobi/.test(navigator.userAgent) && props.width < 1280
      ? `scale(${props.width / 1200 <= 0.15 ? 0.15 : props.width / 1200})`
      : "scale(1)"};

  height: ${props =>
    /Mobi/.test(navigator.userAgent) && props.width < 1280
      ? `${(1280 - props.width) * 100}vh`
      : "100vh"}
  transform-origin:0 0;
  min-width: 1200px;
  display: flex;
  flex-flow: column;
`;
