/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import {
  getCurrentUser, // get current user from firebase
  getCurrentCompanyKey, // get current company key of signed-in user
  getBeacons
} from "../../../controllers/firebase";

import "./Beacon.css";

/*
Declearing the functional component 
*/

function BatteryMonitoring(props) {
  const [beacons, setBeacons] = useState();

  useEffect(() => {
    // method to intialize all the variables which are fecthed from firebase
    getCurrentUser(u => {
      if (u)
        getCurrentCompanyKey(u.uid).then(e => {
          getBeacon(e);
        });
    });
  }, []);
  const getBeacon = key => {
    getBeacons(key).then(array => {
      setBeacons(array);
    });
  };

  // Add necessary comments in the return
  return (
    <Grid style={{ marginBottom: "15px" }}>
      <Paper elevation="5" style={{ paddingBottom: "10px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} id="beaconsettingstitle">
            BATTERY MOINTERING
          </Grid>

          <Grid item xs={6} id="gridstyle">
            <Typography>
              <span id="field">Field Battery Percentage:</span>
              <span id="deviceinfo">78%</span>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <span id="field">Remaining Lifetime: </span>
              <span id="sublocationinfo">4 weeks</span>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

/*
Validating React Component Props
*/
BatteryMonitoring.propTypes = {};

export default BatteryMonitoring;
