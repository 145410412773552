/* eslint-disable import/no-unresolved */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

import beaconDashbordImage from "./beaconDashbordImage.png";
import {
  getCurrentUser, // get current user from firebase
  getCurrentCompanyKey, // get current company key of signed-in user
  getBeacons
} from "../../../controllers/firebase";
import "./Dashboard.css";

const myStyle = {
  width: "145px",
  height: "180px"
};

/*
 ****************************************************************************
 * @brief  (fucntion name)      brief description of the fucntion
 *
 * @see link of the function if applicable
 *
 * @param{type : stringn,array .. etc} (paramter name)   parameter definition
 *
 * @return  what does the function return
 *
 ****************************************************************************
 */

/*
Declearing the functional component 
*/
function BeaconsDisplay(props) {
  // here you store the number of beacons that the custmer have
  const [beacons, setBeacons] = useState();

  useEffect(() => {
    // method to intialize all the variables which are fecthed from firebase
    getCurrentUser(u => {
      if (u)
        getCurrentCompanyKey().then(e => {
          getBeacon(e);
        });
    });
  }, []);

  const getBeacon = key => {
    getBeacons(key).then(array => {
      setBeacons(array.length);
    });
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={5} style={{ height: "165px" }}>
        <img src={beaconDashbordImage} alt="noimage" style={myStyle} />
      </Grid>
      <Grid item xs={12} sm={6} style={{ height: "165px" }}>
        <Typography id="beaconstypographystyle">Beacons</Typography>
        <Typography id="beaconsnumbertypographystyle">{beacons}</Typography>
        <Typography
          id="seemoreinfostyle"
          onClick={() => {
            props.history.push("/home/beaconredesgined");
          }}
        >
          See More Info >
        </Typography>
      </Grid>
    </Grid>
  );
}

/*
Validating React Component Props
*/
BeaconsDisplay.propTypes = {
  history: PropTypes.object.isRequired
};

export default BeaconsDisplay;
