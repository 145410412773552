/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: Beacons.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Abhishek Kudikala (abhishek@snowm.io)
 *
 *  Date created: 21-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import axios from "axios";

const userHash = "2F2C-F7C7-C494-A1AA";
const url = {
  getAllBeacons: "https://node-api.navigine.com/beacon/getAll?",
  beaconDetail: "https://node-api.navigine.com/beacon/get?",
  addBeacon: "https://node-api.navigine.com/beacon/add?",
  editBeacon: "https://node-api.navigine.com/beacon/edit?",
  deleteBeacon: "https://node-api.navigine.com/beacon/delete?"
};

/*
 ****************************************************************************
 * @brief  (snowmGetAllBeacons)      this function gets all beacons assigned in a Sublocation
 *
 * @see link of the function if applicable
 *
 * @param{type : string} (sub_id)   takes the  location ID
 *
 * @return
 *
 ****************************************************************************
 */

export async function snowmGetAllBeacons(subid) {
  const SubLocationId = subid;
  const data = [];
  await axios
    .get(
      `${url.getAllBeacons}userHash=${userHash}&sublocationId=${SubLocationId}`
    )
    .then(res => {
      console.log(res);
      if (res.statusText === "OK") {
        data.push(res.data);
      }
    })
    .catch(e => {
      console.log("from error biacth", e);
    });
  return data;
}

/*
 ****************************************************************************
 * @brief  (snowmGetBeaconInfo)      this function gets all beacons assigned in a Sublocation
 *
 * @see link of the function if applicable
 *
 * @param{type : string} (sub_id)   takes the  location ID
 *
 * @return
 *
 ****************************************************************************
 */

export async function snowmGetBeaconInfo(beaconid) {
  const BeaconId = beaconid;
  const data = [];
  await axios
    .get(`${url[1]}userHash=${userHash}&beaconId=${BeaconId}`)
    .then(res => {
      if (res.data.response === "ok") {
        data.push(res.data);
      }
    });
  return data;
}

/*
 ****************************************************************************
 * @brief  (snowmAddBeacon)      this function gets all beacons assigned in a Sublocation
 *
 * @see link of the function if applicable
 *
 * @param{type : string} (sub_id)   takes the  location ID
 *
 * @return
 *
 ****************************************************************************
 */

export function snowmAddBeacon(subid, beaconname, uuid, major, minor, x, y) {
  const formdata = new FormData();
  formdata.append("id", "");
  formdata.append("sublocationId", subid);
  formdata.append("description", beaconname);
  formdata.append("uuid", uuid);
  formdata.append("major", major);
  formdata.append("minor", minor);
  formdata.append("kx", x);
  formdata.append("ky", y);
  formdata.append("pw", -2);
  formdata.append("isUsed", 0);
  formdata.append("powerNav", 1);

  return axios({
    url: `${url.addBeacon}userHash=${userHash}`,
    method: "POST",
    data: formdata
  }).then(res => {
    if (res.statusText === "OK") {
      return res.data;
    } else {
      return null
    }
  });
}

/*
 ****************************************************************************
 * @brief  (snowmEditBeacon)      this function gets all beacons assigned in a Sublocation
 *
 * @see link of the function if applicable
 *
 * @param{type : string} (sub_id)   takes the  location ID
 *
 * @return
 *
 ****************************************************************************
 */

export async function snowmEditBeacon(
  beaconid,
  beaconName,
  uuid,
  major,
  minor,
  x,
  y
) {
  const Description = beaconName;
  const UUID = uuid;
  const Major = major;
  const Minor = minor;
  const kx = x;
  const ky = y;
  const responseData = [];
  const formdata = new FormData();
  formdata.append("description", Description);
  formdata.append("id", beaconid);
  formdata.append("uuid", UUID);
  formdata.append("major", Major);
  formdata.append("minor", Minor);
  formdata.append("kx", kx);
  formdata.append("ky", ky);
  formdata.append("pw", -2);
  formdata.append("isUsed", 0);
  await axios({
    url: `${url.editBeacon}userHash=${userHash}`,
    method: "POST",
    data: formdata
  }).then(res => {
    if (res.data.response === "ok") {
      responseData.push(res.data);
    }
  });
  return responseData;
}

/*
 ****************************************************************************
 * @brief  (snowmDeconsteBeacon)      this function gets all beacons assigned in a Sublocation
 *
 * @see link of the function if applicable
 *
 * @param{type : string} (sub_id)   takes the  location ID
 *
 * @return
 *
 ****************************************************************************
 */

export async function snowmDeleteBeacon(beaconid) {
  const id = beaconid;
  let msg = "";
  await axios
    .post(`${url.deleteBeacon}userHash=${userHash}`, { id })
    .then(res => {
      if (res.data.response === "ok") {
        msg = res.data.result;
      }
    });
  return msg;
}
