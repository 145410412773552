/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_drawer.jsx
 *
 *  Description: Drawer component.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";
import {
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  Divider
} from "@material-ui/core";
import PropTypes from "prop-types";
import Styled from "styled-components";
import CollapseIcon from "mdi-react/ChevronRightIcon";
import ExpandIcon from "mdi-react/ChevronDownIcon";

// functional component
/*
 ****************************************************************************
 * @brief  (DrawerView)  Renders the contents of drawer
 * @param{type : Array,string,object } ( routes,active,props)
 *
 * @returns content of the drawer (react element)
 ****************************************************************************
 */
function DrawerView({ routes, active, ...props }) {
  const [expanded, setExpanded] = useState(active);
  const [activeLocal, setActive] = useState(active);
  useEffect(() => {
    setActive(active);
    setExpanded(active);
  }, [active]);

  /*
   **************************************************************************************
   * @brief  (SideItems)  renders the items inside the drawer
   * @param{type : Array } (routesToRender)
   *
   * @returns Appropriate React element including all the routes listed in routesToRender
   **************************************************************************************
   */
  function SideItems({ routes: routesToRender }) {
    return (
      <List>
        {routesToRender.map(({ route, name, icon, children, menu }, index) => {
          const routeAbs = `${route}`;
          const expand = expanded.includes(route, 0);
          if (menu) {
            return null;
          }
          return (
            <div style={{ padding: 0 }} key={index}>
              <StyledListItem
                button
                key={name}
                active={activeLocal === route}
                onClick={() => {
                  setActive(route);
                  if (children) {
                    if (!expand) {
                      setExpanded(route);
                    } else {
                      setExpanded("*");
                    }
                  }
                  props.history.push(routeAbs);
                }}
                {...props}
              >
                <PaddedListItemIcon size="large">{icon}</PaddedListItemIcon>
                <StyledContainer active={activeLocal === route}>
                  <StyledListItemText
                    className="waves-effect"
                    to={routeAbs}
                    active={activeLocal === route}
                  >
                    {name.toUpperCase()}
                  </StyledListItemText>
                  {children ? (
                    <ListItemIcon
                      style={{ margin: "auto" }}
                      active={activeLocal === route}
                    >
                      {expand ? <CollapseIcon /> : <ExpandIcon />}
                    </ListItemIcon>
                  ) : (
                    <div />
                  )}
                </StyledContainer>
                <Divider />
              </StyledListItem>
              {children && expand ? <SideItems routes={children} /> : null}
            </div>
          );
        })}
      </List>
    );
  }

  // returns the Content of SideItems inside of sidebar
  return (
    <div>
      <SideBar {...props}>
        <SideItems routes={routes} />
      </SideBar>
    </div>
  );
}

// proptype validation
DrawerView.defaultProps = {
  setTitle: () => {},
  toggle: () => {}
};
DrawerView.propTypes = {
  drawer: PropTypes.bool.isRequired,
  theme: PropTypes.shape.isRequired,
  setTitle: PropTypes.func,
  toggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.string).isRequired,
  active: PropTypes.string.isRequired
};

export default DrawerView;

const StyledListItem = Styled(ListItem)`
  &&{
    margin:15px, 0px ;
    padding:0px;
    padding-left:20px;
    background:${props =>
      props.active
        ? props.theme.palette.primary.light
        : "transparent"} !important;
    span, div{
      color:white;
    };
    &:hover{
      background:${props => props.theme.palette.primary.light};
    }
  }
`;

const PaddedListItemIcon = Styled(ListItemIcon)`
    padding:10px;
    &&{
    min-width:20px;
    margin:0px;
    }
`;

const StyledListItemText = Styled(ListItemText)`
&&{
  span{
  font-size:18px;
  }
  padding-left:0px;
  width: 100%;
}
`;

const SideBar = Styled.div`
  background-color:#29abe2;
  flex-grow:1;
  height:100%;
 width:${props => `${props.width}px`};
  `;

const StyledContainer = Styled.div`
   width: 100%;
    display:flex;
    flex-direction:row;
    padding:15px;
    padding-left:0px;
   
    
`;
