import React, { createContext, useState, useEffect } from "react";
import {
  getCurrentCompanyKey,
  getSubLocationData,
  getCurrentUser
} from "../controllers/firebase";
export const sublocationContext = createContext();

export function SublocationsContextProvider(props) {
  const [sublocations, setSublocations] = useState();
  const [companyKey, setCompanyKey] = useState();
  useEffect(() => {
    getCurrentUser(u => {
      if (u !== null) {
        getCurrentCompanyKey(u.uid).then(e => {
          getSubData(e);

          setCompanyKey(e);
        });
      }
    });
  }, []);

  const getSubData = async companyKey => {
    const data = await getSubLocationData(companyKey);
    setSublocations(data);
  };
  const updateSubLocations = () => {
    getSubData(companyKey);
  };
  return (
    <sublocationContext.Provider value={{ sublocations, updateSubLocations }}>
      {props.children}
    </sublocationContext.Provider>
  );
}
