/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import {
  getCurrentUser, // get current user from firebase
  getCurrentCompanyKey, // get current company key of signed-in user
  getBeacons
} from "../../../controllers/firebase";
import "./Beacon.css";
import DefaultProperties from "./DefaultProperties";
import TelemetryPackets from "./TelemetryPackets";
import BatteryMonitoring from "./BatteryMonitoring";

function BeaconSettings(props) {
  const [beacons, setBeacons] = useState();

  useEffect(() => {
    // method to intialize all the variables which are fecthed from firebase
    getCurrentUser(u => {
      if (u)
        getCurrentCompanyKey(u.uid).then(e => {
          getBeacon(e);
        });
    });
  }, []);
  const getBeacon = key => {
    getBeacons(key).then(array => {
      setBeacons(array);
    });
  };

  // Add necessary comments in the return
  return (
    <div style={{ minWidth: "500px" }}>
      <Grid>
        <Typography
          style={{
            fontSize: "25px",
            fontFamily: "helvetica neue, sans-serif",
            fontWeight: "bolder"
          }}
        >
          BEACONS
        </Typography>
        {/* <EditBeaconDialog /> */}
        <DefaultProperties beaconData={props.beaconData} />
        <TelemetryPackets beaconData={props.beaconData} />
        {/* <Ibeacon beaconData={props.beaconData} />
        <Eddystone beaconData={props.beaconData} />
        <TlmEddyStone beaconData={props.beaconData} />
        <UrlEddyStone beaconData={props.beaconData} /> */}
        <BatteryMonitoring />
      </Grid>
    </div>
  );
}

/*
Validating React Component Props
*/
BeaconSettings.propTypes = {};

export default BeaconSettings;
