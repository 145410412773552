/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: App/index.js
 *
 *  Description: Entry point of the Application.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React, { Component } from "react";
import { Switch, Redirect, Router } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core";
import createBrowserHistory from "history/createBrowserHistory";

import Dashboard from "../Private";
import Login from "../Public/Login";
import { LoginRoute, PrivateRoute } from "../../HOC/snowm_authenticator";
import { initFirebase } from "../../controllers/firebase";
import theme from "../../styles/theme";

const history = createBrowserHistory();

class App extends Component {
  constructor() {
    initFirebase();
    super();
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router history={history}>
          <Switch>
            <LoginRoute path="/login" component={Login} />
            <PrivateRoute path="/home" component={Dashboard} />
            <Redirect to="/home" />
          </Switch>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
