/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect, useContext } from "react";
import { Paper, Typography } from "@material-ui/core";
import {
  getCurrentUser, // get current user from firebase
  getCurrentCompanyKey, // get current company key of signed-in user
  getBeacons
} from "../../../controllers/firebase";
import settingImage from "../../../assets/images/setting.png";
import { LocationContext } from "../../../contexts/LocationsContext";
import { sublocationContext } from "../../../contexts/SublocationsContext";

import BeaconSettings from "./BeaconSettings";
import { Row, Col } from "../../../styles/styled";
import { lightGrey } from "../../../styles/theme";
import styled from "styled-components";

/*
Declearing the functional component 
*/

//let check = true;
function BeaconsRedesigined(props) {
  const { locationss, subType } = useContext(LocationContext);
  const { sublocations } = useContext(sublocationContext);
  const [beacons, setBeacons] = useState();

  const [data, setDate] = useState();
  const [check, setCheck] = useState(true);

  useEffect(() => {
    // method to intialize all the variables which are fecthed from firebase
    getCurrentUser(u => {
      if (u)
        getCurrentCompanyKey(u.uid).then(e => {
          getBeacon(e);
          //getBeaconsEvent(e);
        });
    });
  }, []);
  const getBeacon = key => {
    getBeacons(key).then(array => {
      setBeacons(array);
    });
  };

  const handleClick = beacon => {
    setDate(beacon);
    setCheck(false);
  };

  // Add necessary comments in the return
  return (
    <div style={{ minWidth: "500px" }}>
      {check ? (
        <div>
          <Typography
            style={{
              margin: "10px",
              fontSize: "25px",
              fontFamily: "helvetica neue, sans-serif",
              fontWeight: "bolder"
            }}
          >
            BEACONS
          </Typography>

          {beacons &&
            beacons.map((beacon, index) => (
              <Paper style={{ margin: 10 }}>
                <Row
                  style={{
                    background: lightGrey
                  }}
                >
                  <div id="beaconname">{beacon.name}</div>
                  <div
                    id="statusstyle"
                    style={{
                      background: "white",
                      color:
                        new Date().getTime() - beacon.lastSeen < 10000
                          ? "green"
                          : "orange",
                      textAlign: "center",
                      borderRadius: 10
                    }}
                  >
                    {new Date().getTime() - beacon.lastSeen < 10000
                      ? "Online"
                      : "Offline"}
                  </div>
                </Row>
                <Row>
                  <Col id="infostyle">
                    <Typography>
                      <span id="field">Device ID:</span>
                      <span id="deviceinfo">{beacon.uuid}</span>
                    </Typography>
                    <Typography>
                      <span id="field">Protcols:</span>{" "}
                      <span id="protcolsinfo"> SNOWM TELEMETRY </span>
                    </Typography>
                    <Typography>
                      <span id="field">Location:</span>{" "}
                      {locationss &&
                        locationss.map((location, index) => {
                          return (
                            <React.Fragment key={index}>
                              {location.id === beacon.id && (
                                <span id="locationinfo">
                                  {location.locationName}
                                </span>
                              )}
                            </React.Fragment>
                          );
                        })}
                    </Typography>
                    {(subType.sublocationBased === true ||
                      subType.indoorNavigation === true) && (
                      <Typography>
                        <span id="field">Sublocation: </span>

                        {sublocations &&
                          sublocations.map((sublocation, index) => {
                            return (
                              <React.Fragment key={index}>
                                {sublocation.navSublocationId ===
                                  beacon.navSublocationId && (
                                  <span id="sublocationinfo">
                                    {sublocation.subLocationName}
                                  </span>
                                )}
                              </React.Fragment>
                            );
                          })}
                      </Typography>
                    )}
                  </Col>
                  <img
                    style={{
                      margin: 10,
                      height: 50,
                      width: 50
                    }}
                    src={settingImage}
                    alt="settings icon"
                    id="settingstyle"
                    onClick={() => handleClick(beacon)}
                  />
                </Row>
              </Paper>
            ))}
        </div>
      ) : (
        <BeaconSettings beaconData={data} />
      )}
    </div>
  );
}

/*
Validating React Component Props
*/

export default BeaconsRedesigined;
