/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { LocationContext } from "../../../contexts/LocationsContext";
import { sublocationContext } from "../../../contexts/SublocationsContext";

import locationDashbordImage from "../../../assets/images/locationDashboardImage.png";
import {
  getCurrentUser, // get current user from firebase
  getCurrentCompanyKey, // get current company key of signed-in user
  getLocationData // get location data of user
} from "../../../controllers/firebase";
import "./Dashboard.css";

const myStyle = {
  width: "145px",
  height: "180px"
};

/*
Declearing the functional component 
*/
function LocationsDisplay(props) {
  // variables to store location values fetched from firebase
  const [locations, setLocations] = useState();
  // variable to store company key fetched from firebase
  const [companyKey, setCompanyKey] = useState();
  // variable to store the userid fetched from firebase
  const [uid, setUid] = useState();
  const { subType } = useContext(LocationContext);
  const { sublocations } = useContext(sublocationContext);

  useEffect(() => {
    // method to intialize all the variables which are fecthed from firebase
    getCurrentUser(u => {
      if (u) {
        setUid(u.uid);
        getCurrentCompanyKey().then(e => {
          setCompanyKey(e);
          getLocData(e);
        });
      }
    });
  }, []);
  // getting location data from firebase
  const getLocData = cKey => {
    getLocationData(cKey).then(array => {
      setLocations(array.length);
    });
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={5} style={{ height: "165px" }}>
        <img src={locationDashbordImage} alt="noimage" style={myStyle} />
      </Grid>
      <Grid item xs={12} sm={6} style={{ height: "165px" }}>
        {subType.sublocationBased || subType.indoorNavigation ? (
          <React.Fragment>
            {" "}
            <Typography id="locationstypographystyleofsublocation">
              Locations/Sublocations
            </Typography>
            <Typography id="locationsnumbertypographystyleofsublocation">
              {locations} / {sublocations.length}
            </Typography>{" "}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography id="locationstypographystyle">Locations</Typography>
            <Typography id="locationsnumbertypographystyle">
              {locations}
            </Typography>{" "}
          </React.Fragment>
        )}

        <Typography
          id="locationseemoreinfostyle"
          onClick={() => {
            props.history.push("/home/locations");
          }}
        >
          See More Info >
        </Typography>
      </Grid>
    </Grid>
  );
}

/*
Validating React Component Props
*/
LocationsDisplay.propTypes = {
  history: PropTypes.object.isRequired
};

export default LocationsDisplay;
