import React from "react";
import { Card } from "@material-ui/core";

export default function BeaconItem({ beacon, onSelectBeacon }) {
  return (
    <Card
      style={{ margin: 8, padding: 8, cursor: "pointer" }}
      onClick={onSelectBeacon}
    >
      <p key={beacon.uuid}>{beacon.name}</p>
    </Card>
  );
}
