import { createMuiTheme } from "@material-ui/core/styles";
import { pink, red } from "@material-ui/core/colors";

export const primaryColor = "#29abe2";
export const primaryLight = "#54bce8";
export const primaryDark = "#173964";
export const lightGrey = "#bfe6f7";
export default createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
      light: primaryLight
    },

    secondary: pink,
    error: red,
    tempColor: {
      main: "#333333"
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  typography: {
    // //example
    // headline: {
    //   fontSize: "1.5rem",
    //   fontWeight: 400,
    //   fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
    //   lineHeight: "1.35417em",
    //   color: "rgba(0, 0, 0, 0.87)"
    // }
    subheading: {
      fontSize: 18,
      fontWeight: 500
    }
  }
});
