import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import ReactPanZoom from "@ajainarayanan/react-pan-zoom";

import { decodeHash } from "../../../../controllers/map_helper";

export default function IndoorMap({
  hash,
  image,
  beaconPoints,
  onTapHandler,
  crews
}) {
  console.log(image);
  // const [zoom, setZoom] = useState(1);

  const CANVAS_HEIGHT = 600;
  const CANVAS_WIDTH = 1000;

  const canvasStyle = {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "1000px 600px",
    border: "1.5px solid black",
    cursor: "pointer",
    overflow: "hidden"
  };

  const canvasRef = React.useRef(null);

  function drawPolyline(ctx) {
    ctx.beginPath();

    if (hash) {
      const data = decodeHash(hash);

      ctx.strokeStyle = "red";
      ctx.lineWidth = 2;

      ctx.moveTo(CANVAS_WIDTH * data[0].kx, CANVAS_HEIGHT * (1 - data[0].ky));
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < data.length; i++) {
        ctx.lineTo(CANVAS_WIDTH * data[i].kx, CANVAS_HEIGHT * (1 - data[i].ky));
        ctx.stroke();
      }
    }
  }

  function drawBeaconPoint(ctx) {
    ctx.beginPath();
    beaconPoints.forEach(point => {
      ctx.moveTo(CANVAS_WIDTH * point.kx, CANVAS_HEIGHT * (1 - point.ky));
      ctx.arc(
        CANVAS_WIDTH * point.kx,
        CANVAS_HEIGHT * (1 - point.ky),
        6.5,
        0,
        2 * Math.PI
      );
    });
    ctx.fillStyle = "#29abe2";
    ctx.fill();
  }

  const drawCrews = ctx => {
    // ctx.beginPath();
    Object.values(crews).forEach(crew => {
      const img = new Image();
      img.src =
        crew.imageUrl ||
        "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/user_male2-512.png";

      img.addEventListener("load", () => {
        const photoHeight = img.naturalHeight;
        const photoWidth = img.naturalWidth;

        ctx.drawImage(
          img,
          0.5 * photoWidth,
          0.5 * photoHeight,
          900,
          900,
          CANVAS_WIDTH * crew.x - 10,
          CANVAS_HEIGHT * (1 - crew.y) - 10,
          25,
          25
        );
      });
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;

    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
    drawPolyline(ctx);
    drawBeaconPoint(ctx);
    if (crews) {
      drawCrews(ctx);
    }
  }, [crews, hash, beaconPoints]);

  function handleMouseClick(e) {
    const rect = canvasRef.current.getBoundingClientRect();
    const offsetX = rect.left;
    const offsetY = rect.top;
    const pointX = e.clientX - offsetX;
    const pointY = e.clientY - offsetY;
    const newKX = pointX / CANVAS_WIDTH;
    const newKY = 1 - pointY / CANVAS_HEIGHT;
    // let changeInZoom = 0;
    // if (zoom !== 1) {
    //   changeInZoom = (zoom - 1) * 0.3;
    // }
    onTapHandler(
      {
        kx: newKX,
        ky: newKY
      },
      false
    );
  }

  // const handleWheel = e => {
  //   if (e.deltaY > 0) {
  //     setZoom(zoom > 1 ? zoom - 0.2 : 1);
  //   } else {
  //     setZoom(zoom + 0.2);
  //   }
  // };

  return (
    <Grid item xs>
      <div style={{ padding: 16 }}>
        <div
          style={{
            height: CANVAS_HEIGHT,
            width: CANVAS_WIDTH,
            overflow: "hidden",
            border: "0.5px solid black"
          }}
        >
          {/* <ReactPanZoom pandx={0} pandy={0} zoom={zoom}> */}
          <canvas
            // onWheel={handleWheel}
            ref={canvasRef}
            style={canvasStyle}
            height={CANVAS_HEIGHT}
            width={CANVAS_WIDTH}
            onClick={e => {
              handleMouseClick(e);
            }}
          />
          {/* </ReactPanZoom> */}
        </div>
      </div>
    </Grid>
  );
}
