import React from "react";
import { Grid, Paper } from "@material-ui/core";
import BeaconsDisplay from "./BeaconsDisplay";
import LocationsDisplay from "./LocationDisplay";
import MapDisplay from "./MapDisplay";
import CampaignsDisplay from "./CampaignDisplay";
import NotificationsDisplay from "./NotificationDisplay";
import "./Dashboard.css";
export default function DashboardContent(props) {
  return (
    <div id="parentdiv">
      <div id="firstchilddiv">
        {props.version === 3 ? (
          <React.Fragment>
            {" "}
            <Grid item xs={6} id="beacongridstyle">
              <Paper elevation="5" id="beaconpaperstyle">
                <BeaconsDisplay {...props} />
              </Paper>
            </Grid>
            <Grid item xs={6} id="locationgridstyle">
              <Paper elevation="5" id="locationpaperstyle">
                <LocationsDisplay {...props} />
              </Paper>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {" "}
            <Grid item xs={4} id="beacongridstyle">
              <Paper elevation="5" id="beaconpaperstyle">
                <BeaconsDisplay {...props} />
              </Paper>
            </Grid>
            <Grid item xs={4} id="locationgridstyle">
              <Paper elevation="5" id="locationpaperstyle">
                <LocationsDisplay {...props} />
              </Paper>
            </Grid>
            <Grid item xs={4} id="campaigngridstyle">
              <Paper elevation="5" id="campaignpaperstyle">
                <CampaignsDisplay {...props} />
              </Paper>
            </Grid>
          </React.Fragment>
        )}
      </div>
      <div id="secondchilddiv">
        {props.version === 3 ? (
          <Grid item xs={12} id="mapgridstyleIndoor">
            <Paper elevation="5" id="mappaperstyle">
              <MapDisplay {...props} />
            </Paper>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid item xs={6} id="notificationsgridstyle">
              <Paper elevation="5" id="notificationspaperstyle">
                <NotificationsDisplay />
              </Paper>
            </Grid>
            <Grid item xs={6} id="mapgridstyle">
              <Paper elevation="5" id="mappaperstyle">
                <MapDisplay />
              </Paper>
            </Grid>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
