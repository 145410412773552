import axios from "axios";
const endpoint = "https://node-api.navigine.com/sublocations/";
const userHash = "2F2C-F7C7-C494-A1AA";

export async function navigineAddSubLocation(
  locid,
  name,
  image,
  height,
  width
) {
  const LocationID = locid;
  const title = name;
  const Imgheight = height;
  const Imgwidth = width;

  let formdata1 = {
    title: title,
    file: image,
    filewidth: Imgwidth,
    fileheight: Imgheight,
    lat: 55.87,
    lon: 76.54,
    hor: 3,
    timezone: 3,
    navigation_config_id: 1
  };

  const res = await axios({
    url: `${endpoint}add?userHash=${userHash}&locationId=${LocationID}`,
    method: "POST",
    data: formdata1
  });
  return res.data.sublocation.id;
}

export async function navigineDeleteSubLocation(subid) {
  const SublocationId = subid;
  let data = "";
  await axios
    .delete(
      `${endpoint}delete?userHash=${userHash}&sublocationId=${SublocationId}`
    )
    .then(res => {
      data = res.data;
    });
  return data;
}
export async function navigineEditSubLocation(subid, name) {
  const data = [];

  const SubLocationID = subid;
  const newtitle = name;

  const formdata = new FormData();
  formdata.append("title", newtitle);

  await axios({
    url: `${endpoint}edit?userHash=${userHash}&sublocationId=${SubLocationID}`,
    method: "POST",
    data: formdata
  }).then(res => {
    if (res.data.response === "ok") {
      data.push(res.data);
    }
  });
  console.log(data);
  return data;
}
