import React, { useState, useEffect, useContext } from "react";
import { Switch, Route } from "react-router-dom";
import Styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import UserIcon from "../../assets/images/supervisor_account.svg";
import BluetoothIcon from "../../assets/images/beacon.svg";
import LocationIcon from "../../assets/images/place.svg";
import SubLocationIcon from "../../assets/images/sublocation.svg";
import CampaignIcon from "../../assets/images/notifications_active.svg";
import HomeIcon from "../../assets/images/home.svg";
import Drawer from "../Generics/drawer";
import AppBar from "../Generics/appbar";
import UserProfile from "./userProfile/UserProfile";
import SubLocation from "./sublocations/SubLocation";
import UserList from "./users/UserList";

import DashboardN from "./dashboard/Dashboard";
import Locations from "./locations/LocationList";

import Campaigns from "./campaigns/Campaigns";
import { LocationContext } from "../../contexts/LocationsContext";
import BeaconsRedesigned from "../../Components/Private/beacons/BeaconsRedesigined";

const routes = [
  {
    route: "/home",
    component: DashboardN,
    name: "home",
    icon: (
      <img
        style={{ height: "40px", width: "40px" }}
        src={HomeIcon}
        alt="logo"
      />
    ),
    exact: true
  },
  {
    route: "/home/locations",
    component: Locations,
    name: "locations",
    icon: (
      <img
        style={{ height: "40px", width: "40px" }}
        src={LocationIcon}
        alt="logo"
      />
    ),
    exact: true
  },
  {
    route: "/home/sublocations",
    component: SubLocation,
    name: "Sublocations",
    icon: (
      <img
        style={{ height: "40px", width: "40px" }}
        src={SubLocationIcon}
        alt="logo"
      />
    ),
    exact: true
  },

  {
    route: "/home/beacons",
    component: BeaconsRedesigned,
    name: "beacons",
    icon: (
      <img
        style={{ height: "40px", width: "40px" }}
        src={BluetoothIcon}
        alt="logo"
      />
    ),
    exact: true
  },

  {
    route: "/home/campaigns",
    component: Campaigns,
    name: "campaigns",
    icon: (
      <img
        style={{ height: "40px", width: "40px" }}
        src={CampaignIcon}
        alt="logo"
      />
    ),
    exact: true
  },
  {
    route: "/home/profile",
    component: UserProfile,
    name: "profile",
    icon: null,
    menu: true
  }
];
const routesIndoorNavigation = [
  {
    route: "/home",
    component: DashboardN,
    name: "home",
    icon: (
      <img
        style={{ height: "40px", width: "40px" }}
        src={HomeIcon}
        alt="logo"
      />
    ),
    exact: true
  },

  {
    route: "/home/locations",
    component: Locations,
    name: "locations",
    icon: (
      <img
        style={{ height: "40px", width: "40px" }}
        src={LocationIcon}
        alt="logo"
      />
    ),
    exact: true
  },
  {
    route: "/home/sublocations",
    component: SubLocation,
    name: "Sublocations",
    icon: (
      <img
        style={{ height: "40px", width: "40px" }}
        src={LocationIcon}
        alt="logo"
      />
    ),
    exact: true
  },
  {
    route: "/home/beaconredesgined",
    component: BeaconsRedesigned,
    name: "beacons",
    icon: (
      <img
        style={{ height: "40px", width: "40px" }}
        src={BluetoothIcon}
        alt="logo"
      />
    ),
    exact: true
  },
  {
    route: "/home/profile",
    component: UserProfile,
    name: "profile",
    icon: null,
    menu: true
  }
];

const routesLocationBased = [
  {
    route: "/home",
    component: DashboardN,
    name: "home",
    icon: (
      <img
        style={{ height: "40px", width: "40px" }}
        src={HomeIcon}
        alt="logo"
      />
    ),
    exact: true
  },

  {
    route: "/home/locations",
    component: Locations,
    name: "locations",
    icon: (
      <img
        style={{ height: "40px", width: "40px" }}
        src={LocationIcon}
        alt="logo"
      />
    ),
    exact: true
  },

  {
    route: "/home/campaigns",
    component: Campaigns,
    name: "campaigns",
    icon: (
      <img
        style={{ height: "40px", width: "40px" }}
        src={CampaignIcon}
        alt="logo"
      />
    ),
    exact: true
  },
  {
    route: "/home/beaconredesgined",
    component: BeaconsRedesigned,
    name: "beacons",
    icon: (
      <img
        style={{ height: "40px", width: "40px" }}
        src={BluetoothIcon}
        alt="logo"
      />
    ),
    exact: true
  },
  {
    route: "/home/users",
    component: UserList,
    name: "users",
    icon: (
      <img
        style={{ height: "40px", width: "40px" }}
        src={UserIcon}
        alt="logo"
      />
    ),
    exact: true
  },
  {
    route: "/home/profile",
    component: UserProfile,
    name: "profile",
    icon: null,
    menu: true
  }
];

// renders all of the array of routes recursively
function getRoutes(routesToRender, setActive, theme) {
  return (
    <div>
      {routesToRender.map(route => {
        return (
          <Switch>
            <Route
              key={route.name}
              path={route.route}
              component={p => {
                setActive(route.route);
                const Component = route.component;
                return <Component {...p} theme={theme} />;
              }}
              exact={route.exact}
            />
            {route.children && getRoutes(route.children, setActive, theme)}
          </Switch>
        );
      })}
    </div>
  );
}

const drawerWidth = 240;

const Dashboard = props => {
  const { subType } = useContext(LocationContext);
  const [active, setActive] = useState("");

  useEffect(() => {}, [active]);
  const { theme, history } = props;

  return (
    <React.Fragment>
      {subType && (
        <Body>
          {subType.locationBased === true &&
          subType.sublocationBased === false &&
          subType.indoorNavigation === false ? (
            <AppBar
              theme={theme}
              history={history}
              routes={routesLocationBased}
            />
          ) : subType.locationBased === false &&
            subType.sublocationBased === false &&
            subType.indoorNavigation === true ? (
            <AppBar
              theme={theme}
              history={history}
              routes={routesIndoorNavigation}
            />
          ) : (
            <AppBar theme={theme} history={history} routes={routes} />
          )}
          <Content>
            {subType.locationBased === true &&
            subType.sublocationBased === false &&
            subType.indoorNavigation === false ? (
              <React.Fragment>
                <Drawer
                  active={active}
                  width={drawerWidth}
                  routes={routesLocationBased}
                  {...props}
                />
                <Main theme={theme}>
                  <div>
                    <Switch>{getRoutes(routesLocationBased, setActive)}</Switch>
                  </div>
                </Main>
              </React.Fragment>
            ) : subType.locationBased === false &&
              subType.sublocationBased === false &&
              subType.indoorNavigation === true ? (
              <React.Fragment>
                <Drawer
                  active={active}
                  width={drawerWidth}
                  routes={routesIndoorNavigation}
                  {...props}
                />
                <Main theme={theme}>
                  <Switch>
                    {getRoutes(routesIndoorNavigation, setActive)}
                  </Switch>
                </Main>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Drawer
                  active={active}
                  width={drawerWidth}
                  routes={routes}
                  {...props}
                />
                <Main theme={theme}>
                  <Switch>{getRoutes(routes, setActive)}</Switch>
                </Main>
              </React.Fragment>
            )}
          </Content>
        </Body>
      )}
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  theme: PropTypes.shape.isRequired
};

export default withTheme()(Dashboard);

const Body = Styled.div`
  height:inherit;
  display: flex;
  flex-flow: column;
`;

const Content = Styled.div`
  flex:1;
  display:flex;
  flex-direction:row;
  height:100%;
`;

const Main = Styled.div`
  height:calc(100vh - 64px);
  width:100%;
  overflow:auto;
`;
