import React from "react";

import { useContext } from "react";

import { CircularProgress } from "@material-ui/core";

import DashboardContent from "./DashboardContent";

import { LocationContext } from "../../../contexts/LocationsContext";
import "./Dashboard.css";

export default function Dashboard(props) {
  const { subType } = useContext(LocationContext);
  return (
    <React.Fragment>
      {subType ? (
        <React.Fragment>
          {(subType.locationBased &&
            subType.indoorNavigation === false &&
            subType.sublocationBased === false) ||
          (subType.locationBased &&
            subType.indoorNavigation &&
            subType.sublocationBased === false) ? (
            <DashboardContent version={1} {...props} />
          ) : (subType.sublocationBased &&
              subType.indoorNavigation === false &&
              subType.locationBased === false) ||
            (subType.sublocationBased &&
              subType.locationBased &&
              subType.indoorNavigation) ||
            (subType.sublocationBased &&
              subType.indoorNavigation &&
              subType.locationBased === false) ||
            (subType.sublocationBased &&
              subType.locationBased &&
              subType.indoorNavigation === false) ? (
            <DashboardContent version={2} {...props} />
          ) : subType.indoorNavigation &&
            subType.sublocationBased === false &&
            subType.locationBased === false ? (
            <DashboardContent version={3} {...props} />
          ) : (
            <CircularProgress />
          )}
        </React.Fragment>
      ) : (
        <CircularProgress />
      )}
    </React.Fragment>
  );
}
