import firebase from "firebase";

/*
 ****************************************************************************
 * @brief  (fucntion setLocationData) - adds the campaign data added by the user to the firebase
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take title,beacon uuid,age range,list of days and time ,notification,priority,
 * proximity
 *
 * @return  what does the function return
 *-this functional component doesnt not  return anything
 ****************************************************************************
 */
// eslint-disable-next-line import/prefer-default-export
export async function setCampaigns(
  title,
  above,
  below,
  uuid = null,
  gender,
  type,
  dataAny,
  description,
  priority,
  proximity,
  when,
  dayList,
  locationId,
  uid,
  companyKey,
  timeZone,
  timestamp,
  navSublocationId = null,
  displayName
) {
  const dataRef = firebase
    .firestore()
    .collection("campaigns")
    .doc();
  await dataRef.set({
    title: title || null,
    age: {
      above: above || null,
      below: below || null
    },
    key: dataRef.id,
    uuid: uuid || null,
    gender: gender || null,
    type: type || null,
    content: dataAny || null,
    description: description || null,
    priority: priority || null,
    proximity: proximity || null,
    when: when || null,
    dayList: dayList || null,
    locationId,
    uid,
    companyKey,
    timeZone: timeZone || null,
    timestamp,
    navSublocationId: navSublocationId || null,
    displayName
  });
}
/*
 ****************************************************************************
 * @brief  (fucntion getCampaigns) - gets the campaign data added by the user from  the firebase
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take locationId
 *
 * @return  what does the function return
 *-this functional component returns the array of campagins
 ****************************************************************************
 */
export async function getCampaigns(cKey) {
  const campaigns = [];
  try {
    const snapshot = await firebase
      .firestore()
      .collection("campaigns")
      .where("companyKey", "==", cKey)
      .orderBy("timestamp", "asc")
      .get();

    snapshot.forEach(doc => {
      campaigns.push({
        age: doc.data().age,
        content: doc.data().content,
        dayList: doc.data().dayList,
        description: doc.data().description,
        gender: doc.data().gender,
        locationId: doc.data().locationId,
        priority: doc.data().priority,
        proximity: doc.data().proximity,
        when: doc.data().when,
        title: doc.data().title,
        type: doc.data().type,
        uuid: doc.data().uuid,
        key: doc.data().key,
        timestamp: doc.data().timestamp,
        navSublocationId: doc.data().navSublocationId,
        uid: doc.data().uid,
        displayName: doc.data().displayName
      });
    });
  } catch (e) {
    throw Error(e);
  }
  return campaigns;
}
/*
 ****************************************************************************
 * @brief  (fucntion deleteCampaign) - deletes the campaign data from firebase
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take key
 *
 * @return  what does the function return
 *-this functional component does not return anything
 ****************************************************************************
 */
export async function deleteCampaign(key) {
  try {
    const snapshot = await firebase
      .firestore()
      .collection("campaigns")
      .where("key", "==", key)
      .get();

    snapshot.forEach(doc => {
      firebase
        .firestore()
        .collection("campaigns")
        .doc(doc.id)
        .delete();
    });
  } catch (e) {
    throw Error(e);
  }
}
/*
 ****************************************************************************
 * @brief  (fucntion getParticularCampaign) - gets the campaign data added by the user from  the firebase
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take locationId
 *
 * @return  what does the function return
 *-this functional component returns the array of campagins
 ****************************************************************************
 */
// export async function getParticularCampaign(key) {
//   const campaigns = [];
//   try {
//     const snapshot = await firebase
//       .firestore()
//       .collection("campaigns")
//       .where("key", "==", key)
//       .get();

//     snapshot.forEach(doc => {
//       campaigns.push({
//         age: doc.data().age,
//         content: doc.data().content,
//         dayList: doc.data().dayList,
//         description: doc.data().description,
//         gender: doc.data().gender,
//         locationId: doc.data().locationId,
//         priority: doc.data().priority,
//         proximity: doc.data().proximity,
//         title: doc.data().title,
//         type: doc.data().type,
//         uuid: doc.data().uuid,
//         key: doc.data().key
//       });
//     });
//   } catch (e) {
//     throw Error(e);
//   }
//   return campaigns;
// }
/*
 ****************************************************************************
 * @brief  (fucntion updateCampaign) - updates the campaign data to firebase
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component takes input
 *
 * @return  what does the function return
 *-this functional component doesnot return anything
 ****************************************************************************
 */

export async function updateCampaign(info) {
  const dataRef = firebase
    .firestore()
    .collection("campaigns")
    .doc(info.key);
  await dataRef.update({
    ...info
  });
}

export async function UpdateCampaignsByUUID(uuid) {
  await firebase
    .firestore()
    .collection("campaigns")
    .where("uuid", "==", uuid)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        firebase
          .firestore()
          .collection("campaigns")
          .doc(doc.id)
          .update({
            uuid: null,
            beaconId: null
          });
      });
    });
}
