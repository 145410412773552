/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-indent */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState } from "react";

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import PropTypes from "prop-types";


import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import {
  deleteCampaign // update location data to firebase
} from "../../../controllers/firebase";

/*
Declearing the functional component 
*/
function DeleteLocationDialogBox({ campaign, ...props }) {
  // variable to handle open /close dialog box
  const [open, setOpen] = useState(false);

  // variable to store locname
  const [key, setKey] = useState();

  // method to open the dialog box
  const handleClickOpen = loc => {
    setOpen(true);

    setKey(campaign.key);
  };

  // method to close the dialog box
  const handleClose = () => {
    setOpen(false);
  };

  // method to handle submit of edit button
  const handleDelete = async () => {
    await deleteCampaign(key);

    props.update();

    setOpen(false);
  };
  // Add necessary comments in the return
  return (
    <div>
      <DeleteForeverIcon
        style={{ color: "#173964" }}
        onClick={handleClickOpen}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {" "}
          <Typography
            style={{
              fontSize: "20px",
              fontWeight: "bolder",

              fontFamily: "helvetica neue, sans-serif"
            }}
          >
            Delete Campaign
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "bolder",
                color: "#A3A3A3",
                fontFamily: "helvetica neue, sans-serif"
              }}
            >
              Are you sure you want to delete this 
{' '}
{campaign.title}
?
</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="primary"
            style={{ color: "white" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

/*
Validating React Component Props
*/
DeleteLocationDialogBox.propTypes = {
  campaign : PropTypes.array.isRequired,
  update: PropTypes.func.isRequired
};

export default DeleteLocationDialogBox;
