/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_styled.js
 *
 *  Description: Provides app wide styled components
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import Styled, { css } from "styled-components";

export const StyledForm = Styled.form`
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: space-evenly;
`;

export const Container = Styled.form`
  
`;
export const Horizontal = Styled.div`
  display:flex;
  flex-direction:row;
`;

export const SmallLogo = Styled.div`
  flex:1;
  margin:auto;
  font-size:24px;
  font-weight:bold;
  text-align:left;
`;

export const CenterScreen = Styled.div`
    color:{props=>props.theme.secondary};
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:100vh;
    margin:auto;
`;

export const Padding = Styled.div`
padding:${props => props.value}px;
padding-left:${props => props.left}px
padding-right:${props => props.right}px
padding-top:${props => props.top}px
padding-bottom:${props => props.bottom}px
`;

export const PaddedDescText = Styled.div`
  padding:20px;
  width:100%;
  height:100%;
  margin:auto;
  text-align:center;
`;

export const FloatingButton = Styled.div`
  position:fixed;
  bottom:40px;
  right:40px;
  padding:20px;
  z-index:10;
`;

export const Row = Styled.div`
  display: flex;
  width: ${props => props.width || "100%"};
  margin-bottom: ${props => props.marginbottom || "6px"};
  justify-content: ${props => props.justify || "inherit"};
  align-items: ${props => props.align || "inherit"};
  ${props =>
    props.vertCenter &&
    css`
      align-items: center;
    `};
`;

export const Col = Styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormContainer = Styled.div`
    width:60%;
`;
