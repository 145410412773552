/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from "@material-ui/core";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import {
  getUser,
  getCurrentUser,
  getCurrentCompanyKey,
  resetPassword,
  deleteUser,
  disableUser,
  enableUser
} from "../../../controllers/firebase";
import autoRenew from "./autorenew_24px.svg";

import AddUser from "./AddUser";

const headerStyle = {
  fontSize: "20px",
  fontWeight: "bolder",
  fontFamily: "helvetica neue, sans-serif"
};

const bodyStyle = {
  fontSize: "16px",
  fontWeight: "bolder",
  // width: "10px",
  fontFamily: "helvetica neue, sans-serif"
};
const addressStyle = {
  fontSize: "16px",
  color: "#A3A3A3",
  fontWeight: "normal",
  fontFamily: "helvetica neue, sans-serif"
};

/*
Declearing the functional component 
*/
function UsersList() {
  // variable to store company key fetched from firebase
  const [companyKey, setCompanyKey] = useState();
  const [users, setUsers] = useState();
  const [counter, setCounter] = useState(true);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState();

  // method to intialize all the variables which are fecthed from firebase
  useEffect(() => {
    initData();
    //eslint-disable-next-line
  }, []);

  function initData() {
    getCurrentUser(u => {
      getCurrentCompanyKey().then(e => {
        setCompanyKey(e);
        getUserData(e);
      });
    });
  }
  function handleClose() {
    setOpen(false);
  }
  const handleClickOpen = msg => {
    setOpen(true);
    setMsg(msg);
  };
  const updateUsers = async () => {
    await getUserData(companyKey);
  };

  const getUserData = async key => {
    await getUser(key).then(array => {
      if (array.length >= 16) {
        setCounter(false);
      }
      setUsers(array);
    });
  };
  const reset = async email => {
    await resetPassword(email);
    handleClickOpen("Reset-Password Email Sent");
    // alert("email sent ");
  };

  const handleDelete = async (id, index) => {
    const data = users.filter(u => u.uid !== id);
    setUsers(data);
    setCounter(true);

    await deleteUser(id);
    handleClickOpen("User Deleted");
    // alert("deleted");
    initData();
    await updateUsers(companyKey);
  };
  const handleDisable = async id => {
    await disableUser(id);
    handleClickOpen("User Disabled");
    // alert("disabled");
  };
  const handleEnable = async id => {
    await enableUser(id);
    handleClickOpen("User Enabled");
    // alert("enabled");
  };

  // Add necessary comments in the return
  return (
    <div style={{ minWidth: "800px" }}>
      <AddUser counter={counter} updateUsers={updateUsers} />
      <Paper elevation="5" style={{ marginTop: 20 }}>
        {users == null ? (
          <div id="circulardiv">
            {" "}
            <CircularProgress />{" "}
          </div>
        ) : (
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: "#BFE6F7" }}>
                <TableCell style={headerStyle}>Name</TableCell>
                <TableCell align="center" style={headerStyle}>
                  Email
                </TableCell>
                <TableCell align="center" style={headerStyle}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.map((user, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" style={bodyStyle}>
                      {user.name.charAt(0).toUpperCase() + user.name.slice(1)}
                    </TableCell>
                    <TableCell align="center" style={addressStyle}>
                      {user.email}
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={autoRenew}
                        alt="reset"
                        onClick={() => reset(user.email)}
                        title="reset-password"
                        style={{ cursor: "pointer" }}
                      />
                      <DeleteForeverIcon
                        style={{ color: "#173964", cursor: "pointer" }}
                        onClick={() => {
                          handleDelete(user.uid, index);
                        }}
                      />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          handleEnable(user.uid);
                        }}
                      >
                        Enable
                      </Button>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          handleDisable(user.uid);
                        }}
                      >
                        Disable
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogTitle id="alert-dialog-title">{msg}</DialogTitle>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </div>
  );
}

/*
Validating React Component Props
*/
UsersList.propTypes = {};

export default UsersList;
