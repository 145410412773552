/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";

import {
  getCurrentUser, // get current user from firebase
  getCurrentCompanyKey, // get current company key of signed-in user
  getBeaconConfig
} from "../../../controllers/firebase";

import "./Beacon.css";

/*
Declearing the functional component 
*/

function DefaultProperties(props) {
  const [config, setConfig] = useState("");

  useEffect(() => {
    // method to intialize all the variables which are fecthed from firebase
    getCurrentUser(u => {
      if (u)
        getCurrentCompanyKey(u.uid).then(e => {
          getBeacon(e);
        });
    });
  }, []);
  const getBeacon = key => {
    // getBeaconConfig(key).then(obj => {
    //   setConfig(obj);
    // });
    // setId(props.beaconData.uuid);
    //if ((check = true)) {
    getBeaconConfig(key, props.beaconData.uuid).then(obj => {
      setConfig(obj);
    });
    //}

    //"4245434e-1001-0011-1011-101010101010"
  };

  // Add necessary comments in the return
  return (
    <Grid style={{ marginBottom: "15px" }}>
      <Paper elevation="5" style={{ paddingBottom: "10px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} id="title">
            DEFAULT PROPERTIES{" "}
          </Grid>

          <Grid item xs={6} id="gridstyle">
            <Typography>
              <span id="field">Device ID:</span>
              <span id="deviceinfo">{props.beaconData.uuid}</span>
            </Typography>

            {/* <Typography>
              <span id="field">GEO Location:</span>{" "}
              <span id="locationinfo"></span>
            </Typography> */}
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <span id="field">Firmware version</span>
              <span id="firmwareinfo">{config.HVR}</span>
            </Typography>
            <Typography>
              <span id="field">Hardware Version: </span>
              <span id="sublocationinfo">{config.FVR}</span>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

/*
Validating React Component Props
*/
DefaultProperties.propTypes = {};

export default DefaultProperties;
