/* eslint-disable import/prefer-default-export */
import firebase from "firebase";
/*
 ****************************************************************************
 * @brief  (fucntion addUser) - It allows to add user under an admin
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component takes user email and password
 *
 * @return  what does the function return
 *-this functional component  doesnot return any value
 ****************************************************************************

 */

export async function addUser(name, email) {
  const createUser = firebase.functions().httpsCallable("userGroup-createUser");
  await createUser({ name, email });

  return true;
}
/*
 ****************************************************************************
 * @brief  (fucntion getUser) - It allows to get users of that company
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component takes user email and password
 *
 * @return  what does the function return
 *-this functional component  returnsarray of users
 ****************************************************************************

 */

export async function getUser(companyKey) {
  const users = [];
  try {
    const snapshot = await firebase
      .firestore()
      .collection("users")
      .where("companyKey", "==", companyKey)
      .get();

    snapshot.forEach(doc => {
      users.push({
        name: doc.data().name,
        email: doc.data().email,
        uid: doc.data().uid
      });
    });
  } catch (e) {
    throw Error(e);
  }
  return users;
}

export async function resetPassword(email) {
  await firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      return true;
    })
    .catch(function(e) {
      throw Error(e);
    });
}

export async function deleteUser(uid) {
  const userGroupdeleteUser = await firebase
    .functions()
    .httpsCallable("userGroup-deleteAccount");
  await userGroupdeleteUser({
    uid
  });

  return true;
}

export async function disableUser(uid) {
  const userGroupdisableAccount = await firebase
    .functions()
    .httpsCallable("userGroup-disableAccount");
  userGroupdisableAccount({
    uid
  });
  return true;
}
export async function enableUser(uid) {
  const userGroupenableAccount = await firebase
    .functions()
    .httpsCallable("userGroup-enableAccount");
  userGroupenableAccount({
    uid
  });
  return true;
}
