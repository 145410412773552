/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState } from "react";

import {
  Button,
  Paper,
  Typography,
  TextField,
  CircularProgress
} from "@material-ui/core";

import { addUser } from "../../../controllers/firebase";

import { ValidateEmail, ValidateUserName } from "../../../helpers/validators";
import "./Users.css";

/* 
Declearing the functional component 
*/
function AddUser(props) {
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: ""
  });
  const [errors, setErrors] = useState({
    name: {
      status: false,
      message: "50 chars , dont leave it empty"
    },
    email: {
      status: false,
      message: "enter valid email input"
    }
  });
  const [baseInfo, setBaseInfo] = useState({
    name: "",
    email: ""
  });

  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    if (event.target.name === "name") {
      if (ValidateUserName(event.target.value)) {
        setUserInfo({
          ...userInfo,
          [event.target.name]: event.target.value
        });
        setErrors({
          ...errors,
          name: {
            status: false,
            error: "enter valid name input"
          }
        });
      } else {
        setErrors({
          ...errors,
          name: {
            status: true,
            message: "enter valid name input"
          }
        });
      }
    } else if (event.target.name === "email") {
      if (ValidateEmail(event.target.value)) {
        setUserInfo({
          ...userInfo,
          [event.target.name]: event.target.value
        });
        setErrors({
          ...errors,
          email: {
            status: false,
            message: "enter valid email input"
          }
        });
      } else {
        setErrors({
          ...errors,
          email: {
            status: true,
            message: "enter valid email input"
          }
        });
      }
    }

    setUserInfo({
      ...userInfo,
      [event.target.name]: event.target.value
    });
  };
  const handleSubmit = async () => {
    try {
      if (
        userInfo.name &&
        userInfo.email &&
        !errors.name.status &&
        !errors.email.status
      ) {
        setLoading(true);
        await addUser(userInfo.name, userInfo.email);

        setUserInfo({ ...baseInfo });
        props.updateUsers();
      } else {
        alert("Enter valid inputs please ");
      }
    } catch (e) {
      throw Error(e);
    } finally {
      setLoading(false);
    }
  };

  // Add necessary comments in the return
  return (
    <Paper>
      <Typography style={{ marginLeft: "1.3%", paddingTop: "1%" }}>
        <span id="adduserstyle">Add a new user</span>

        <span id="addusercontentstyle"> Enter new user name and email</span>
      </Typography>
      <div id="divstyle">
        <TextField
          id="outlined-name"
          label="Enter name "
          name="name"
          value={userInfo.name}
          onChange={handleChange}
          style={{ display: "flex", flex: 2, margin: 5 }}
          variant="outlined"
          error={errors.name.status}
          helperText={errors.name.status && errors.name.message}
        />
        <TextField
          id="outlined-name"
          label="Enter email"
          name="email"
          value={userInfo.email}
          style={{ display: "flex", flex: 2, margin: 5 }}
          onChange={handleChange}
          variant="outlined"
          error={errors.email.status}
          helperText={errors.email.status && errors.email.message}
        />
        {props.counter ? (
          <Button
            variant="contained"
            color="primary"
            margin="normal"
            style={{ display: "flex", flex: 1, margin: 5 }}
            onClick={handleSubmit}
            style={{
              color: "white",
              verticalAlign: "middle",
              fontSize: 20
            }}
          >
            {loading ? <CircularProgress color="white" /> : "Add User"}
          </Button>
        ) : (
          <span style={{ color: "red" }}>
            You cannot create more than 16 users
          </span>
        )}
      </div>
    </Paper>
  );
}

/*
Validating React Component Props
*/
AddUser.propTypes = {};

export default AddUser;
