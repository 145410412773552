/* eslint-disable func-names */
import React, { useEffect, useRef } from "react";
import { Grid, IconButton } from "@material-ui/core";
import ReactPanZoom from "@ajainarayanan/react-pan-zoom";
import PlusIcon from "mdi-react/MagnifyPlusIcon";
import MinusIcon from "mdi-react/MagnifyMinusIcon";

export default function IndoorCanvas({
  image,
  userData,
  CanvasHeight,
  CanvasWidth
}) {
  const [zoom, setZoom] = React.useState(1);
  const [pan, setPan] = React.useState({ dx: 0, dy: 0 });
  const canvasStyle = {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    border: "2px solid green",
    backgroundPosition: "center",
    // border: "1.5px solid black",
    cursor: "pointer",
    overflow: "hidden"
  };

  const canvasRef = useRef(null);

  function drawBeaconPoint(ctx) {
    if (userData) {
      const img = new Image();
      img.src = "https://img.icons8.com/officel/30/000000/user.png";
      ctx.beginPath();
      // img.onload = function() {
      Object.values(userData).forEach(point => {
        if (point.x !== 0 && point.y !== 0) {
          ctx.moveTo(CanvasWidth * point.x, CanvasHeight * point.y);
          ctx.arc(
            CanvasWidth * point.x,
            CanvasHeight * point.y,
            10,
            0,
            2 * Math.PI
          );
          //ctx.drawImage(img, CanvasWidth * point.x, CanvasHeight * point.y);
        }
      });
      // };
      ctx.fillStyle = "#0095DD";
      ctx.fill();
      ctx.closePath();
    }
  }

  function intersects(x, y, cx, cy, r) {
    const kx = x - cx;
    const ky = y - cy;
    if (kx * kx < r * r && ky * ky < r * r) {
      return true;
    }
    return false;
  }

  function handleClick(e) {
    const rect = canvasRef.current.getBoundingClientRect();

    const offsetX = rect.left;
    const offsetY = rect.top;

    const pointX = e.clientX - offsetX;
    const pointY = e.clientY - offsetY;
    if (userData !== undefined && userData !== null) {
      Object.values(userData).forEach(points => {
        // if (active || activeU) {
        if (
          intersects(
            pointX / CanvasWidth,
            pointY / CanvasHeight,
            points.x,
            points.y,
            0.04
          )
        ) {
          alert(points.name);
        }
        //  }
      });
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, CanvasWidth, CanvasHeight);
    drawBeaconPoint(ctx);
  }, [userData, image, CanvasWidth, CanvasHeight, drawBeaconPoint]);

  function zoomIn() {
    const zoomValue = zoom > 2 ? zoom : zoom + 0.2;
    setZoom(zoomValue);
  }

  function zoomOut() {
    const zoomValue = zoom < 0.8 ? zoom : zoom - 0.2;
    setZoom(zoomValue);
  }
  function onPan(dx, dy) {
    setPan({ dx, dy });
  }

  return (
    <>
      <Grid
        item
        container
        xs
        sm={11}
        style={{
          border: "1.5px solid black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden"
        }}
      >
        {/* <ReactPanZoom zoom={zoom} onPan={onPan} pandx={pan.dx} pandy={pan.dy}> */}
        <canvas
          ref={canvasRef}
          style={canvasStyle}
          height={CanvasHeight}
          width={CanvasWidth}
          onClick={e => {
            handleClick(e);
          }}
        />
        {/* </ReactPanZoom> */}
      </Grid>
      <IconButton onClick={zoomIn}>
        <PlusIcon />
      </IconButton>
      <IconButton onClick={zoomOut}>
        <MinusIcon />
      </IconButton>
    </>
  );
}
