/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import {
  AppBar,
  Button,
  TextField,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from "@material-ui/core";
import { auth } from "firebase";

import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import add_location from "../../../assets/images/add_location.svg";
import highlight_off from "../../../assets/images/highlight_off.svg";
import BeaconListBox from "./beacon_list_box";
import { Row } from "../../../styles/styled";
import {
  getUnassignedBeacons,
  assignBeacon,
  unassignBeacon
} from "../../../controllers/beacons";
import {
  getCurrentCompanyKey,
  getSubLocationInfo,
  setSubLocationData,
  getRealTimeUserData,
  getUserInfo
} from "../../../controllers/firebase";
import {
  snowmGetAllBeacons,
  snowmAddBeacon,
  snowmDeleteBeacon
} from "../../../controllers/navigine_beacons";
import { snowmVersion } from "../../../controllers/navigine_versions";
import { UpdateCampaignsByUUID } from "../../../controllers/campaigns";
import IndoorMap from "./Canvas/indoor_map_canvas";
import BeaconItem from "./Beacon_item";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/*
Declearing the functional component 
*/
let newData;

function SubLocationDialogBox({
  open,
  handleClose,
  subId,
  navLocId,
  ...props
}) {
  const [showActionBox, setShowActionBox] = useState(false);
  const [beaconPoints, setBeaconPoints] = useState([]);
  const [beaconBoxOpen, setBeaconBoxOpen] = useState(false);
  const [selectedBeacon, setSelectedBeacon] = useState();
  const [companyKey, setCompanyKey] = useState();
  const [edit, setEdit] = useState(false);
  // const [allUserInfo, setAllUserInfo] = setState({});

  const [subLocation, setSublocation] = useState(null);
  const [crews, setCrews] = useState({});

  const [unAssignedBeacons, setUnAssignedBeacons] = useState([]);

  const [assignBeaconLoading, setAssignBeaconLoading] = useState(false);
  const [assign, setAssign] = useState(true);

  const update = () => {
    props.updateSubLocation();
  };

  useEffect(() => {
    if (subId) {
      getSubLocationInfo(subId).then(data => {
        setSublocation(data.sublocation);
      });
    }
  }, [subId]);

  useEffect(() => {
    if (open) {
      setEdit(false);
    }
  }, [open]);

  useEffect(() => {
    if (subLocation) startRealTimeUserTracking();
  }, [subLocation]);

  const onUserLocationUpdated = snapshot => {
    let crewsInLocation = snapshot.val();
    if (crewsInLocation) {
      Object.keys(crewsInLocation).forEach(crewId => {
        getUserInfo(crewId).then(userInfo => {
          if (userInfo) {
            crewsInLocation[crewId].imageUrl = userInfo.imageUrl;
            let updatedCrews = crews;
            updatedCrews[crewId] = crewsInLocation[crewId];
            setCrews({ ...updatedCrews });
          }
        });
      });
    } else {
      setCrews({});
    }
  };

  function startRealTimeUserTracking() {
    getRealTimeUserData(
      subLocation.location_id,
      subLocation.id,
      onUserLocationUpdated
    );
  }

  function handleSave() {
    snowmVersion(navLocId);
    props.updateBeacon(companyKey);
  }

  function handleBeaconDialogClose() {
    setBeaconBoxOpen(false);
  }

  function handleActionBoxDialogClose() {
    setShowActionBox(false);
  }

  const toggleAction = value => {
    if (value) {
      setAssign(value);
    } else {
      setAssign(!assign);
    }
  };

  function onMapTapped(pointData) {
    if (!edit) {
      return;
    }
    let selectedBeacon;
    if (beaconPoints && beaconPoints.length !== 0)
      for (let i = 0; i < beaconPoints.length; i++) {
        let xdiff = pointData.kx - beaconPoints[i].kx;
        let ydiff = pointData.ky - beaconPoints[i].ky;

        if (xdiff < 0.01 && ydiff < 0.01) {
          selectedBeacon = beaconPoints[i];
          break;
        }
      }
    if (selectedBeacon) {
      showActions(selectedBeacon);
    } else if (assign) {
      newData = pointData;
      setBeaconBoxOpen(true);
    }
  }
  async function fetchBeaconsFromNavigine() {
    const assignedBeacons = await snowmGetAllBeacons(subId); // getting all beacons of a particular subId
    setBeaconPoints(assignedBeacons[0].beacons);
  }
  async function getUnABeacons(email) {
    const companyKey = await getCurrentCompanyKey(email);
    const unABeacons = await getUnassignedBeacons(companyKey); // getting all unassigned beacons of the company
    setUnAssignedBeacons(unABeacons);
  }

  async function deleteBeacon() {
    snowmDeleteBeacon(selectedBeacon.id).then(async () => {
      handleActionBoxDialogClose();

      fetchBeaconsFromNavigine();
      unassignBeacon(selectedBeacon.uuid);
      UpdateCampaignsByUUID(selectedBeacon.uuid);
    });
  }

  function showBeaconDetail(beacon) {
    alert(`beacon detail: ${beacon.description}`);
    handleActionBoxDialogClose();
  }

  function showActions(beacon) {
    setSelectedBeacon(beacon);
    if (!assign) {
      setShowActionBox(true);
    } else {
      showBeaconDetail(beacon);
    }
  }

  useEffect(() => {
    const currentUserEmail = auth().currentUser.email;
    getUnABeacons(currentUserEmail);
    getCurrentCompanyKey(currentUserEmail).then(key => {
      setCompanyKey(key);
    });
  }, [beaconPoints]);

  useEffect(() => {
    fetchBeaconsFromNavigine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subId]);

  const selectBeacon = async beacon => {
    setAssignBeaconLoading(true);
    try {
      const response = await snowmAddBeacon(
        subId,
        beacon.name,
        beacon.uuid,
        beacon.max,
        beacon.min,
        newData.kx,
        newData.ky
      );

      if (response) {
        await assignBeacon(
          beacon.uuid,
          props.locId,
          navLocId,
          subId,
          response.beacon.id
        );
        setBeaconPoints([...beaconPoints, response.beacon]);
      }
      handleBeaconDialogClose();
      setAssignBeaconLoading(false);
    } catch (e) {
      console.error(e);
      // handleBeaconDialogClose();
      // setAssignBeaconLoading(false);
    }
  };
  console.log(subLocation);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <div>
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="Close"
              style={{ color: "white" }}
            >
              <CloseIcon onClick={handleSave} />
            </IconButton>

            {/* <Button
              color="inherit"
              onClick={handleSave}
              style={{ marginLeft: "90%", color: "white" }}
            >
              close
            </Button> */}
          </Toolbar>
        </AppBar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // put the fixed height of container here
            height: "100vh"
          }}
        >
          <div
            style={{
              // fixed height of the first element
              height: 80,
              display: "flex",
              flexDirection: "row",
              flexGrow: 0,
              paddingLeft: "2%"
            }}
          >
            <Row>
              <TextField
                style={{
                  fontSize: "22px",
                  fontWeight: "bolder",
                  margin: "2% 0% 0% 1% ",
                  fontFamily: "helvetica neue, sans-serif"
                }}
                disabled={!edit}
                value={props.subName}
                fullWidth={true}
              >
                {props.subName}
              </TextField>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  margin: 10
                }}
                onClick={() => setEdit(!edit)}
              >
                {edit ? "Cancel" : "Edit"}
              </Button>
            </Row>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              paddingLeft: "2%"
            }}
          >
            <Grid container>
              {edit && (
                <Row style={{ width: "50%" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    color={assign ? "primary" : "default"}
                    style={{ margin: 10 }}
                    onClick={() => toggleAction(true)}
                  >
                    <img
                      src={add_location}
                      alt="logo"
                      style={{ marginRight: "5%" }}
                    />
                    Assign Beacon
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color={!assign ? "primary" : "default"}
                    style={{ margin: 10 }}
                    onClick={() => toggleAction(false)}
                  >
                    <img src={highlight_off} alt="logo" />
                    Unassign Beacon
                  </Button>
                </Row>
              )}

              <div style={{ margin: 10 }}>
                {subLocation ? (
                  <IndoorMap
                    image={subLocation.files[0].fullUrl}
                    imageHeight={subLocation.ph}
                    imageWidth={subLocation.pw}
                    beaconPoints={beaconPoints}
                    onTapHandler={onMapTapped}
                    crews={crews}
                  />
                ) : (
                    <p>Loading...</p>
                  )}
              </div>
            </Grid>
          </div>
        </div>

        <BeaconListBox
          open={beaconBoxOpen}
          handleNegativeAction={handleBeaconDialogClose}
        >
          <h5>Please select a beacon to assign.</h5>
          {!assignBeaconLoading ? (
            unAssignedBeacons.map(b => {
              return (
                <BeaconItem beacon={b} onSelectBeacon={() => selectBeacon(b)} />
              );
            })
          ) : (
              <>
                <Typography>Loading</Typography>
                <CircularProgress />
              </>
            )}
        </BeaconListBox>

        <Dialog
          open={showActionBox}
          onClose={handleActionBoxDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Typography
              style={{
                fontSize: "20p",
                fontWeight: "bolder",
                fontFamily: "helvetica neue, sans-serif",
                marginBottom: "0%"
              }}
            >
              Delete Location
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "bolder",
                  color: "#a3a3a3",
                  fontFamily: "helvetica neue, sans-serif",
                  marginTop: "0%"
                }}
              >
                Are you sure you want to delete this{" "}
                {selectedBeacon && selectedBeacon.description} ?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleActionBoxDialogClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => deleteBeacon()}
              variant="contained"
              color="primary"
              style={{ color: "white" }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showActionBox}
          onClose={handleActionBoxDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Typography
              style={{
                fontSize: "20p",
                fontWeight: "bolder",
                fontFamily: "helvetica neue, sans-serif",
                marginBottom: "0%"
              }}
            >
              Delete Location
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "bolder",
                  color: "#a3a3a3",
                  fontFamily: "helvetica neue, sans-serif",
                  marginTop: "0%"
                }}
              >
                Are you sure you want to delete this{" "}
                {selectedBeacon && selectedBeacon.description} ?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleActionBoxDialogClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => deleteBeacon()}
              variant="contained"
              color="primary"
              style={{ color: "white" }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Dialog>
  );
}

/*
Validating React Component Props
*/
SubLocationDialogBox.propTypes = {};

export default SubLocationDialogBox;
