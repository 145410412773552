/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect, useContext } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid,
  CircularProgress
} from "@material-ui/core";
import "./Location.css";
import EditLocationDialogBox from "./EditLocationDialogBox";
import {
  getCurrentUser,
  getCurrentCompanyKey,
  getBeacons
} from "../../../controllers/firebase";
import AssignUnassignDialogBox from "./AssignUnassignDialogBox";
import DeleteLocationDialogBox from "./DeleteDialogBox";
import AddLocation from "./AddLocation";
import { LocationContext } from "../../../contexts/LocationsContext";

/*
Declearing the functional component 
*/
function LocationsList() {
  // variable to store location values fetched from the firebase

  // variable to store company key fetched from firebase
  const [companyKey, setCompanyKey] = useState();
  // variable to store the userid fetched from firebase
  const [uid, setUid] = useState();
  const [counter, setCounter] = useState(true);
  const [beacons, setBeacons] = useState();
  const { subType, locationss, getLocData } = useContext(LocationContext);

  // method to intialize all the variables which are fecthed from firebase
  useEffect(() => {
    getCurrentUser(u => {
      setUid(u.uid);
      getCurrentCompanyKey().then(e => {
        setCompanyKey(e);

        getBeacon(e);
      });
    });
  }, []);

  const updateBeacon = () => {
    getBeacon(companyKey);
  };
  const getBeacon = key => {
    getBeacons(key).then(array => {
      setBeacons([...array]);
    });
  };
  const updateLocation = () => {
    getLocData(companyKey);
    setCounter(true);
  };

  // Add necessary comments in the return
  return (
    <div>
      <AddLocation updateLocation={updateLocation} counter={counter} />
      <Paper elevation="5" style={{ margin: 15 }}>
        {locationss == null ? (
          <div id="circulardiv">
            <CircularProgress />{" "}
          </div>
        ) : (
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: "#BFE6F7" }}>
                <TableCell class="headerstyle">Name</TableCell>
                <TableCell class="headerstyle">Address</TableCell>
                <TableCell class="headerstyle">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locationss &&
                locationss.map((location, index) => (
                  <TableRow key={index}>
                    <TableCell id="bodystyle">
                      {location.locationName.charAt(0).toUpperCase() +
                        location.locationName.slice(1)}
                    </TableCell>
                    <TableCell align="center" id="addressstyle">
                      {location.address}
                    </TableCell>
                    <TableCell style={{ width: "620px" }}>
                      {subType.locationBased === false ? (
                        <Grid
                          container
                          direction="row-reverse"
                          style={{ width: "360px" }}
                        >
                          <Grid item xs={1} sm={2}>
                            <DeleteLocationDialogBox
                              loc={location}
                              updateLocation={updateLocation}
                              beacons={beacons}
                            />
                          </Grid>
                          <Grid item xs={3} sm={2}>
                            <EditLocationDialogBox
                              loc={location}
                              updateLocation={updateLocation}
                              index={index}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          direction="row-reverse"
                          style={{ width: "620px" }}
                        >
                          <Grid item xs={1} sm={1}>
                            <DeleteLocationDialogBox
                              loc={location}
                              updateLocation={updateLocation}
                              beacons={beacons}
                            />
                          </Grid>
                          <Grid item xs={3} sm={1}>
                            <EditLocationDialogBox
                              loc={location}
                              updateLocation={updateLocation}
                              index={index}
                            />
                          </Grid>

                          <Grid item xs={4} sm={4}>
                            <AssignUnassignDialogBox
                              loc={location}
                              updateBeacon={updateBeacon}
                              switch={1}
                            />
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <AssignUnassignDialogBox
                              loc={location}
                              updateBeacon={updateBeacon}
                              switch={0}
                            />
                          </Grid>
                        </Grid>
                      )}

                      {/* <Grid
                        container
                        direction="row-reverse"
                        style={{ width: "360px" }}
                      >
                        <Grid item xs={1} sm={2}>
                          <DeleteLocationDialogBox
                            loc={location}
                            updateLocation={updateLocation}
                            beacons={beacons}
                          />
                        </Grid>
                        <Grid item xs={3} sm={2}>
                          <EditLocationDialogBox
                            loc={location}
                            updateLocation={updateLocation}
                            index={index}
                          />
                        </Grid>
                        {(subType.locationBased === false &&
                          subType.sublocationBased === true &&
                          subType.indoorNavigation === false) ||
                        (subType.locationBased === false &&
                          subType.sublocationBased === false &&
                          subType.indoorNavigation === true) ? (
                          <React.Fragment></React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Grid item xs={4} sm={4}>
                              <AssignUnassignDialogBox
                                loc={location}
                                updateBeacon={updateBeacon}
                                switch={1}
                              />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                              <AssignUnassignDialogBox
                                loc={location}
                                updateBeacon={updateBeacon}
                                switch={0}
                              />
                            </Grid>
                          </React.Fragment>
                        )} */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </Paper>
    </div>
  );
}

/*
Validating React Component Props
*/
LocationsList.propTypes = {};

export default LocationsList;
