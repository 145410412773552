/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: firebase.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Pratyusha Nalluri(pratyusha@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

import firebase, { database } from "firebase";
import axios from "axios";
import {
  setLocationData,
  updateLocationData,
  deleteLocation,
  getLocationData
} from "./locations";
import {
  setSubLocationData,
  getSubLocationData,
  deleteSubLocation,
  uploadTaskPromise,
  updateSubLocation
} from "./subLocations";
import { getRouteData, setRouteData, deleteRoute } from "./routedata";
import {
  assignBeacon,
  getBeacons,
  getUnassignedBeacons,
  unassignBeacon,
  getAssignedBeacons,
  getBeaconHeader,
  getBeaconConfig,
  getBeaconEddy,
  getBeaconIbeacon
} from "./beacons";
import {
  setCampaigns,
  getCampaigns,
  deleteCampaign,
  updateCampaign
} from "./campaigns";
import {
  addUser,
  getUser,
  resetPassword,
  deleteUser,
  disableUser,
  enableUser
} from "./user";
import { getAdministrator } from "./administrators";
import { getNotificationData, deleteNotification } from "./notifications";
/*
 ****************************************************************************
 * @brief  (fucntion firebase) -  firebase.js has all the initializations to firebase
 * and it exports multiple functions which are used for retrieving data and adding the
 * data back to the database
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component doesnot take any input parameters
 *
 * @return  what does the function return
 *-this functional component  returns  values depending on the function call
 ****************************************************************************
 */

// connectiong to the firebase
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};
/*
 ****************************************************************************
 * @brief  (fucntion getAuthenticated) -  this function returns if the value
 * true if user is authenticated or false if user is not authenticated
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component doesnot take any input parameters
 *
 * @return  what does the function return
 *-this functional component  returns  authenticated
 ****************************************************************************
 */
let authenticated = null;
export const getAuthenticated = () => {
  return authenticated;
};
/*
 ****************************************************************************
 * @brief  (fucntion initFirebase) -  This function initializes the firebase
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component doesnot take any input parameters
 *
 * @return  what does the function return
 *-based on the user credentials ,it sets the init value and returns it
 ****************************************************************************
 */

// intializing Firebase
let companyKey = null;
export function initFirebase() {
  if (firebase.apps.length === 0) {
    firebase.initializeApp(config);
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        authenticated = true;
      } else {
        authenticated = false;
      }
    });
  }
}

export const { auth } = firebase;

/*
 ****************************************************************************
 * @brief  (fucntion LoginWithEmailPassword) - It allows user to signIn to firebase
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component takes user email and password
 *
 * @return  what does the function return
 *-this functional component  returns  boolean value if the user credentials are true or false
 ****************************************************************************
 */

// authentication functions
export async function loginWithEmailPassword(email, password) {
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(user => {
      return user;
    });
}

/*
 ****************************************************************************
 * @brief  (fucntion getCurrentUser) - This function gives you the auth object of the
 * current signed in user
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component  takes any input of changed user
 *
 * @return  what does the function return
 *-this functional component  returns  auth object of the current user
 ****************************************************************************
 */
// getting the current logged in user
export function getCurrentUser(onChanged) {
  firebase.auth().onAuthStateChanged(user => onChanged(user));
}

export function isAuthenticated() { }

/*
 ****************************************************************************
 * @brief  (fucntion logout) - this function allows the user to loguot from firebase
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component doesnot take any input parameters
 *
 * @return  what does the function return
 *-this functional component  doesnot return any value
 ****************************************************************************
 */
// logging out from the firebase
export async function logout() {
  await firebase.auth().signOut();
}
/*
 ****************************************************************************
 * @brief  (fucntion getCUrrentCompanyKey) - gets company key of current logged in user
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take email of current logged-in user
 *
 * @return  what does the function return
 *-this functional component  returns  companyKey
 ****************************************************************************
 */

// getting the comapanykey of the current logged in user
export async function getCurrentCompanyKey(uid) {
  let snap;
  if (companyKey) {
    return companyKey;
  } else if (firebase.auth().currentUser) {
    // const { uid } = firebase.auth().currentUser;
    if (!uid) {
      throw Error("please login first");
    } else {
      snap = await firebase
        .firestore()
        .collection("administrators")
        .doc(uid)
        .get();
      if (snap.exists) {
        companyKey = snap.data().companyKey;
      }
    }
    return companyKey;
  } else {
    throw Error("please login first");
  }
}

export async function getSubscriptionType(companykey) {
  const type = await firebase
    .firestore()
    .collection("customers")
    .doc(companykey)
    .get();

  return type.data();
}

/*
 ****************************************************************************
 * @brief  (fucntion setPushesData) - it takes the form input values and sets to the firebase
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take tittle,shortNotification,longNotification,url
 *
 * @return  what does the function return
 *-this functional component doesnot  return anything
 ****************************************************************************
 */

export async function setPushesData(title, shortNotif, longNotif, url) {
  if (
    title === null ||
    shortNotif === null ||
    longNotif === null ||
    url === null
  ) {
  }
  try {
    const dataRef = await firebase
      .firestore()
      .collection("Pushes")
      .doc();
    dataRef.set({
      title,
      shortNotif,
      longNotif,
      imageUrl: url
    });
  } catch (e) {
    console.log(e);
  }
}

const userHash = "2F2C-F7C7-C494-A1AA";
const getSubLocationUrl = "https://api.navigine.com/sublocations/get";
const addBeaconUrl = "https://api.navigine.com/beacon/add";

export async function getSubLocationInfo(subId) {
  const res = await axios({
    url: `${getSubLocationUrl}?userHash=${userHash}&sublocationId=${subId}`,
    method: "GET"
  });
  return res.data;
}

export async function addBeacon(subId, beacon, beaconPoint) {
  const data = {
    description: beacon.uuid,
    uuid: beacon.uuid,
    major: beacon.max,
    minor: beacon.min,
    kx: beaconPoint.kx,
    ky: beaconPoint.ky,
    pw: -2,
    isUsed: 0,
    sublocationId: subId,
    powerNav: -72
  };

  const res = await axios({
    url: `${addBeaconUrl}?userHash=${userHash}`,
    method: "POST",
    data
  });

  return res.data;
}

export async function getRealTimeUserData(locationId, subLocationId, onChange) {
  firebase
    .database()
    .ref()
    .child("realtime_locations")
    .child(locationId)
    .child(subLocationId)
    .on("value", onChange);
}

export async function getUserInfo(uid) {
  const snapshot = await firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .get();
  return snapshot.data();
}

export {
  setLocationData,
  updateLocationData,
  deleteLocation,
  getLocationData,
  setSubLocationData,
  getSubLocationData,
  deleteSubLocation,
  uploadTaskPromise,
  updateSubLocation,
  getRouteData,
  setRouteData,
  deleteRoute,
  assignBeacon,
  getBeacons,
  getBeaconHeader,
  getBeaconEddy,
  getBeaconIbeacon,
  getUnassignedBeacons,
  getAssignedBeacons,
  unassignBeacon,
  getBeaconConfig,
  setCampaigns,
  addUser,
  getCampaigns,
  deleteCampaign,
  getUser,
  updateCampaign,
  getAdministrator,
  getNotificationData,
  resetPassword,
  deleteUser,
  disableUser,
  enableUser,
  deleteNotification
};
