/* eslint-disable import/prefer-default-export */
import firebase from "firebase";

export async function assignBeacon(
  uuid,
  locId,
  navLocationId,
  navSublocationId,
  navBeaconId
) {
  if (uuid === null || locId === null) {
    throw Error("error");
  }
  try {
    const snapshot = await firebase
      .firestore()
      .collection("beacons")
      .where("uuid", "==", uuid)
      .get();

    snapshot.forEach(doc => {
      firebase
        .firestore()
        .collection("beacons")
        .doc(doc.id)
        .update({
          locationId: locId,
          navLocationId: navLocationId || null,
          navSublocationId: navSublocationId || null,
          navBeaconId: navBeaconId || null,
          assigned: true
        });
    });

    return true;
  } catch (e) {
    throw Error(e);
  }
}
export async function unassignBeacon(uuid) {
  if (uuid === null) {
    throw Error("select beaon");
  }
  try {
    const snapshot = await firebase
      .firestore()
      .collection("beacons")
      .where("uuid", "==", uuid)
      .get();

    snapshot.forEach(doc => {
      firebase
        .firestore()
        .collection("beacons")
        .doc(doc.id)
        .update({
          locationId: null,
          navLocationId: null,
          navBeaconId: null,
          navSublocationId: null,
          assigned: false
        });
    });
    const campaign = await firebase
      .firestore()
      .collection("campaigns")
      .where("uuid", "==", uuid)
      .get();

    campaign.forEach(async doc => {
      await firebase
        .firestore()
        .collection("campaigns")
        .doc(doc.id)
        .update({
          uuid: ""
        });
    });

    return uuid;
  } catch (e) {
    throw Error("error ");
  }
}

/*
 ****************************************************************************
 * @brief  (fucntion getBeacons) - gives all the beacons assigned to current user
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component takes companyKey of the user
 *
 * @return  what does the function return
 *-this functional component  returns all the beacons assigned to the user
 ****************************************************************************
 */

// getting all the beacons assigned to the user
export async function getBeacons(key) {
  const beacons = [];

  if (!key) {
    return null;
  }
  try {
    const snapshot = await firebase
      .firestore()
      .collection("beacons")
      .where("companyKey", "==", key)
      .orderBy("name")
      .get();

    snapshot.forEach(doc => {
      beacons.push({
        min: doc.data().min,
        max: doc.data().max,
        uuid: doc.data().uuid,
        assigned: doc.data().assigned,
        id: doc.data().locationId,
        name: doc.data().name,
        navLocationId: doc.data().navLocationId,
        navSublocationId: doc.data().navSublocationId,
        navBeaconId: doc.data().navBeaconId
      });
    });
  } catch (e) {
    throw Error(e);
  }

  return beacons;
}

export async function getBeaconConfig(key, uid) {
  let config;

  if (!key) {
    return null;
  }
  try {
    const snapshot = await firebase
      .firestore()
      .collection("beacons")
      .doc(uid)
      .collection("configuration")
      .doc("configParameters");
    // .get();
    config = await snapshot.get();
    return config.data();
  } catch (e) {
    throw Error(e);
  }
}
export async function getBeaconEddy(key, uid) {
  let eddy;

  if (!key) {
    return null;
  }
  try {
    const snapshot = await firebase
      .firestore()
      .collection("beacons")
      .doc(uid)
      .collection("configuration")
      .doc("eddystoneParameters");
    // .get();
    eddy = await snapshot.get();
    return eddy.data();
  } catch (e) {
    throw Error(e);
  }
}
export async function getBeaconHeader(key) {
  let header;

  if (!key) {
    return null;
  }
  try {
    const snapshot = await firebase
      .firestore()
      .collection("beacons")
      .doc("4245434e-1001-0011-1011-101010101010")
      .collection("configuration")
      .doc("headers");

    header = await snapshot.get();
    return header.data();
  } catch (e) {
    throw Error(e);
  }
}

export async function getBeaconIbeacon(key, uid) {
  let ibeacon;

  if (!key) {
    return null;
  }
  try {
    const snapshot = await firebase
      .firestore()
      .collection("beacons")
      .doc(uid)
      .collection("configuration")
      .doc("iBeaconParameters");
    // .get();
    ibeacon = await snapshot.get();
    return ibeacon.data();
  } catch (e) {
    throw Error(e);
  }
}

// getting unassigned beacons
export async function getUnassignedBeacons(key) {
  const beacons = [];
  if (!key) {
    return null;
  }
  try {
    const snapshot = await firebase
      .firestore()
      .collection("beacons")
      .where("companyKey", "==", key)
      .where("assigned", "==", false)
      .orderBy("name")
      .get();
    snapshot.forEach(doc => {
      beacons.push({
        min: doc.data().min,
        max: doc.data().max,
        uuid: doc.data().uuid,
        assigned: doc.data().assigned,
        name: doc.data().name,
        id: doc.data().locationId,
        navLocationId: doc.data().navLocationId,
        navSublocationId: doc.data().navSublocationId,
        navBeaconId: doc.data().navBeaconId
      });
    });
  } catch (e) {
    throw Error(e);
  }
  console.log(beacons);
  return beacons;
}
// getting unassigned beacons
export async function getAssignedBeacons(locationId) {
  const beacons = [];

  try {
    const snapshot = await firebase
      .firestore()
      .collection("beacons")
      .where("locationId", "==", locationId)
      .orderBy("name")
      .get();

    snapshot.forEach(doc => {
      beacons.push({
        min: doc.data().min,
        max: doc.data().max,
        uuid: doc.data().uuid,
        assigned: doc.data().assigned,
        id: doc.data().locationId,
        name: doc.data().name,
        navLocationId: doc.data().navLocationId,
        navSublocationId: doc.data().navSublocationId,
        navBeaconId: doc.data().navBeaconId
      });
    });
  } catch (e) {
    throw Error(e);
  }

  return beacons;
}
