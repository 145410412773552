/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import sample from "./beaconDashbordImage.png";
import {
  getCurrentUser,
  getCurrentCompanyKey,
  getNotificationData,
  getUser,
  getLocationData,
  getCampaigns
} from "../../../controllers/firebase";
import "./Dashboard.css";

/*
 ****************************************************************************
 * @brief  (fucntion name)      brief description of the fucntion
 *
 * @see link of the function if applicable
 *
 * @param{type : stringn,array .. etc} (paramter name)   parameter definition
 *
 * @return  what does the function return
 *
 ****************************************************************************
 */

/*
Declearing the functional component 
*/
function NotificationsDisplay() {
  // variable to store the notification array fetched from firebase
  const [notifications, setNotifications] = useState();
  // variable to store company key fetched from firebase
  const [companyKey, setCompanyKey] = useState();
  const [users, setUsers] = useState();
  const [locations, setLocations] = useState();
  const [campaigns, setCampaigns] = useState();

  useEffect(() => {
    getCurrentUser(u => {
      if (u)
        getCurrentCompanyKey().then(e => {
          setCompanyKey(e);
          getNotData(e);
          getUsers(e);
          getLocations(e);
        });
    });
  }, []);

  const getNotData = async key => {
    await getNotificationData(key).then(array => {
      setNotifications([...array]);
    });
    // setNotifications(data);
  };
  // const getTime = t => {
  //   // return new Date(t * 1e3).toISOString().slice(-13, -5);

  //   const d = new Date(t);
  //   return d;
  // };
  const getUsers = async key => {
    await getUser(key).then(array => {
      setUsers(array);
    });
  };
  const getLocations = async key => {
    await getLocationData(key).then(array => {
      setLocations(array);
    });
    getCampaigns(key).then(array => {
      setCampaigns(array);
    });
  };
  const getLocName = id => {
    let name = "";
    campaigns &&
      campaigns.forEach(c => {
        if (c.key === id) {
          locations &&
            locations.forEach(l => {
              if (c.locationId === l.id) {
                name = l.locationName;
              }
            });
        }
      });

    return name;
  };
  const getUserName = uid => {
    const name =
      users &&
      users.map(u => {
        if (u.uid === uid) {
          return u.name;
        }
        return "";
      });

    return name;
  };
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography id="notificationstypographytitle">Notifications</Typography>
      </Grid>
      <Grid item xs={6}>
        <Avatar id="avatarstyle">
          {notifications && notifications.length >= 100
            ? "99+"
            : notifications && notifications.length}
        </Avatar>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          maxHeight: "55vh",
          overflow: "auto"
        }}
      >
        {notifications &&
          notifications.map((n, index) => {
            return (
              <Paper id="notificationpaperstyle" key={index}>
                <Grid container style={{ marginBottom: "4%" }}>
                  <Grid item xs={2}>
                    <img src={sample} alt="noImage" id="notificationimgstyle" />
                  </Grid>
                  <Grid item xs>
                    <Typography id="notificationtypographystyle">
                      <i> Notification for </i> {n.title} campaign
                    </Typography>
                    <Typography class="notificationinfo">
                      <span style={{ color: "#29ABE2" }}>
                        Has been generated at:
                      </span>{" "}
                      {getLocName(n.campaignId)}
                    </Typography>

                    <Typography class="notificationinfo">
                      <span style={{ color: "#29ABE2" }}> To the user: </span>
                      {getUserName(n.userId)}
                    </Typography>
                    <Typography class="notificationinfo">
                      <span style={{ color: "#29ABE2" }}> Time and Date: </span>
                      {new Date(n.timestamp).toString().slice(0, 25)}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
      </Grid>
    </Grid>
  );
}

/*
Validating React Component Props
*/
NotificationsDisplay.propTypes = {
  history: PropTypes.object.isRequired
};

export default NotificationsDisplay;
