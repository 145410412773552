/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Typography
} from "@material-ui/core";
import "./Location.css";
import {
  assignBeacon,
  getCurrentCompanyKey,
  getCurrentUser,
  getUnassignedBeacons,
  getAssignedBeacons,
  unassignBeacon
} from "../../../controllers/firebase";
import PropTypes from "prop-types";
import { ValidateDropDown } from "../../../helpers/validators";

/*
Declearing the functional component 
*/
function AssignUnassignDialogBox(props) {
  // tp store the selected proximity
  const [assignedBeacon, setAssignedBeacon] = useState("");
  // variable used to store beacon value fetched from firebase
  const [beacons, setBeacons] = useState();
  const [unAssignedBeacons, setUnassignedBeacons] = useState("");
  // const [beacons, setBeacons] = useState("");
  const [selectedBeacon, setSelectedBeacon] = useState("");
  const [open, setOpen] = useState(false);
  const [companyKey, setCompanyKey] = useState();
  const [errorBeacon, setErrorBeacon] = useState(false);

  useEffect(() => {
    initData();
    //eslint-disable-next-line
  }, []);

  function initData() {
    getCurrentUser(u => {
      //WARN: why do getCurrentUser if not use the user object
      getCurrentCompanyKey().then(k => {
        setCompanyKey(k);
        getUnABeacon(k);
        getABeacon(k);
      });
    });
  }

  const getUnABeacon = async k => {
    const array = await getUnassignedBeacons(k);
    setUnassignedBeacons([...array]);
  };

  function handleClickOpen() {
    initData();
    setSelectedBeacon("");
    setUnassignedBeacons("");
    setOpen(true);
  }

  function handleClose() {
    setErrorBeacon(false);
    setOpen(false);
  }

  // to set proximity value
  const handleAssignBeaconChange = e => {
    setAssignedBeacon(e.target.value);
  };

  const handleAssign = async () => {
    if (ValidateDropDown(assignedBeacon)) {
      await assignBeacon(assignedBeacon, props.loc.id);
      await getUnABeacon(companyKey);
      await getABeacon(companyKey);
      props.updateBeacon();
      setErrorBeacon(false);
      setOpen(false);
    } else {
      setErrorBeacon(true);
    }
  };

  const getABeacon = async k => {
    const array = await getAssignedBeacons(props.loc.id);
    await setBeacons([...array]);
  };

  // to set proximity value
  const handleBeaconChange = e => {
    setSelectedBeacon(e.target.value);
  };

  const handleBeacon = async () => {
    if (ValidateDropDown(selectedBeacon)) {
      await unassignBeacon(selectedBeacon);
      await getABeacon(companyKey);
      await getUnABeacon(companyKey);
      props.updateBeacon();
      setErrorBeacon(false);
      setOpen(false);
    } else {
      setErrorBeacon(true);
    }
  };

  // Add necessary comments in the return
  return (
    <div>
      {props.switch === 0 ? (
        <React.Fragment>
          {" "}
          <Button
            disabled={
              props.loc.selectedType === "indoorNavigation" ||
              props.loc.selectedType === "sublocationBased"
            }
            variant="outlined"
            color="primary"
            onClick={handleClickOpen}
          >
            Assign beacon
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            saria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <Typography class="title">Assign Beacon</Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography class="content">
                  The assigned beacons to {props.loc.location} are :
                </Typography>
                {errorBeacon && (
                  <span className="errormessage"> please select a Beacon</span>
                )}
              </DialogContentText>
              <FormControl fullWidth>
                <InputLabel htmlFor="beacon-simple">Beacon</InputLabel>
                <Select
                  value={assignedBeacon}
                  onChange={handleAssignBeaconChange}
                  error={errorBeacon}
                  variant="outlined"
                  inputProps={{
                    name: "beacon",
                    id: "beacon-simple"
                  }}
                >
                  {unAssignedBeacons &&
                    unAssignedBeacons.map((b, index) => {
                      return (
                        <MenuItem value={b.uuid} key={index}>
                          {b.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleAssign}
                variant="contained"
                color="primary"
                style={{ color: "white" }}
              >
                assign
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      ) : (
        <div>
          <Button
            disabled={
              props.loc.selectedType === "indoorNavigation" ||
              props.loc.selectedType === "sublocationBased"
            }
            variant="outlined"
            color="primary"
            onClick={handleClickOpen}
          >
            unAssign beacon
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {" "}
              <Typography class="title">Unassign Beacon</Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography class="content">
                  The assigned beacons to {props.loc.location} are :
                </Typography>
              </DialogContentText>
              <DialogContentText>
                <Typography class="content">
                  Please select beacon to unassign
                </Typography>
                {errorBeacon && (
                  <span className="errormessage"> please select a Beacon</span>
                )}
              </DialogContentText>
              {beacons && (
                <FormControl fullWidth>
                  <InputLabel htmlFor="select-multiple">Beacon</InputLabel>
                  <Select
                    value={selectedBeacon}
                    onChange={handleBeaconChange}
                    error={errorBeacon}
                    variant="outlined"
                    inputProps={{
                      name: "beacon",
                      id: "beacon-simple"
                    }}
                  >
                    {beacons &&
                      beacons.map((beacon, index) => (
                        <MenuItem value={beacon.uuid} key={index}>
                          {beacon.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleBeacon}
                variant="contained"
                color="primary"
                style={{ color: "white" }}
              >
                unAssign
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
}

/*
Validating React Component Props
*/
AssignUnassignDialogBox.propTypes = {
  loc: PropTypes.object.isRequired
};

export default AssignUnassignDialogBox;
