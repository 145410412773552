/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-indent */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {
  getCurrentUser, // get current user from firebase
  getCurrentCompanyKey, // get current company key of signed-in user
  unassignBeacon,
  getAssignedBeacons, // get beacons assigned to particular location with firebase
  deleteLocation, // update location data to firebase
  deleteCampaign,
  deleteNotification,
  getCampaigns
} from "../../../controllers/firebase";
import PropTypes from "prop-types";
import { navigineDeleteLocation } from "../../../controllers/navigine_locations";

/*
Declearing the functional component 
*/
function DeleteLocationDialogBox(props) {
  // variable to handle open /close dialog box
  const [open, setOpen] = useState(false);

  // variable to store locname
  const [locationId, setlocationId] = useState();
  const [beacons, setBeacons] = useState();
  const [campaigns, setCampaigns] = useState();

  useEffect(() => {
    // method to intialize all the variables which are fecthed from firebase
    getCurrentUser(u => {
      //WARN: why do getCurrentUser if not use the user object

      getCurrentCompanyKey().then(e => {
        getBeacon(e);
        getCampaignData(e);
      });
    });
    //eslint-disable-next-line
  }, []);

  const getBeacon = key => {
    getAssignedBeacons(props.loc.id).then(array => {
      setBeacons([...array]);
    });
  };
  const getCampaignData = cKey => {
    getCampaigns(cKey).then(array => {
      setCampaigns(array);
    });
  };

  // method to open the dialog box
  const handleClickOpen = loc => {
    setOpen(true);

    setlocationId(loc.id);
  };

  // method to close the dialog box
  const handleClose = () => {
    setOpen(false);
  };

  // method to handle submit of edit button
  const handleDelete = async () => {
    if (props.beacons) {
      props.beacons.map(async b => {
        if (b.assigned) {
          if (b.id === props.loc.id) {
            await unassignBeacon(b.uuid);
          }
        }
      });
    }

    campaigns &&
      campaigns.forEach(campaign => {
        if (campaign.locationId === props.loc.id) {
          deleteCampaign(campaign.key);
          deleteNotification(campaign.key);
        }
      });

    if (props.loc.selectedType === "locationBased") {
      await deleteLocation(locationId);
    } else {
      await navigineDeleteLocation(props.loc.navLocationId);
      await deleteLocation(locationId);
    }

    props.updateLocation();

    setOpen(false);
  };
  // Add necessary comments in the return
  return (
    <div>
      <DeleteForeverIcon
        id="deleteicon"
        onClick={() => handleClickOpen(props.loc)}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography class="title">Delete Location</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography class="content">
              Are you sure you want to delete this {props.loc.locationName} ?,If
              there are any beacons assigned to this location they will get
              unassigned.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="primary"
            style={{ color: "white" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

/*
Validating React Component Props
*/
DeleteLocationDialogBox.propTypes = {
  loc: PropTypes.object.isRequired,
  updateLocation: PropTypes.func.isRequired
};

export default DeleteLocationDialogBox;
