/* eslint-disable react/no-array-index-key */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import { Grid } from "@material-ui/core";
import {
  getCurrentUser, // get current user from firebase
  getCurrentCompanyKey, // get current company key of signed-in user
  getLocationData // get location data of user
} from "../../../controllers/firebase";
import "./Dashboard.css";

function Map() {
  // variables to store location values fetched from firebase
  const [locations, setLocations] = useState();
  // variable to store company key fetched from firebase
  const [companyKey, setCompanyKey] = useState();
  // variable to store the userid fetched from firebase
  const [uid, setUid] = useState();

  const [location, setLocation] = useState(false);
  const [locationInfo, setLocationInfo] = useState();
  // here get the lat and lng from firebase
  useEffect(() => {
    // method to intialize all the variables which are fecthed from firebase
    getCurrentUser(u => {
      if (u) {
        setUid(u.uid);
        getCurrentCompanyKey().then(e => {
          setCompanyKey(e);
          getLocData(e);
        });
      }
    });
  }, []);
  // getting location data from firebase
  const getLocData = cKey => {
    getLocationData(cKey).then(array => {
      if (array.length === 0) {
        setLocation(false);
      } else {
        setLocations(array);
        setLocation(true);
      }
    });
  };

  return (
    <>
      {!location ? (
        <GoogleMap
          defaultZoom={10}
          defaultCenter={{
            lat: 45.4215,
            lng: -75.6972
          }}
        />
      ) : (
        <GoogleMap
          defaultZoom={10}
          defaultCenter={{
            lat: locations && locations[0].lat,
            lng: locations && locations[0].lng
          }}
        >
          {locations &&
            locations.map((loc, index) => {
              return (
                <React.Fragment key={index}>
                  <Marker
                    key={index}
                    position={{ lat: loc.lat, lng: loc.lng }}
                    //onClick={onMarkerClick}
                    onClick={() => {
                      setLocationInfo(loc);
                    }}
                  />
                </React.Fragment>
              );
            })}
          {locationInfo && (
            <InfoWindow
              position={{ lat: locationInfo.lat, lng: locationInfo.lng }}
              onCloseClick={() => {
                setLocationInfo(null);
              }}
            >
              <div>
                <h3>{locationInfo.locationName}</h3>
                <p>{locationInfo.address}</p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </>
  );
}
/*
AIzaSyAPH3BAwgBPEi_sSWNMswf6Ui3eR7w4SCo

*/

const WrappedMap = withScriptjs(withGoogleMap(Map));

function MapDisplay() {
  // Add necessary comments in the return
  return (
    <Grid id="mapgrid">
      <WrappedMap
        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAPH3BAwgBPEi_sSWNMswf6Ui3eR7w4SCo"
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ height: "100%" }} />}
      />
    </Grid>
  );
}

/*
Validating React Component Props
*/
MapDisplay.propTypes = {};

export default MapDisplay;
