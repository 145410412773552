/* eslint-disable import/prefer-default-export */
import firebase from "firebase";
/*
 ****************************************************************************
 * @brief  (fucntion getNotificationData) - gets the notification details from firebase of
 * that particular company
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take companyKey
 *
 * @return  what does the function return
 *-this functional component returns promise
 ****************************************************************************
 */
export async function getNotificationData(companyKey) {
  try {
    const notifications = [];
    const snapshot = await firebase
      .firestore()
      .collection("receiveNotifications")
      .where("companyKey", "==", companyKey)
      .orderBy("timestamp", "desc")
      .limit(100)
      .get();

    snapshot.forEach(doc => {
      notifications.push({
        campaignId: doc.data().campaignId,
        content: doc.data().content,
        timestamp: doc.data().timestamp,
        title: doc.data().title,
        userId: doc.data().uid
      });
    });
    return notifications;
  } catch (e) {
    throw Error;
  }
}

export async function deleteNotification(campaignId) {
  const snapshot = await firebase
    .firestore()
    .collection("receiveNotifications")
    .where("campaignId", "==", campaignId)
    .get();

  snapshot.docs.forEach(async doc => {
    await doc.ref.delete();
  });
}
