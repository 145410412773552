/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: Locations.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Abhishek Kudikala (abhishek@snowm.io)
 *
 *  Date created: 21-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import axios from "axios";

const userHash = "2F2C-F7C7-C494-A1AA";
// const url = [
//   "https://node-api.navigine.com/locations/getAll?",
//   "https://node-api.navigine.com/locations/add?",
//   "https://node-api.navigine.com/locations/edit?",
//   "https://node-api.navigine.com/locations/delete?",
//   "https://node-api.navigine.com/locations/get?"
// ];

// const newendpoint = "https://node-api.navigine.com/locations/delete?";
const endpoint = "https://node-api.navigine.com/locations/";

/*
 ****************************************************************************
 * @brief  (snowmGetAllLocations)      this function gives all the created location
 *
 * @see link of the function if applicable
 *
 * @param{} ()
 *
 * @return  {type: array}  returns "AllLocations" generated by navigine.
 *
 ****************************************************************************
 */
export async function snowmGetAllLocation() {
  let AllLocations = {};
  await axios.post(`${endpoint}list?userHash=${userHash}`).then(res => {
    const { locations } = res.data;
    AllLocations = locations;
  });

  return AllLocations;
}

/*
 ****************************************************************************
 * @brief  (snowmAddLocation)      this function creates a new location
 *
 * @see link of the function if applicable
 *
 * @param{type : string} (locationName)   takes the user entered name of the location
 *
 * @return  returns "locationId" generated by navigine.
 *
 ****************************************************************************
 */

export async function navigineAddLocation(locationName) {
  const data = {
    title: locationName,
    description: null,
    publicLocation: 0,
    navigation_config_id: 3
  };
  const res = await axios.post(`${endpoint}add?userHash=${userHash}`, data);

  // return locationID

  return res.data.location.id;
}

/*
 ****************************************************************************
 * @brief  (snowmEditLocation)      this function creates a new location
 *
 * @see link of the function if applicable
 *
 * @param{type : string, string} (NewlocationName, Locid)   takes the user entered NEW name of the location and its ID
 *
 * @return  returns "title" which is the new changed name.
 *
 ****************************************************************************
 */
export async function navigineEditLocation(newlocationName, locId) {
  const data = { title: newlocationName, description: null, publicLocation: 0 };
  const res = await axios.post(
    `${endpoint}edit?userHash=${userHash}&locationId=${locId}`,
    data
  );

  return res.data.location.title;
}

/*
 ****************************************************************************
 * @brief  (snowmDeleteLocation)      this function deletes a location
 *
 * @see link of the function if applicable
 *
 * @param{type : string} (Locid)   takes the locationID of the one which the user wants to delete.
 *
 * @return{type : boolean}  returns "locationDelete" confirmation of deleting
 *
 ****************************************************************************
 */
export async function navigineDeleteLocation(Locid) {
  const locationId = Locid;
  const data = {
    password: "Abc@123"
  };

  await axios
    .post(
      `${endpoint}/delete?userHash=${userHash}&locationId=${locationId}`,
      data
    )
    .then(res => {
      return true;
    });
}

/*
 ****************************************************************************
 * @brief  (snowmGetLocationInfo)      this function gives information of a particular location
 *
 * @see link of the function if applicable
 *
 * @param{type : string} (Locid)   takes the locationID of the one which the user wants to get info.
 *
 * @return{type : array}  returns "data" having fetched info
 *
 ****************************************************************************
 */

export async function snowmGetLocationInfo(locid) {
  const data = [];
  const locationID = locid;
  await axios
    .get(`${endpoint}/get?userHash=${userHash}&locationId=${locationID}`)
    .then(res => {
      if (res.data.response === "ok") {
        data.push(res.data.location);
      }
    });
  return data;
}
