/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_login.jsx
 *
 *  Description: Login component.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import {
  Button,
  Card,
  CircularProgress,
  Link,
  TextField,
  Typography
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import React, { useState } from "react";
import Styled from "styled-components";
import { primaryDark } from "../../styles/theme";
import { loginWithEmailPassword } from "../../controllers/firebase";
import { ValidateEmail } from "../../helpers/validators";

// functional component
const Login = props => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    email: {
      value: "",
      error: false
    },
    password: {
      value: "",
      error: false
    },
    error: null
  });

  /*
   ****************************************************************************
   * @brief  (attemptLogin)      tries to login with the current credentials in the state
   * @param{type : object } (e)
   *
   * @returns undefined
   ****************************************************************************
   */
  const attemptLogin = e => {
    if (ValidateEmail(form.email.value) && form.password.value !== "") {
      setLoading(true);
      loginWithEmailPassword(form.email.value, form.password.value).catch(
        err => {
          // eslint-disable-next-line no-alert
          window.alert(err.message);
          setLoading(false);
        }
      );
    }
    setForm({ ...form, error: "Please check errors" });
  };

  /*
   ****************************************************************************
   * @brief  (handleInput)     called each time an input value is changed in
   * the form, makes appropriate changes in state when a value in input field
   * is changed.
   * @param{type : event } (event)
   *
   * @returns undefined
   ****************************************************************************
   */
  const handleInput = event => {
    let error = false;
    if (event.target.name === "email") {
      error = !ValidateEmail(event.target.value);
    }
    setForm({
      ...form,
      [event.target.name]: { value: event.target.value, error }
    });
  };

  // returns the content of Login page
  return (
    <>
      <Overlay>
        <StyledCard>
          <LogoImage src="https://static.wixstatic.com/media/dfaf54_a93f1233ad1b44e28dd07aa82c479593~mv2.png/v1/fill/w_280,h_109,al_c,lg_1,q_80/Final-Logo-01.webp" />
          <LoginForm {...props}>
            <StyledForm>
              <TextField
                fullWidth
                error={form.email.error}
                required
                label="Email"
                id="email"
                type="email"
                name="email"
                onChange={handleInput}
              />
              <Blank />
              <TextField
                error={form.password.error}
                required
                fullWidth
                label="Password"
                id="password"
                type="password"
                name="password"
                onChange={handleInput}
              />
              {/* <StyledTypography variant="subheading">
                By Logging in, you agree to the
                <br />
                <Link href="1" variant="subheading">
                  {"Terms of Service "}
                </Link>
                and our
                <br />
                <Link variant="subheading" href="2">
                  {" Privacy Policy"}
                </Link>
              </StyledTypography> */}
              <StyledButton
                variant="contained"
                justify="center"
                color="primary"
                onClick={() => {}}
              >
                Create SnowM Account
              </StyledButton>
              <RowCenterChild>
                {!loading && (
                  <StyledOutlineButton
                    variant="outlined"
                    justify="center"
                    color="primary"
                    type="submit"
                    onClick={attemptLogin}
                    {...props}
                  >
                    Login
                  </StyledOutlineButton>
                )}
                {loading && <CircularProgress size={20} />}
              </RowCenterChild>
            </StyledForm>
          </LoginForm>
          {/* <Link href="#" variant="body1">
            Forgot password?
          </Link> */}
        </StyledCard>
      </Overlay>
    </>
  );
};

export default withTheme()(Login);

const Overlay = Styled.div`
position:fixed;
top:0;
left:0;
height:100vh;
width:100vw;
justify-content:center;
display:flex;
background-color: ${primaryDark}
`;

const Blank = Styled.div`
  height:5px;
`;
const StyledCard = Styled(Card)`
margin:auto;
width:450px;
padding:10px;
padding:20px 0px;
&&{
border-radius:35px;
}
display:flex;
flex-direction:column;

@media (max-width: 450px) {
    width: 100%;
    &&{
    border-radius:0px;
    }
  }
`;

const LogoImage = Styled.img`
object-fit:cover;
width:290px;
align-self:center;
@media (max-width:450px){
  width:80vw;
  max-height:15vh;
  height:auto;
}
`;

const LoginForm = Styled.div`
  color:${props => props.theme.palette.primary.main}
  display: flex;
  flex: 1;
  justify-content: center;
  width:290px;
  margin:auto;
  flex-direction: column;
  @media (max-width:450px){
    width:80vw;
  }
`;

const StyledButton = Styled(Button)`
  padding:10px;
  && {
    margin-top: 10px;
    text-transform: none;
    color:white;
    border-radius:0px;
  }
`;

const StyledOutlineButton = Styled(Button)`
  &&{
    :hover {
    border-width:2px;
    }
    color:${props => props.theme.palette.grey.main}
    font-weight:bold;
    align-self:center;
    margin:15px;
    border-color:${props => props.theme.palette.primary.dark}
    border-width:2px;
    border-radius:0px;
    text-transform:none; 
    padding:0px 25px;
    }
`;

// const StyledTypography = Styled(Typography)`
// padding:15px;
// &&{
//   line-height:1;
//   letter-spacing:0.1;
// }
//   text-align:center;
//  `;

export const StyledForm = Styled.form`
  display: flex;
  flex-direction: column;
  height: 50%;
  padding:15px 0px;
  justify-content: space-evenly;
  @media (max-width:450px){
    width:100%;
  }
`;

export const RowCenterChild = Styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
`;
