/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect, useContext } from "react";
import { Paper, Grid, TextField, Typography } from "@material-ui/core";
import BeaconUserProfile from "../../../assets/images/BeaconUserProfile.png";
import {
  getCurrentUser,
  getAdministrator
} from "../../../controllers/firebase";
import { LocationContext } from "../../../contexts/LocationsContext";

const userLabelStyle = {
  fontSize: "100%"
};

/*
 ****************************************************************************
 * @brief  (fucntion name)      brief description of the fucntion
 *
 * @see link of the function if applicable
 *
 * @param{type : stringn,array .. etc} (paramter name)   parameter definition
 *
 * @return  what does the function return
 *
 ****************************************************************************
 */

/*
Declearing the functional component 
*/
function UserProfile() {
  const [user, setUser] = useState();
  const { subType } = useContext(LocationContext);

  // method to intialize all the variables which are fecthed from firebase
  useEffect(() => {
    getCurrentUser(u => {
      getUserDetails(u.uid);
    });
  }, []);

  const getUserDetails = async userid => {
    await getAdministrator(userid).then(details => {
      setUser(details);
    });
  };

  // Add necessary comments in the return
  return (
    <div>
      <Paper
        elevation="5"
        style={{
          height: "90%",
          width: "80%",
          marginLeft: "10%",
          marginTop: "5%"
        }}
      >
        {user && (
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="h4" style={{ margin: "3% 3% 3% 3%" }}>
                My Profile
              </Typography>
              <img
                style={{ marginLeft: "20%", marginBottom: "10%" }}
                src={BeaconUserProfile}
                alt=""
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginTop: "2%" }}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  label={
                    <Typography style={userLabelStyle}>Full Name</Typography>
                  }
                  value={user[0].name}
                  margin="normal"
                  style={{ width: "45%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label={<Typography style={userLabelStyle}>Email</Typography>}
                  value={user[0].email}
                  margin="normal"
                  style={{ width: "45%" }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography style={{ marginTop: "4%" }}>Services:</Typography>

                <Grid container>
                  {subType.locationBased === true && (
                    <Grid item xs={12}>
                      <TextField value="- Location Based" />
                    </Grid>
                  )}
                  {subType.sublocationBased === true && (
                    <Grid item xs={12}>
                      <TextField value="- Sub-location Based" />
                    </Grid>
                  )}

                  {subType.indoorNavigation === true && (
                    <Grid item xs={12}>
                      <TextField value="- Indoor Navigation" />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Paper>
    </div>
  );
}

/*
Validating React Component Props
*/
UserProfile.propTypes = {};

export default UserProfile;
