/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import {
  getCurrentUser, // get current user from firebase
  getCurrentCompanyKey, // get current company key of signed-in user
  getBeaconConfig
} from "../../../controllers/firebase";

import "./Beacon.css";

/*
Declearing the functional component 
*/

function TelemetryPackets(props) {
  const [config, setConfig] = useState("");
  const txPower = ["-40", "-20", "-16", "-12", "-8", "-4", " 0", "4", "8"];

  let advIntervals = new Map([
    ["00000", "100"],
    ["00001", "150"],
    ["00002", "360"],
    ["00003", "500"],
    ["00004", "1000"],
    ["00005", "1500"],
    ["00004", "2000"],
    ["00005", "5000"]
  ]);

  let range = new Map([
    ["8", "140m-150m"],
    ["4", "100m-110m"],
    ["0", "70m-100m"],
    ["-4", "50m-60m"],
    ["-8", "40m-50m"],
    ["-12", "20m-25m"],
    ["-20", "7m-15m"],
    ["-40", "1m-5m"]
  ]);

  useEffect(() => {
    // method to intialize all the variables which are fecthed from firebase
    getCurrentUser(u => {
      if (u)
        getCurrentCompanyKey(u.uid).then(e => {
          getBeacon(e);
        });
    });
  }, []);
  const getBeacon = key => {
    getBeaconConfig(key, props.beaconData.uuid).then(obj => {
      setConfig(obj);
    });
  };

  // Add necessary comments in the return
  return (
    <Grid style={{ marginBottom: "15px" }}>
      <Paper elevation="5" style={{ paddingBottom: "10px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} id="title">
            SNOWM TELEMETRY PACKETS{" "}
          </Grid>

          <Grid item xs={6} id="gridstyle">
            <Typography>
              <span id="field">UUID:</span>
              <span id="uidtelemetry">{config.UID}</span>
            </Typography>
            <Typography>
              <span id="field">Major:</span>{" "}
              <span id="majortelemetry"> {config.MAJ}</span>
            </Typography>
            <Typography>
              <span id="field">Minor:</span>{" "}
              <span id="minortelemetry"> {config.MIN}</span>
            </Typography>
            <Typography>
              <span id="field">TX Power:</span>{" "}
              <span id="txtelemetry"> {txPower[config.TXP]} dBm</span>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <span id="field">Maximum Range: </span>
              <span id="maxrangetelemetry">
                {range.get(txPower[config.TXP])}
              </span>
            </Typography>
            <Typography>
              <span id="field">Advertising Interval: </span>
              <span id="sublocationinfo">
                {advIntervals.get(config.ADI)} ms
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

/*
Validating React Component Props
*/
TelemetryPackets.propTypes = {};

export default TelemetryPackets;
