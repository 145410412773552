/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-indent */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import PropTypes from "prop-types";
import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from "@material-ui/core";
import "./Location.css";

import {
  getCurrentUser /* Get current user from Firebase */,
  getLocationData, // get location data from firebase
  getCurrentCompanyKey, // get companykey of current user
  updateLocationData // update location data to firebase
} from "../../../controllers/firebase";
import { navigineEditLocation } from "../../../controllers/navigine_locations";
import { ValidateLocationName } from "../../../helpers/validators";
let check = false;
let addressCheck = false;

/*
Declearing the functional component 
*/
function EditLocationDialogBox(props) {
  // variable to handle open /close dialog box
  const [open, setOpen] = useState(false);

  // variable to store locname
  const [id, setId] = useState();
  // variable to store locname
  const [locName, setLocName] = useState();
  // state that holds the address
  const [address, setAddress] = useState();

  // state to show loading component
  const [loading, setLoading] = useState(false);

  // state that holds lng and lat
  const [coordinates, setCoordinates] = useState({
    lat: props.loc.lat,
    lng: props.loc.lng
  });

  // variable to store location values fetched from the firebase
  const [locations, setLocations] = useState(null);
  // variable to store company key fetched from firebase
  const [companyKey, setCompanyKey] = useState();
  // variable to store the userid fetched from firebase
  const [uid, setUid] = useState();
  // state that holds the address suggestions
  const [suggestions, setsuggestions] = useState([""]);

  const [errorsForm, setErrorsForm] = useState({
    locName: {
      status: false,
      message: "16 chars , dont leave it empty"
    }
  });
  // method to intialize all the variables which are fecthed from firebase
  useEffect(() => {
    init();
    //eslint-disable-next-line
  }, []);

  const init = () => {
    getCurrentUser(u => {
      setUid(u.uid);
      getCurrentCompanyKey().then(e => {
        setCompanyKey(e);
        getLocData(e);
      });
    });
  };
  // getting location data from firebase
  const getLocData = cKey => {
    getLocationData(cKey).then(array => {
      setLocations(array);
    });
  };
  // take all the address suggestions
  const handleSuggestions = sug => {
    setsuggestions(sug);
  };
  // method to open the dialog box
  const handleClickOpen = loc => {
    setOpen(true);
    init();
    setLocName(loc.locationName);
    setAddress(loc.address);
    setId(loc.id);
  };

  const handleChangeAddress = add => {
    setAddress(add);
    geocodeByAddress(add)
      .then(results => getLatLng(results[0]))
      .then(latLng => setCoordinates(latLng))
      .catch(error => console.error("Error", error));
  };

  // method to the handle the new locationname
  const handleChange = e => {
    if (ValidateLocationName(e.target.value)) {
      setLocName(e.target.value);
      setErrorsForm({
        ...errorsForm,
        locName: { status: false, message: "16 chars , dont leave it empty" }
      });
    } else {
      setErrorsForm({
        ...errorsForm,
        locName: { status: true, message: "16 chars , dont leave it empty" }
      });
    }
    setLocName(e.target.value);
  };
  // method to close the dialog box
  const handleClose = () => {
    setOpen(false);
  };

  // method to handle submit of edit button
  const handleEdit = async () => {
    check = false;

    suggestions.forEach(sug => {
      if (sug.description === address || address === props.loc.address) {
        addressCheck = true;
      }
    });
    locations &&
      locations.forEach(location => {
        if (
          location.locationName.trim().toLowerCase() ===
            locName.trim().toLowerCase() &&
          location.locationName.trim().toLowerCase() !==
            props.loc.locationName.trim().toLowerCase()
        ) {
          check = true;
        }
      });

    if (!errorsForm.locName.status && !check && addressCheck) {
      setLoading(true);
      //checked changed from == to ===
      if (props.loc.selectedType === "locationBased") {
        await updateLocationData(
          id,
          locName,
          address,
          coordinates.lat,
          coordinates.lng
        );
      } else {
        await navigineEditLocation(locName, props.loc.navLocationId);
        await updateLocationData(
          id,
          locName,
          address,
          coordinates.lat,
          coordinates.lng
        );
      }

      setLoading(false);
      props.updateLocation();
      setOpen(false);
    } else {
      alert("please enter valid data");
    }
  };
  // Add necessary comments in the return
  return (
    <div>
      <EditTwoToneIcon
        id="editicon"
        onClick={() => handleClickOpen(props.loc)}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {" "}
          <Typography class="title">Edit Location</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography class="content">
              Edit the lcoation name : {props.loc.locationName}
            </Typography>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Location Name"
            type="name"
            value={locName}
            error={errorsForm.locName.status}
            helperText={errorsForm.locName.status && errorsForm.locName.message}
            variant="outlined"
            name="locName"
            fullWidth
            onChange={handleChange}
          />
          <PlacesAutocomplete
            value={address}
            onChange={handleChangeAddress}
            // onSelect={handleSelect}
            onClick={handleChange}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, load }) => (
              <div>
                <TextField
                  id="outlined-name"
                  label="Address"
                  // className={classes.textField}
                  // value={address}
                  name="name"
                  // onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                  style={{
                    width: 400
                    // marginLeft: "15%"
                  }}
                  {...getInputProps({
                    placeholder: "Search Places ...",
                    className: "location-search-input"
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {load && (
                    <div
                      style={{
                        width: "400px",

                        paddingLeft: "10px"
                      }}
                    >
                      Loading...
                    </div>
                  )}
                  {suggestions.map((suggestion, index) => {
                    handleSuggestions(suggestions);

                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? {
                          width: "400px",
                          // marginLeft: "50px",
                          // paddingLeft: "70px",
                          backgroundColor: "#fafafa",
                          cursor: "pointer"
                        }
                      : {
                          width: "400px",
                          // marginLeft: "50px",
                          // paddingLeft: "70px",
                          backgroundColor: "#ffffff",
                          cursor: "pointer"
                        };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style
                        })}
                        key={index}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleEdit}
            variant="contained"
            color="primary"
            style={{ color: "white" }}
          >
            {loading ? <CircularProgress color="white" /> : "EDIT"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

/*
Validating React Component Props
*/
EditLocationDialogBox.propTypes = {
  loc: PropTypes.object.isRequired,
  updateLocation: PropTypes.func.isRequired
};

export default EditLocationDialogBox;
