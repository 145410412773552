import React from "react";
import { LocationsContextProvider } from "./LocationsContext";
import { SublocationsContextProvider } from "./SublocationsContext";

export default function ContextProvider({ children }) {
  return (
    <LocationsContextProvider>
      <SublocationsContextProvider>{children}</SublocationsContextProvider>
    </LocationsContextProvider>
  );
}
