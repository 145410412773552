import firebase from "firebase";

/*
 ****************************************************************************
 * @brief  (fucntion setLocationData) - adds the location name added by the user to firebase
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take locationName,userid and companykey
 *
 * @return  what does the function return
 *-this functional component doesnt not  return anything
 ****************************************************************************
 */

// setting location data
export async function setLocationData(
  location,
  companyKey,
  lat,
  lng,
  address,
  selectedType,
  navLocationId,
  timestamp
) {
  try {
    const dataRef = firebase
      .firestore()
      .collection("locations")
      .doc();
    await dataRef.set({
      locationName: location,
      companyKey,
      locationId: dataRef.id,
      lat,
      lng,
      address,
      selectedType,
      navLocationId,
      timestamp
    });
  } catch (e) {
    throw Error(e);
  }
}

/*
 ****************************************************************************
 * @brief  (fucntion getLocationData) - gives the locations added by the user
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take userid of current logged-in user
 *
 * @return  what does the function return
 *-this functional component  returns all locations of signed in user
 ****************************************************************************
 */
// get location data
export async function getLocationData(companyKey) {
  const locations = [];
  try {
    const snapshot = await firebase
      .firestore()
      .collection("locations")
      .where("companyKey", "==", companyKey)
      .orderBy("timestamp", "asc")
      .get();

    snapshot.forEach(doc => {
      locations.push({
        locationName: doc.data().locationName,
        id: doc.data().locationId,
        uuid: doc.data().uuid,
        address: doc.data().address,
        lat: doc.data().lat,
        lng: doc.data().lng,
        selectedType: doc.data().selectedType,
        navLocationId: doc.data().navLocationId,
        timestamp: doc.data().timestamp
      });
    });
  } catch (e) {
    throw Error(e);
  }

  return locations;
}

/*
 ****************************************************************************
 * @brief  (fucntion deleteLocation) - deletes location selected by the user
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take document Id of the selected location
 *
 * @return  what does the function return
 *-this functional component  doesnot return anything
 ****************************************************************************
 */

export async function deleteLocation(id) {
  if (id === null) {
    throw Error("error");
  }
  try {
    // console.log(id);
    // const snapshot = await firebase
    //   .firestore()
    //   .collection("locations")
    //   .where("locationId", "==", id)
    //   .get();

    // snapshot.forEach(doc => {
    //   firebase
    //     .firestore()
    //     .collection("locations")
    //     .doc(doc.id)
    //     .delete();
    // });
    await firebase
      .firestore()
      .collection("locations")
      .doc(id)
      .delete();
  } catch (e) {
    throw Error(e);
  }
}
/*
 ****************************************************************************
 * @brief  (fucntion updateLocationData) - it takes the edited name of the location and
 * update to the firebase
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take previous location name and edited location name
 *
 * @return  what does the function return
 *-this functional component doesnot return anything
 ****************************************************************************
 */

// update Location Data
export async function updateLocationData(id, locationName, address, lat, lng) {
  try {
    await firebase
      .firestore()
      .collection("locations")
      .doc(id)
      .update({
        locationName,
        address,
        lat,
        lng
      });
  } catch (e) {
    throw Error(e);
  }
}
