import React, { createContext, useState, useEffect } from "react";
import {
  getCurrentUser,
  getCurrentCompanyKey,
  getLocationData,
  getBeacons,
  getSubscriptionType,
  setLocationData
} from "../controllers/firebase";
import { navigineAddLocation } from "../controllers/navigine_locations";

export const LocationContext = createContext();

export function LocationsContextProvider(props) {
  const [locationss, setLocationss] = useState();
  const [beacons, setBeacons] = useState();
  const [subType, setSubType] = useState();
  const [companyKey, setCompanyKey] = useState();
  const getLocData = cKey => {
    getLocationData(cKey).then(array => {
      setLocationss(array);
    });
  };
  const getBeacon = key => {
    getBeacons(key).then(array => {
      setBeacons([...array]);
    });
  };
  const getType = async ck => {
    const types = await getSubscriptionType(ck);
    setSubType(types);
  };
  const addLocation = async (
    locName,
    address,
    lat,
    lng,
    selectedType,
    timestamp
  ) => {
    let navLocationId = null;
    if (selectedType === "locationBased") {
      await setLocationData(
        locName,
        companyKey,
        lat,
        lng,
        address,
        selectedType,
        navLocationId,
        timestamp
      );
    } else {
      navLocationId = await navigineAddLocation(locName);
      await setLocationData(
        locName,
        companyKey,
        lat,
        lng,
        address,
        selectedType,
        navLocationId,
        timestamp
      );
    }

    getLocData(companyKey);
  };
  useEffect(() => {
    getCurrentUser(u => {
      if (u !== null) {
        getCurrentCompanyKey(u.uid).then(e => {
          getType(e);
          getLocData(e);
          getBeacon(e);
          setCompanyKey(e);
        });
      }
    });
  }, []);

  return (
    <LocationContext.Provider
      value={{ locationss, beacons, subType, getLocData, addLocation }}
    >
      {props.children}
    </LocationContext.Provider>
  );
}
