import firebase from "firebase";

/*
 ****************************************************************************
 * @brief  (fucntion setSubLocationData ) - sets sublocation name and image
 * uploaded by the user to firebase
 *
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take subLocationName,LocationId and imageUrl
 *
 * @return  what does the function return
 *-this functional component doesnt not return anything
 ****************************************************************************
 */

// setSubLocation data
export function setSubLocationData(
  subLocation,
  Lid,
  url,
  sid,
  companyKey,
  locationId,
  timestamp,
  displayName
) {
  const dataRef = firebase
    .firestore()
    .collection("subLocations")
    .doc();
  dataRef.set({
    subLocationName: subLocation,
    navLocationId: Lid,
    imageUrl: url,
    navSublocationId: sid,
    companyKey,
    locationId,
    timestamp,
    displayName
  });
}
/*
 ****************************************************************************
 * @brief  (fucntion getSubLocationData ) -gives the sublocations of the user
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take userid of current logged-in user
 *
 * @return  what does the function return
 *-this functional component  returns subLocations added by the user
 ****************************************************************************
 */
// get SubLocation data

export async function getSubLocationData(companyKey) {
  const subLocations = [];
  await firebase
    .firestore()
    .collection("subLocations")
    // TODO: we are not seperating entities like route, sub location, location, cmapaings, users based on the admins UID, we are supposed to use companyKey for this. So the query should filter based on companyKey of the provider.
    .where("companyKey", "==", companyKey)
    .orderBy("timestamp", "asc")
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        subLocations.push(doc.data());
      });
    });
  return subLocations;
}
/*
 ****************************************************************************
 * @brief  (fucntion deleteSubLocation ) - deletes the sublocation selected by the user
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take id of the selected sublocation
 *
 * @return  what does the function return
 *-this functional component doesnot  return anything
 ****************************************************************************
 */
// / delete sub Location
export async function deleteSubLocation(id) {
  await firebase
    .firestore()
    .collection("subLocations")
    .where("navSublocationId", "==", id)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        firebase
          .firestore()
          .collection("subLocations")
          .doc(doc.id)
          .delete();
      });
    });
}

/*
 ****************************************************************************
 * @brief  (fucntion uploadTaskPromise) - This function uploads image to firebase storage and
 * returns url of the uploaded image
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take image uploaded by the user
 *
 * @return  what does the function return
 *-this functional component  returns url of the uploaded image
 ****************************************************************************
 */

// /////////////////////////////////
export async function uploadTaskPromise(type, image) {
  let storageRef = "";
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    if (type === "Image") {
      storageRef = storage.ref(`images/${image.name}`);
    } else if (type === "Video") {
      storageRef = storage.ref(`Video/${image.name}`);
    } else if (type === "Audio") {
      storageRef = storage.ref(`Audio/${image.name}`);
    }

    const uploadTask = storageRef.put(image);
    uploadTask.on(
      "state_changed",
      snapshot => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      error => {
        reject();
      },
      complete => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          resolve(downloadURL);
        });
      }
    );
  });
}
/*
 ****************************************************************************
 * @brief  (fucntion updateSubLocation) - it updates the firebase with the edited sublocation name by the user
 *
 *
 *
 *
 *
 * @see link of the function if applicable
 *
 *
 * -this functional component does take editedSublocation name and sublocation id
 *
 * @return  what does the function return
 *-this functional component doesnot  return anything
 ****************************************************************************
 */

// update sublocation data
export async function updateSubLocation(subLocName, id) {
  await firebase
    .firestore()
    .collection("subLocations")
    .where("navSublocationId", "==", id)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        firebase
          .firestore()
          .collection("subLocations")
          .doc(doc.id)
          .update({ subLocationName: subLocName });
      });
    });
}
