/* eslint-disable import/no-unresolved */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_Template.js
 *
 *  Description: SnowM Template File. This Template will be used by
 *               different product lines from SnowM.
 *
 *  Author: Ali Al-saaidi (ali@snowm.io)
 *
 *  Date created: 18-June-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import campaignDashbordImage from "./campaignDashboardImage.png";
import {
  getCurrentUser,
  getCurrentCompanyKey,
  getCampaigns
} from "../../../controllers/firebase";
import "./Dashboard.css";

const myStyle = {
  width: "145px",
  height: "180px"
};

function CampaignsDisplay(props) {
  // variable to store company key fetched from firebase
  const [companyKey, setCompanyKey] = useState();
  // variable to store the userid fetched from firebase
  const [uid, setUid] = useState();

  const [campaigns, setCampaigns] = useState();
  // method to intialize all the variables which are fecthed from firebase
  useEffect(() => {
    getCurrentUser(u => {
      if (u) {
        setUid(u.uid);
        getCurrentCompanyKey().then(e => {
          setCompanyKey(e);
          getCampaignData(e);
        });
      }
    });
  }, []);

  const getCampaignData = cKey => {
    getCampaigns(cKey).then(array => {
      setCampaigns(array.length);
    });
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={5} style={{ height: "165px" }}>
        <img src={campaignDashbordImage} alt="noimage" style={myStyle} />
      </Grid>
      <Grid item xs={12} sm={6} style={{ height: "165px" }}>
        <Typography id="campaignstypographystyle">Campaigns</Typography>
        <Typography id="campaignsnumbertypographystyle">{campaigns}</Typography>
        <Typography
          id="campaignsseemoreinfostyle"
          onClick={() => {
            props.history.push("/home/campaigns");
          }}
        >
          See More Info >
        </Typography>
      </Grid>
    </Grid>
  );
}

/*
Validating React Component Props
*/
CampaignsDisplay.propTypes = {
  history: PropTypes.object.isRequired
};

export default CampaignsDisplay;
